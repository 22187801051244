import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'

// Components
import FeaturedWebinar from './FeaturedWebinar'
import FeaturedCases from './FeaturedCases'
import FeaturedWebinarAlternate from './FeaturedWebinarAlternate'
import FeaturedWhitepaper from './FeaturedWhitepaper'
import FeaturedWebinarLatest from './FeaturedWebinarLatest'

export default function FeaturedShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    webinar: FeaturedWebinar,
    webinar_alternate: FeaturedWebinarAlternate,
    cases: FeaturedCases,
    whitepaper: FeaturedWhitepaper,
    webinar_latest: FeaturedWebinarLatest
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
