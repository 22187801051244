// @ubo
import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'

// Components
import HeroVideo from './HeroVideo'
import HeroDefault from './HeroDefault'
import HeroContent from './HeroContent'
import HeroSwipes from './HeroSwipes'
import HeroMagic from './HeroMagic'
import HeroImage from './HeroImage'
import HeroBranding from './HeroBranding'

export default function HeroShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    default: HeroDefault,
    content: HeroContent,
    video: HeroVideo,
    swipes: HeroSwipes,
    magic: HeroMagic,
    image: HeroImage,
    branding: HeroBranding
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
