export default function Branding({ className = '' }: { className?: string }) {
  return (
    <svg width="523" height="400" viewBox="0 0 523 400" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <mask id="mask0_302_674" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="944" height="400">
        <rect x="943.307" y="400" width="943.307" height="400" transform="rotate(180 943.307 400)" fill="#070744" />
      </mask>
      <g mask="url(#mask0_302_674)">
        <path d="M728.845 -10.2384L439.608 278.981" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M716.12 -22.963L426.883 266.256" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M703.396 -35.6869L414.159 253.532" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M690.619 -48.4645L401.382 240.755" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M677.894 -61.1891L388.657 228.03" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M665.17 -73.9134L375.933 215.306" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M566.852 278.875L503.177 342.55" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M554.128 266.133L490.435 329.808" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M541.403 253.409L477.711 317.084" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M528.625 240.631L464.95 304.324" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M515.902 227.907L452.209 291.599" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M579.646 291.652L515.971 355.345" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M552.418 -10.2384L263.199 278.981" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M539.694 -22.9628L250.474 266.257" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M526.969 -35.6869L237.75 253.532" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M514.192 -48.4645L224.973 240.755" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M501.468 -61.1888L212.248 228.031" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M488.743 -73.9134L199.524 215.306" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M390.424 278.875L326.749 342.55" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M377.701 266.133L314.026 329.808" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M364.977 253.409L301.302 317.084" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M352.198 240.631L288.523 304.324" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M339.474 227.907L275.799 291.599" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M441.445 329.878L377.77 393.571" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M428.668 317.118L364.993 380.793" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M415.998 304.43L352.323 368.105" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M403.219 291.652L339.544 355.345" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M505.191 393.624L441.516 457.316" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M492.466 380.899L428.791 444.574" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M479.742 368.175L416.067 431.85" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M466.965 355.397L403.29 419.09" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M454.24 342.673L390.566 406.348" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M376.009 -10.2384L86.7718 278.981" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M363.284 -22.963L74.0472 266.256" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M350.56 -35.6869L61.3228 253.532" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M337.783 -48.4645L48.5459 240.755" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M325.058 -61.1891L35.8213 228.03" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M312.334 -73.9134L23.097 215.306" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M214.016 278.875L150.341 342.55" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M201.292 266.133L137.599 329.808" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M188.567 253.409L124.875 317.084" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M175.789 240.631L112.114 304.324" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M163.064 227.907L99.3896 291.599" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M265.038 329.878L201.345 393.571" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M252.259 317.118L188.584 380.793" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M239.57 304.43L175.895 368.105" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M226.81 291.652L163.135 355.345" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M328.782 393.624L265.107 457.316" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M316.059 380.899L252.366 444.574" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M303.316 368.175L239.641 431.85" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M290.556 355.397L226.863 419.09" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M277.831 342.673L214.139 406.348" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
      </g>
    </svg>
  )
}
