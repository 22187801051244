export default function Download({ className = '' }: { className?: string }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="23" fill="none" viewBox="0 0 20 23" className={className}>
      <path stroke="currentColor" strokeLinecap="round" strokeWidth="1.4" d="M1 18.125v3a1 1 0 001 1h16a1 1 0 001-1v-3"></path>
      <path
        fill="currentColor"
        d="M10.7.875a.7.7 0 10-1.4 0h1.4zM9.505 16.37a.7.7 0 00.99 0l4.455-4.455a.7.7 0 10-.99-.99L10 14.885l-3.96-3.96a.7.7 0 10-.99.99l4.455 4.455zM9.3.875v15h1.4v-15H9.3z"
      ></path>
    </svg>
  )
}
