// Components
import Content from '~/components/elements/Content'

// Third party
import { AnimatePresence, motion, useInView } from 'framer-motion'

// Types
import type { Page_Flexcontent_Flex_Text } from '~/graphql/types'
import { useRef } from 'react'

export default function TextTitle({ fields }: { fields: Page_Flexcontent_Flex_Text }) {
  const sectionRef = useRef<HTMLElement>(null)
  const inView = useInView(sectionRef)

  return (
    <section
      data-component="TextTitle"
      data-scrollindicatortitle={fields.scrollIndicatorTitle ?? undefined}
      id={fields.sectionId ?? undefined}
      ref={sectionRef}
      className="overflow-hidden py-12 lg:py-24"
    >
      <AnimatePresence>
        <motion.div
          className="container"
          animate={inView ? { y: 0 } : { y: '-200%' }}
          transition={{
            duration: 0.75
          }}
        >
          <div className="grid grid-cols-2 gap-x-8">
            <div className="col-span-2 sm:col-span-1">
              <Content className="content-xl children-headings:mb-6 sm:children-headings:mb-0">{fields.title}</Content>
            </div>
            <div className="col-span-2 sm:col-span-1">
              <Content>{fields.description}</Content>
            </div>
          </div>
        </motion.div>
      </AnimatePresence>
    </section>
  )
}
