// Third party
import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import { AnimatePresence, useInView, motion } from 'framer-motion'
import { useRef, useState } from 'react'

// Components
import Content from '~/components/elements/Content'

// Types
import type { Page_Flexcontent_Flex_Usps } from '~/graphql/types'

export default function UspsServices({ fields }: { fields: Page_Flexcontent_Flex_Usps }) {
  const [currentUspIndex, setCurrentUspIndex] = useState(0)
  const sectionRef = useRef<HTMLElement>(null)

  const inView = useInView(sectionRef)

  return (
    <section
      data-component="UspsServices"
      data-scrollindicatortitle={fields.scrollIndicatorTitle ?? undefined}
      id={fields.sectionId ?? undefined}
      ref={sectionRef}
      className={clsx(
        fields.appearance?.bgColor === 'light' ? 'bg-ubo-delibird' : 'bg-ubo-whiscash',
        fields.appearance?.spacingTop === 'tight' ? 'py-14 lg:py-24' : 'pb-14 pt-24 lg:pb-24 lg:pt-40',
        'relative overflow-hidden'
      )}
    >
      <div className="container">
        <div className="relative mb-12 inline-flex items-end">
          <Content className={clsx(fields.appearance?.bgColor === 'light' ? 'text-ubo-whiscash' : 'text-ubo-delibird', 'content-xl')}>
            {fields.description}
          </Content>
          <AnimatePresence key="Services">
            {inView && (
              <motion.div
                initial={{ x: '100%' }}
                animate={{ x: 0 }}
                exit={{ x: '100%' }}
                transition={{
                  duration: 2
                }}
                className={clsx(
                  fields.appearance?.bgColor === 'light' ? 'bg-ubo-whiscash' : 'bg-ubo-delibird',
                  'absolute -bottom-2 left-full mb-[1.6rem] ml-6 h-[0.125rem] w-[2000px] sm:mb-[1.875rem]'
                )}
              />
            )}
          </AnimatePresence>
        </div>
        <div className="lg:px-20">
          {fields.usps?.map((edge, uspIndex) => {
            const active = currentUspIndex === uspIndex

            return (
              <div
                key={edge?.title}
                className={clsx(
                  fields.appearance?.bgColor === 'light' ? 'border-ubo-taillow' : 'border-ubo-delibird',
                  'relative flex items-center justify-between border-b-2 py-12'
                )}
              >
                <AnimatePresence>
                  {active && (
                    <motion.div
                      key={edge?.description}
                      initial={{ x: '100%', opacity: 0 }}
                      animate={{ x: '25%', opacity: 1 }}
                      exit={{ x: '100%', opacity: 0 }}
                      transition={{
                        duration: 0.7
                      }}
                      className="absolute right-0 top-0 overflow-hidden"
                    >
                      <LossePlaatjie src={edge?.icon} className="h-[115px] w-[115px] lg:h-[300px] lg:w-[300px] xl:h-[450px] xl:w-[450px]" />
                    </motion.div>
                  )}
                </AnimatePresence>
                <div className="relative">
                  <span
                    role="button"
                    onClick={() => setCurrentUspIndex(uspIndex)}
                    className={clsx(
                      !active && fields.appearance?.bgColor === 'light' && 'text-stroke stroke-dark hover:text-ubo-taillow',
                      !active && fields.appearance?.bgColor === 'lightBlue' && 'text-stroke stroke-light hover:text-ubo-delibird',
                      active && fields.appearance?.bgColor === 'lightBlue' && 'text-ubo-delibird hover:text-ubo-delibird',
                      'block font-september text-4xl font-black lg:text-6xl xl:text-10xl'
                    )}
                  >
                    {edge?.title}
                  </span>
                  <AnimatePresence>
                    {active && (
                      <motion.div
                        key={edge?.title}
                        initial={{ height: 0 }}
                        animate={{ height: 'fit-content' }}
                        exit={{ height: 0 }}
                        className="overflow-hidden"
                      >
                        <div className="flex flex-col items-end pl-8 pt-8 lg:w-2/3 lg:pl-32">
                          <Content className={clsx(fields.appearance?.bgColor === 'lightBlue' && 'text-ubo-delibird')}>
                            {edge?.description}
                          </Content>
                          {edge?.link?.title && (
                            <LosseLink
                              to={edge?.link?.url}
                              className={clsx(fields.appearance?.bgColor === 'light' ? 'btn--dark' : 'btn--light--filled', 'btn mt-6')}
                            >
                              {edge?.link?.title}
                            </LosseLink>
                          )}
                        </div>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}
