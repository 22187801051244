// Third party
import { motion } from 'framer-motion'

// Types
import clsx from 'clsx'
import type { Maybe, Page_Flexcontent_Flex_Hero } from '~/graphql/types'
import { useRef, useState } from 'react'

export default function HeroVideo({ fields }: { fields: Page_Flexcontent_Flex_Hero }) {
  const sectionRef = useRef<HTMLElement>(null)

  let videoComponent = <Iframe videoUrl={fields.heroVideo} videoWidth={fields.appearance?.videoWidth} />

  if (fields.heroVideo?.includes('vimeo')) {
    videoComponent = <Vimeo videoUrl={fields.heroVideo} videoWidth={fields.appearance?.videoWidth} />
  }

  return (
    <section
      data-component="HeroVideo"
      data-scrollindicatortitle={fields.scrollIndicatorTitle ?? undefined}
      ref={sectionRef}
      id={fields.sectionId ?? undefined}
      className={clsx(fields.appearance?.bgColor === 'darkBlue' ? 'bg-ubo-taillow' : 'bg-ubo-whiscash', 'relative overflow-hidden')}
    >
      <div className="container py-14">{videoComponent}</div>
    </section>
  )
}

function Iframe({ videoUrl, videoWidth }: { videoUrl: Maybe<string> | undefined; videoWidth?: Maybe<string> | undefined }) {
  const [fullscreen, setFullscreen] = useState(false)

  if (!videoUrl) return null

  return (
    <motion.iframe
      src={videoUrl}
      className={clsx(videoWidth === 'tight' && 'lg:px-20', 'aspect-video h-full w-full object-cover')}
      animate={
        fullscreen
          ? {
              scale: 1.1
            }
          : { scale: 1 }
      }
      transition={{
        duration: 0.75
      }}
      onPlay={() => setFullscreen(true)}
      onPause={() => setFullscreen(false)}
      onEnded={() => setFullscreen(false)}
    />
  )
}

function Vimeo({ videoUrl, videoWidth }: { videoUrl: Maybe<string> | undefined; videoWidth?: Maybe<string> | undefined }) {
  const [fullscreen, setFullscreen] = useState(false)

  if (!videoUrl) return null

  return (
    <motion.video
      muted
      controls
      className={clsx(videoWidth === 'tight' && 'lg:px-20', 'h-full w-full object-cover')}
      animate={
        fullscreen
          ? {
              scale: 1.1
            }
          : { scale: 1 }
      }
      transition={{
        duration: 0.75
      }}
      onPlay={() => setFullscreen(true)}
      onPause={() => setFullscreen(false)}
      onEnded={() => setFullscreen(false)}
    >
      <source src={videoUrl} type="video/mp4" />
    </motion.video>
  )
}
