// Third party
import { motion, useInView, useScroll, useTransform } from 'framer-motion'
import { useRef } from 'react'

// Components
import Content from '~/components/elements/Content'
import Identity from '~/components/elements/svg/Identity'

// Types
import type { Page_Flexcontent_Flex_Text } from '~/graphql/types'

export default function TextVideo({ fields }: { fields: Page_Flexcontent_Flex_Text }) {
  // const [pulsingImage, setPulsingImage] = useState(true)
  const sectionRef = useRef<HTMLElement>(null)
  const contentRef = useRef<HTMLDivElement>(null)
  const identityRef = useRef(null)

  const contentInView = useInView(contentRef, { once: true })

  const { scrollYProgress } = useScroll({
    target: identityRef
  })

  const transform = useTransform(scrollYProgress, [0, 1], [-10, -50])
  const xPercentage = useTransform(transform, (value) => `${value}%`)

  return (
    <section
      data-component="TextVideo"
      data-scrollindicatortitle={fields.scrollIndicatorTitle ?? undefined}
      id={fields.sectionId ?? undefined}
      className="relative overflow-hidden pb-20 pt-32 lg:pb-48"
      ref={sectionRef}
    >
      <div className="absolute inset-0 top-[20%] h-[80%] bg-ubo-delibird" />
      <div className="absolute -bottom-6 overflow-hidden lg:-bottom-20" ref={identityRef}>
        <div className="relative h-full w-full overflow-hidden">
          <motion.div key="moving_identity" style={{ translateX: xPercentage }} className="w-full">
            <Identity type="dark" className="h-full w-full" />
          </motion.div>
        </div>
      </div>
      <div className="container relative">
        <div className="grid grid-cols-12 items-start 2xl:gap-x-24">
          <div className="col-span-12 flex xl:col-span-4 2xl:col-span-5">
            <div
              // onClick={() => setPulsingImage(!pulsingImage)}
              // animate={pulsingImage && contentInView ? { scale: [1.01, 1, 1.01] } : { scale: [1, 1, 1] }}
              // transition={{
              //   repeat: 5,
              //   repeatType: 'mirror'
              // }}
              className="flex-1 cursor-pointer transition-opacity"
            >
              <video
                autoPlay
                muted
                controls
                className="mx-auto aspect-video lg:min-w-[650px] lg:max-w-[650px] xl:absolute xl:-top-32 xl:min-w-[800px] xl:max-w-[800px] 2xl:-top-14 2xl:min-w-[800px] 2xl:max-w-[800px]"
              >
                <source src={fields.video || ''} type="video/mp4" />
              </video>
            </div>
          </div>
          <div className="pointer-events-none col-span-12 xl:col-span-8 2xl:col-span-7" ref={contentRef}>
            <motion.div initial={{ x: '110%' }} animate={contentInView ? { x: 0 } : { x: '110%' }}>
              <Content className="content-stroke py-8 children-headings:hyphens-auto children-headings:font-museo children-headings:text-xl children-em:mb-4 children-em:block children-em:font-september children-em:text-6xl children-h2:mb-3 children-h3:mb-2 children-h3:text-2xl children-h3:leading-none children-p:text-justify max-lg:children-headings:!text-left lg:pb-28 lg:pt-20 lg:children-headings:text-5xl lg:children-headings:leading-[1.1] lg:children-em:text-10xl xl:pt-56 2xl:pt-64 2xl:children-headings:text-[4.65rem] 2xl:children-em:text-12xl">
                {fields?.description}
              </Content>
            </motion.div>
          </div>
        </div>
      </div>
    </section>
  )
}
