export default function Stripe({ type = 'dark', className = '' }: { type?: 'light' | 'dark'; className?: string }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="383" height="68" fill="none" viewBox="0 0 383 68" className={className}>
      <mask id="mask0_1628_32900" style={{ maskType: 'alpha' }} width="390" height="68" x="-7" y="0" maskUnits="userSpaceOnUse">
        <path fill={type === 'light' ? '#000CED' : '#010119'} d="M-7 0H383V68H-7z"></path>
      </mask>
      <g fill={type === 'dark' ? '#070744' : '#000CED'} mask="url(#mask0_1628_32900)">
        <path d="M-108.957-105H-167L-27.584 34.415-167 173.833h58.043L30.459 34.415-108.957-105z"></path>
        <path d="M-23.75-105h-58.044L57.622 34.415-81.794 173.833h58.043L115.666 34.415-23.751-105z"></path>
        <path d="M61.456-105H3.413L142.829 34.415 3.413 173.833h58.043L200.872 34.415 61.456-105z"></path>
        <path d="M146.662-105H88.619L228.035 34.415 88.619 173.833h58.043L286.078 34.415 146.662-105z"></path>
        <path d="M232.043-105H174L313.416 34.415 174 173.833h58.043L371.459 34.415 232.043-105z"></path>
      </g>
    </svg>
  )
}
