import { LosseLink, LossePlaatjie, losseContentParse } from '@ubo/losse-sjedel'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Text } from '~/graphql/types'

export default function TextUsps({ fields }: { fields: Page_Flexcontent_Flex_Text }) {
  return (
    <section
      data-component="TextUsps"
      data-scrollindicatortitle={fields.scrollIndicatorTitle ?? undefined}
      id={fields.sectionId ?? undefined}
      className="pb-10 pt-10 lg:pb-28"
    >
      <div className="container">
        <div className="max-w-screen-xl grid-cols-2 gap-20 lg:grid xl:gap-40">
          <div />
          <div>
            <Content className="content text-xl text-ubo-taillow children-p:mb-4 last:children-p:mb-0">{fields?.description}</Content>
          </div>
        </div>

        <div className="mx-auto mt-10 grid max-sm:gap-10 sm:mt-16 sm:grid-cols-3 lg:mt-32">
          {fields?.usps?.map((usp: any, index: number) => (
            <div key={index} className="flex flex-col items-center justify-center">
              <LossePlaatjie src={usp?.icon} className="h-[50px] w-[50px] sm:h-[75px] sm:w-[75px] md:h-[100px] md:w-[100px]" />
              <h2 className="mt-2 text-center text-base font-extrabold uppercase text-ubo-whiscash sm:mt-10 md:text-xl mb-4">
                {losseContentParse(usp?.title?.replace(' ', '<br />'))}
              </h2>
              <Content className="children-p:text-base">{usp?.description}</Content>
            </div>
          ))}
        </div>

        <div className="mt-10 flex justify-center sm:mt-16 lg:mt-20">
          <LosseLink src={fields?.link} className="btn--primary--filled rounded-full px-6 py-2 font-bold lg:px-10 lg:py-4 lg:text-xl" />
        </div>
      </div>
    </section>
  )
}
