import { LosseLink, LossePlaatjie, useLosseLanguage } from '@ubo/losse-sjedel'
import type { KnowledgeBaseItem, Page_Flexcontent_Flex_Featured } from '~/graphql/types'
import { useInView, motion, useMotionValue, useSpring } from 'framer-motion'
import type { MouseEvent } from 'react'
import { useEffect, useRef, useState } from 'react'
import Arrow from '~/components/elements/svg/Arrow'
import X from '~/components/elements/svg/X'
import Play from '~/components/elements/svg/Play'
import Download from '~/components/elements/svg/Download'
import Content from '~/components/elements/Content'
import Stroke from '~/components/elements/svg/Stroke'
import { Line } from './FeaturedWebinar'
import clsx from 'clsx'

export default function FeaturedWebinarAlternate({ fields }: { fields: Page_Flexcontent_Flex_Featured }) {
  const [, locale] = useLosseLanguage()

  const categoryIds = fields.filters?.map((filter) => filter?.databaseId ?? 0) ?? []

  const featured = fields?.whitepaper?.nodes?.find((node) => {
    const hasWebinarCategory =
      (node as KnowledgeBaseItem)?.categories?.nodes?.filter((category) => Number(category.databaseId) === 100) || []

    const hasCategory =
      (node as KnowledgeBaseItem)?.categories?.nodes?.filter((category) => categoryIds.includes(Number(category.databaseId))) || []

    return hasCategory.length > 0 && hasWebinarCategory.length > 0
  }) as KnowledgeBaseItem

  const [hoverThumbnail, setHoverThumbnail] = useState(false)
  const sectionRef = useRef(null)
  const thumbnailRef = useRef<HTMLImageElement | null>(null)
  const buttonRef = useRef(null)

  const cursorX = useMotionValue(190)
  const cursorY = useMotionValue(120)

  const inView = useInView(sectionRef)

  useEffect(() => {
    if (!hoverThumbnail) {
      cursorX.set(190)
      cursorY.set(120)
    }
  }, [cursorX, cursorY, hoverThumbnail])

  function handleCursor(event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) {
    if (thumbnailRef.current) {
      const { left, top } = thumbnailRef.current.getBoundingClientRect()

      cursorX.set(event.clientX - left - 110)
      cursorY.set(event.clientY - top - 36)
    }
  }

  const springConfig = { damping: 25, stiffness: 700 }
  const cursorXSpring = useSpring(cursorX, springConfig)
  const cursorYSpring = useSpring(cursorY, springConfig)

  if (!featured) return null

  const alternative = !!fields.appearance?.bgText

  return (
    <section
      data-component="FeaturedWebinarAlternate"
      data-scrollindicatortitle={fields.scrollIndicatorTitle ?? undefined}
      className={clsx('presentation-section relative text-ubo-delibird', alternative ? 'bg-ubo-delibird' : 'bg-ubo-whiscash')}
      id="webinars"
      ref={sectionRef}
    >
      {alternative && (
        <div className="absolute -left-[50vw] hidden max-h-full w-[200vw] overflow-hidden lg:inline">
          <Line bgText={featured?.title?.toUpperCase()} direction="ltr" />
          <Line bgText={featured?.title?.toUpperCase()} direction="rtl" />
          <Line bgText={featured?.title?.toUpperCase()} direction="ltr" />
          <Line bgText={featured?.title?.toUpperCase()} direction="rtl" />
          <Line bgText={featured?.title?.toUpperCase()} direction="ltr" />
          <Line bgText={featured?.title?.toUpperCase()} direction="ltr" />
          <Line bgText={featured?.title?.toUpperCase()} direction="ltr" />
        </div>
      )}

      {!alternative && (
        <>
          <X inView={inView} className="absolute right-0 top-0 hidden h-full w-auto lg:block" />
          <Stroke direction="horizontal" type="dark" className="absolute top-0 block h-1/5 w-full lg:hidden" />
        </>
      )}
      <div className="presentation-container relative flex flex-col py-12 lg:py-24">
        <>
          <Content className={clsx('content-3xl mb-9 block lg:hidden', alternative && 'text-ubo-taillow')}>{fields.title}</Content>
          <motion.div
            initial={{ x: '70vw' }}
            animate={inView ? { x: '0vw' } : { x: '70vw' }}
            transition={{
              delay: 0.5,
              bounce: 0,
              duration: 0.75
            }}
            className="relative ml-auto w-full lg:order-last lg:w-3/5"
            onMouseEnter={() => setHoverThumbnail(true)}
            onMouseLeave={() => setHoverThumbnail(false)}
          >
            <LosseLink to={featured?.uri}>
              <div className="absolute inset-0 h-full w-full bg-ubo-delibird opacity-50 lg:hidden" />
              <div
                ref={thumbnailRef}
                onMouseMove={(event) => handleCursor(event)}
                className="flex items-center justify-center lg:block lg:items-start lg:justify-start"
              >
                <motion.div
                  ref={buttonRef}
                  role="button"
                  className="btn btn--dark lg:btn--light pointer-events-none absolute z-[99] hidden sm:inline-flex"
                  style={{
                    translateX: cursorXSpring,
                    translateY: cursorYSpring
                  }}
                >
                  <Play className="hidden lg:block" type="light" />
                  <Play className="lg:hidden" type="dark" />
                  <span className="ml-2">Play webinar</span>
                </motion.div>
                <div ref={buttonRef} role="button" className="btn btn--dark pointer-events-none absolute z-[99] sm:hidden">
                  <Play type="dark" />
                  <span className="ml-2">Play webinar</span>
                </div>

                <LossePlaatjie src={featured?.recap?.highlightedThumbnail} className="h-full w-full object-cover" />
              </div>
            </LosseLink>
          </motion.div>
          <motion.div
            initial={{ x: '-100vw' }}
            animate={{ x: 0 }}
            transition={{ delay: 0.5 }}
            exit={{ x: '-100vw' }}
            className="lg:order-first"
          >
            <Content className={clsx('content-3xl mb-9 hidden lg:block', alternative && 'text-ubo-taillow')}>{fields?.title}</Content>
            <Content
              className={clsx(
                'content-3xl mb-9 mt-7 w-full children-h3:mb-0 children-h3:text-2xl children-h3:font-bold lg:mt-0 lg:w-1/2 lg:children-p:pr-16',
                alternative && 'text-ubo-taillow'
              )}
            >{`<h3>${featured?.title}</h3>${featured?.recap?.excerpt}`}</Content>
            <div>
              <LosseLink className={clsx('btn', alternative ? 'btn--primary--filled' : 'btn--light--filled')} to={featured?.uri}>
                <Download />
                <span className="ml-3">{locale === 'nl_NL' ? 'Bekijk hier de webinar' : 'Watch the webinar here'}</span>
              </LosseLink>
            </div>
            <div className="mt-5">
              <LosseLink
                to={fields?.link?.url}
                target={fields?.link?.target || ''}
                className={clsx(
                  'group inline-flex items-center rounded-full border-2 border-transparent transition-all duration-300',
                  alternative ? 'text-ubo-taillow hover:border-ubo-taillow' : 'hover:border-ubo-delibird'
                )}
              >
                <div className="rounded-full border-2 border-ubo-delibird p-4 transition-all duration-300 group-hover:border-transparent">
                  <Arrow type={alternative ? 'lightBlue' : 'light'} />
                </div>
                <span className="px-3 lg:text-lg">{fields?.link?.title}</span>
              </LosseLink>
            </div>
          </motion.div>
        </>
      </div>
    </section>
  )
}

export function convertDate(date?: string) {
  if (!date) return null

  const [, month, day] = date?.split('-')

  const months: { [key: string]: string } = {
    '01': 'Jannuari',
    '02': 'Februari',
    '03': 'Maart',
    '04': 'April',
    '05': 'Mei',
    '06': 'Juni',
    '07': 'Juli',
    '08': 'Augustus',
    '09': 'September',
    '10': 'Oktober',
    '11': 'November',
    '12': 'December'
  }

  return `${day} ${months[month]}`
}
