// Third party
import clsx from 'clsx'

// Types
import type { Page_Flexcontent_Flex_Hero } from '~/graphql/types'
import { useRef } from 'react'
import { LossePlaatjie } from '@ubo/losse-sjedel'

export default function HeroImage({ fields }: { fields: Page_Flexcontent_Flex_Hero }) {
  const sectionRef = useRef<HTMLElement>(null)

  return (
    <section
      data-component="HeroImage"
      data-scrollindicatortitle={fields.scrollIndicatorTitle ?? undefined}
      ref={sectionRef}
      id={fields.sectionId ?? undefined}
      className={clsx(fields.appearance?.bgColor === 'darkBlue' ? 'bg-ubo-taillow' : 'bg-ubo-whiscash', 'relative overflow-hidden')}
    >
      <div className="container py-14">
        <LossePlaatjie src={fields.image} className="h-full w-full object-cover xl:max-h-[51rem]" />
      </div>
    </section>
  )
}
