// @ubo
import { LosseBlogBink, LosseLink, LossePlaatjie, useLosseLanguage } from '@ubo/losse-sjedel'

// Types
import type {
  Maybe,
  PageInfo,
  Page_Flexcontent_Flex_Posts,
  Page_Flexcontent_Flex_PostsToContentNodeConnectionEdge,
  Post
} from '~/graphql/types'

// Components
import PostsSearch from '~/components/elements/PostsSearch'
import PostsFilters from '~/components/elements/PostsFilters'

// Third party
import clsx from 'clsx'
import { AnimatePresence, motion, useInView } from 'framer-motion'

// SVG
import Clock from '~/components/elements/svg/Clock'
import Chevron from '~/components/elements/svg/Chevron'
import { useRef } from 'react'

export default function PostsBlog({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  if (!fields.posts?.pageInfo) return null

  return (
    <section
      data-component="PostsBlog"
      data-scrollindicatortitle={fields.scrollIndicatorTitle ?? undefined}
      id={fields.sectionId ?? undefined}
      className="relative pb-32"
    >
      <LosseBlogBink pageInfo={fields.posts.pageInfo as PageInfo}>
        <Overview fields={fields} />
      </LosseBlogBink>
    </section>
  )
}

function Overview({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  return (
    <>
      <div className="bg-ubo-whiscash">
        <div className="container py-6 sm:py-20">
          <div className="mb-24 grid grid-cols-3 items-end gap-x-16">
            <div className="col-span-3 md:col-span-1">
              <PostsSearch />
            </div>
            <div className="col-span-3 md:col-span-2">
              <PostsFilters filters={fields.filters} />
            </div>
          </div>
        </div>
      </div>

      <PostsGallery posts={fields.posts?.edges} />
    </>
  )
}

function PostsGallery({ posts }: { posts: Maybe<Maybe<Page_Flexcontent_Flex_PostsToContentNodeConnectionEdge>[]> | undefined }) {
  const [, locale] = useLosseLanguage()
  const galleryRef = useRef(null)
  const inView = useInView(galleryRef)

  let pointer = 0
  let gridItemsPointer = 0

  return (
    <div className="relative min-h-screen" ref={galleryRef}>
      <div className="absolute h-full w-full">
        <div className="h-36 w-full bg-ubo-whiscash" />
        <div className="h-auto w-full bg-ubo-delibird" />
      </div>
      <div className="container relative -mt-20 gap-4 lg:grid lg:auto-rows-[337px] lg:grid-cols-3 lg:gap-10">
        <AnimatePresence>
          {inView && (
            <>
              {Number(posts?.length) > 0 &&
                posts?.map((edge, postIndex) => {
                  let colStart
                  let colEnd
                  let rowStart
                  let rowEnd

                  switch (postIndex % 7) {
                    case Number(0):
                      colStart = 1
                      colEnd = 2
                      rowStart = 1 + pointer * 4
                      rowEnd = 2 + pointer * 4
                      break
                    case 1:
                      colStart = 2
                      colEnd = 3
                      rowStart = 1 + pointer * 4
                      rowEnd = 3 + pointer * 4
                      break
                    case 2:
                      colStart = 3
                      colEnd = 4
                      rowStart = 1 + pointer * 4
                      rowEnd = 2 + pointer * 4
                      break
                    case 3:
                      colStart = 3
                      colEnd = 4
                      rowStart = 2 + pointer * 4
                      rowEnd = 3 + pointer * 4
                      break
                    case 4:
                      colStart = 1
                      colEnd = 2
                      rowStart = 2 + pointer * 4
                      rowEnd = 4 + pointer * 4
                      break
                    case 5:
                      colStart = 2
                      colEnd = 4
                      rowStart = 3 + pointer * 4
                      rowEnd = 5 + pointer * 4
                      break
                    case 6:
                      colStart = 1
                      colEnd = 2
                      rowStart = 4 + pointer * 4
                      rowEnd = 5 + pointer * 4
                      break
                    default:
                      break
                  }

                  gridItemsPointer++
                  if (gridItemsPointer === 7) {
                    gridItemsPointer = 0
                    pointer++
                  }

                  const post = edge?.node as Post

                  return (
                    <motion.div
                      key={post.title}
                      style={{
                        gridColumnStart: colStart,
                        gridColumnEnd: colEnd,
                        gridRowStart: rowStart,
                        gridRowEnd: rowEnd
                      }}
                      initial={{ y: '25%', opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      exit={{ y: '25%', opacity: 0 }}
                      transition={{ delay: postIndex * 0.125, bounce: 0, duration: 0.5 }}
                      className="relative mb-6 overflow-hidden lg:mb-0"
                    >
                      <LosseLink to={post.uri} className="group">
                        <LossePlaatjie
                          src={post.recap?.thumbnail}
                          className={clsx(
                            postIndex === 0 || postIndex % 7 === 0 ? 'h-[337px]' : 'h-full',
                            'max-h-52 w-full object-cover transition-transform group-hover:scale-105 sm:max-h-full'
                          )}
                        />
                        <div className="absolute bottom-0 w-full bg-gradient-to-t from-ubo-taillow p-4">
                          <div className="btn btn--light--filled mb-2 px-2 py-1 font-light text-ubo-taillow">
                            <Clock />
                            <span className="ml-2 mt-[0.025rem] text-sm sm:text-base">{post.recap?.readingtime}</span>
                          </div>
                          <div className="flex items-end justify-between">
                            <h2 className="text-lg font-bold text-ubo-delibird lg:text-3xl">{post.title}</h2>
                            <Chevron className="h-6 w-6 lg:h-auto lg:w-auto" />
                          </div>
                        </div>
                      </LosseLink>
                    </motion.div>
                  )
                })}
            </>
          )}
        </AnimatePresence>
      </div>
      {Number(posts?.length) < 1 && (
        <div className="container relative">
          <p className="text-lg text-white md:text-xl lg:text-2xl">
            {locale === 'nl_NL'
              ? 'Het lijkt erop dat er geen resultaten zijn voor je zoekopdracht, probeer iets anders.'
              : 'It looks like there are no results for your search, try something else.'}
          </p>
        </div>
      )}
    </div>
  )
}
