// Third party
import { motion, useInView } from 'framer-motion'

// Hooks
import { useRef, useState } from 'react'

// Components
import Content from '~/components/elements/Content'

// Types
import type { Page_Flexcontent_Flex_Banner } from '~/graphql/types'

// SVG
import Sound from '~/components/elements/svg/Sound'
import clsx from 'clsx'
import Muted from '~/components/elements/svg/Muted'
import { useLoaderData } from '@ubo/losse-sjedel'
import type { PageLoaderData } from '~/templates/page'

export default function BannerVideo({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  const [muted, setMuted] = useState(true)
  const sectionRef = useRef(null)
  const inView = useInView(sectionRef)

  const { rest } = useLoaderData<PageLoaderData>()

  return (
    <section
      data-component="BannerVideo"
      data-scrollindicatortitle={fields.scrollIndicatorTitle ?? undefined}
      id={fields.sectionId ?? undefined}
      ref={sectionRef}
      className={clsx(fields.appearance?.videoWidth !== 'tight' && 'bg-ubo-roggenrola', 'relative overflow-hidden bg-ubo-whiscash')}
    >
      <>
        {fields.appearance?.videoWidth === 'tight' ? (
          <div className="relative pt-24 lg:pt-32">
            <div className="absolute bottom-0 h-1/3 w-full bg-ubo-whiscash" />
            <div className="container relative">
              <motion.div
                initial={{ x: '-110%' }}
                animate={inView ? { x: 0 } : { x: '-110%' }}
                transition={{
                  duration: 1
                }}
              >
                <div className={clsx(!fields.video && 'pt-14', 'flex items-start')}>
                  <Content className={clsx(!fields.video && 'text-white', 'content-3xl mb-6')}>{fields.description}</Content>
                  <div
                    className={clsx(
                      fields.video ? 'bg-ubo-whiscash' : 'bg-ubo-taillow',
                      '-mt-4 flex h-6 w-6 items-center justify-center rounded-full sm:-mt-0 lg:h-9 lg:w-9'
                    )}
                  >
                    <span className="text-sm font-bold text-ubo-delibird lg:text-xl">{rest.cases.pageInfo.total}</span>
                  </div>
                </div>
              </motion.div>
              {fields.video && (
                <motion.div
                  animate={inView ? { x: 0 } : { x: '110%' }}
                  transition={{ bounce: 0, duration: 1 }}
                  key={fields.video}
                  className="relative mx-auto max-w-[1536px]"
                >
                  <video preload="metadata" muted controls playsInline className="mx-auto aspect-video min-w-full">
                    <source src={fields.video || ''} type="video/mp4" />
                  </video>
                </motion.div>
              )}
            </div>
          </div>
        ) : (
          <>
            {fields.video && (
              <div className="relative mx-auto max-w-[1536px]">
                <video
                  preload="metadata"
                  key={fields.video}
                  muted={muted}
                  loop
                  autoPlay
                  playsInline
                  controls
                  className="mx-auto aspect-video min-w-full overflow-hidden"
                >
                  <source src={fields.video || ''} type="video/mp4" />
                </video>
                <div className="absolute hidden w-full justify-center sm:bottom-12 sm:flex lg:bottom-16">
                  <div role="button" className="btn btn--light--filled px-3 py-2 lg:px-4 lg:py-3" onClick={() => setMuted(!muted)}>
                    {muted ? <Sound /> : <Muted />}
                    <span className="ml-5">{muted ? <>Click for sound</> : <>Click to mute</>}</span>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </>
    </section>
  )
}
