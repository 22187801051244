// @ubo
import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'

// Components
import GalleryDefault from './GalleryDefault'

export default function GalleryShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    default: GalleryDefault
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
