import { useRef, useEffect, useState } from 'react'
import ReactModal from 'react-modal'
import type { Maybe } from 'yup/lib/types'
import useClientMediaQuery from '~/hooks/useClientMediaQuery'
import Content from './Content'
import Branding from './svg/Branding'
import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'

export default function WebinarModal({
  isOpen,
  setIsOpen,
  item,
  presentators
}: {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  item: any
  presentators: Maybe<any>[]
}) {
  const isDesktop = useClientMediaQuery('(min-width: 992px)')
  const [lookback, setLookback] = useState(false)

  const ref = useRef(null)

  useEffect(() => {
    const { documentElement: html } = document
    if (isOpen) {
      html.classList.add('overflow-hidden')
    } else {
      html.classList.remove('overflow-hidden')
    }
  }, [isOpen])

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: isDesktop ? 'auto' : '0%',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      background: 'transparent',
      border: 0,
      width: isDesktop ? '100vw' : '100%',
      height: '100vh',
      backgroundColor: '#070744',
      padding: 0
    }
  }

  // check if item.sentence[0] date is in the past
  useEffect(() => {
    if (item?.sentence[0]?.description) {
      const date = new Date(item?.sentence[0]?.description)
      const now = new Date()
      if (date < now) {
        setLookback(true)
      }
    }
  }, [setLookback, item?.sentence])

  return (
    <ReactModal isOpen={isOpen} style={customStyles}>
      <div ref={ref} className="h-full overflow-y-scroll px-4 lg:px-0">
        <div onClick={() => setIsOpen(!isOpen)} className="absolute right-4 top-4 mr-4 mt-4 cursor-pointer">
          <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M16.9307 20.7539L16.5771 20.4004L16.2236 20.7539L5.05372 31.9238L1.23052 28.1006L12.4004 16.9307L12.7539 16.5772L12.4004 16.2236L1.23052 5.05373L5.05105 1.23321L16.2209 12.4031L16.5745 12.7566L16.928 12.4031L28.1006 1.23054L31.9238 5.05373L20.7512 16.2263L20.3977 16.5798L20.7512 16.9334L31.9211 28.1033L28.1006 31.9238L16.9307 20.7539Z"
              fill="white"
              stroke="#000CED"
            />
          </svg>
        </div>
        <Branding className="absolute right-0 top-0 -z-[1] hidden lg:block" />
        <h1 className="text-stroke mt-4 pt-4 font-september text-5xl font-extrabold uppercase lg:text-9xl">{item?.title}</h1>
        <div className="mt-10 lg:mt-20 lg:px-32 xl:px-48">
          <Content className="content-md w-3/4 text-white children-h2:mb-4 lg:children-p:text-[24px]">{item?.description}</Content>
          <div className="mb-10 mt-10 grid lg:mb-0 lg:mt-20 lg:grid-cols-10">
            <div className="col-span-4">
              <ul>
                {item?.sentence?.map((item: any, index: number) => (
                  <li className="list-after relative mb-8 flex items-center lg:mb-16" key={index}>
                    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M18.84 32.68C26.4836 32.68 32.68 26.4836 32.68 18.84C32.68 11.1964 26.4836 5 18.84 5C11.1964 5 5 11.1964 5 18.84C5 26.4836 11.1964 32.68 18.84 32.68Z"
                        stroke="#000CED"
                        strokeWidth="9"
                        strokeMiterlimit="10"
                      />
                    </svg>
                    <span className="ml-5 font-september text-3xl font-extrabold text-white">{item.description}</span>
                  </li>
                ))}
              </ul>
              <div className="flex items-center">
                {presentators?.map((presentator, index) => (
                  <div key={index} className="mr-8">
                    <LossePlaatjie
                      src={presentator?.recap?.image}
                      className="h-[100px] w-[100px] rounded-full object-cover object-top lg:h-[141px] lg:w-[141px]"
                    />
                    <div className="mt-6">
                      <p className="text-lg font-light text-white">{presentator?.title}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-span-6 mt-10 lg:mt-0">
              <Content className="text-white">{item?.secondDescription}</Content>
              <div className="mt-8 flex items-center lg:mt-16">
                <LosseLink
                  to="#inschrijven"
                  onClick={() => {
                    setIsOpen(false)
                    setTimeout(() => {
                      const element = document.getElementById('inschrijven')
                      if (element) {
                        element.scrollIntoView({ behavior: 'smooth' })
                      }
                    }, 500)
                  }}
                  className="btn--primary--filled rounded-full px-6 py-2 font-bold lg:px-10 lg:py-4 lg:text-xl"
                >
                  {lookback ? 'Terugkijken' : 'Inschrijven'}
                </LosseLink>
                {!lookback && <p className="ml-4 text-base font-light text-white lg:ml-8">Beperkt aantal plaatsen beschikbaar</p>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </ReactModal>
  )
}
