export default function Clock({ className = '' }: { className?: string }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" fill="none" viewBox="0 0 18 19">
      <g fill="#070744" clipPath="url(#clip0_1465_19034)">
        <path d="M9 .5a9 9 0 109 9 9.01 9.01 0 00-9-9zm0 15.75a6.75 6.75 0 116.75-6.75A6.758 6.758 0 019 16.25z"></path>
        <path d="M8.625 5.75A1.125 1.125 0 007.5 6.875v3.22c0 .397.158.779.44 1.06L9.284 12.5a1.125 1.125 0 001.591-1.59L9.75 9.783V6.875A1.125 1.125 0 008.625 5.75z"></path>
      </g>
      <defs>
        <clipPath id="clip0_1465_19034">
          <path fill="#fff" d="M0 0H18V18H0z" transform="translate(0 .5)"></path>
        </clipPath>
      </defs>
    </svg>
  )
}
