/* eslint-disable react-hooks/exhaustive-deps */
// Third party
import { AnimatePresence, motion } from 'framer-motion'

// SVG
import SplashArrows from '~/components/elements/svg/SplashArrows'

// Types
import type { Page_Flexcontent_Flex_Splash } from '~/graphql/types'

// Hooks
import { useEffect, useRef, useState } from 'react'
import useLayout from '~/hooks/useLayout'
import useClientMousePosition from '~/hooks/useClientMousePosition'

export default function Splash({ fields }: { fields: Page_Flexcontent_Flex_Splash }) {
  const mobileButtonWrapperRef = useRef(null)
  const { x, y } = useClientMousePosition()
  const { splashSeen, setSplashSeen } = useLayout()
  const [checked, setChecked] = useState(false)

  useEffect(() => {
    if (!checked) return

    const { documentElement: html } = document

    if (splashSeen) {
      html?.classList.remove('overflow-hidden')
    } else {
      html?.classList.add('overflow-hidden')
    }

    localStorage.setItem('ubo-splash-seen', String(splashSeen))
  }, [checked, splashSeen])

  useEffect(() => {
    const splash = localStorage.getItem('ubo-splash-seen')
    const effect = splash ? Boolean(splash) : false

    setSplashSeen(effect)
    setChecked(true)
  }, [])

  const handleGo = () => {
    setSplashSeen(true)
  }

  return (
    <>
      {!checked && <div className="fixed inset-0 z-[999] bg-white" />}
      <AnimatePresence>
        {!splashSeen && checked && (
          <motion.section data-component="Splash" className="fixed inset-0 z-[100] bg-ubo-whiscash text-white" layout>
            <motion.div
              role="button"
              className="btn btn--primary z-[99] hidden md:fixed md:block"
              animate="default"
              onClick={() => handleGo()}
              style={{ top: y - 36, left: x - 70 }}
            >
              <span className="ml-2">Lets go!</span>
            </motion.div>
            <div className="presentation-container grid h-full grid-cols-4 overflow-hidden">
              <div className="col-span-4 self-center overflow-hidden md:order-last md:col-span-1 md:-ml-80">
                <motion.div
                  initial={{ opacity: 0, x: '100%' }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: '100%' }}
                  transition={{
                    bounce: 0
                  }}
                  className="relative mx-auto h-[280px] w-[300px] bg-ubo-whiscash md:h-[580px] md:w-[600px]"
                >
                  <div className="absolute inset-0">
                    <video autoPlay muted loop playsInline className="aspect-square h-full w-full">
                      <source src={fields.splashVideo || ''} type="video/mp4" />
                    </video>
                  </div>
                  <SplashArrows className="relative h-full w-full" />
                </motion.div>
              </div>
              <div className="relative z-[2] col-span-4 self-center md:order-first md:col-span-3">
                <h1 className="font-september text-5xl font-black md:text-10xl">
                  {fields.animatedHeading?.map((edge, wordIndex) => (
                    <motion.span
                      className="block"
                      key={edge?.description}
                      initial={{ y: '100vh', x: `${10 * wordIndex}%` }}
                      animate={{ y: 0 }}
                      transition={{ delay: wordIndex * 0.25, type: 'spring', duration: 0.8, bounce: 0.1 }}
                    >
                      {edge?.description}
                    </motion.span>
                  ))}
                </h1>
              </div>
              <div ref={mobileButtonWrapperRef} className="col-span-4 flex items-center justify-center md:hidden">
                <motion.div
                  className="btn btn--light--filled"
                  drag
                  dragConstraints={mobileButtonWrapperRef}
                  dragSnapToOrigin
                  onClick={() => handleGo()}
                  onDragTransitionEnd={() => handleGo()}
                >
                  Lets go!
                </motion.div>
              </div>
            </div>
          </motion.section>
        )}
      </AnimatePresence>
    </>
  )
}
