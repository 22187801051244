import { LossePlaatjie } from '@ubo/losse-sjedel'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Hero } from '~/graphql/types'

export default function HeroDefault({ fields }: { fields: Page_Flexcontent_Flex_Hero }) {
  let bgClass
  let textClass
  switch (fields.appearance?.bgColor) {
    case 'lightBlue':
      bgClass = `bg-ubo-whiscash`
      break
    case 'darkBlue':
      bgClass = `bg-ubo-taillow`
      textClass = `text-ubo-delibird`
    default:
      bgClass = `bg-transparent`
      textClass = `text-ubo-whiscash`
      break
  }

  return (
    <section
      data-component="HeroDefault"
      data-scrollindicatortitle={fields.scrollIndicatorTitle ?? undefined}
      id={fields.sectionId ?? undefined}
      className={`${bgClass} mb-12 lg:mb-24`}
    >
      <div className="container py-8 lg:py-20">
        <div className="mb-24 grid grid-cols-2 gap-x-12">
          <div className="col-span-2 mb-6 sm:col-span-1 sm:mb-0">
            <Content className={`content-xl ${textClass}`}>{fields.title}</Content>
          </div>
          <div className="col-span-2 sm:col-span-1">
            <Content className={`content-xl ${textClass}`}>{fields?.description}</Content>
          </div>
        </div>
        <LossePlaatjie src={fields.image} />
      </div>
    </section>
  )
}
