export default function SplashArrows({ className = '' }: { className?: string }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="654" height="637" fill="none" viewBox="0 0 654 637" className={className}>
      <path
        fill="#000CED"
        d="M0 0v637h654V0H0zm10.168 559.281l235.309-240.967L10.167 77.719l66.09-68.05L378.02 318.685 76.258 627.332l-66.09-68.051zm332.265 68.051l-66.453-68.051 235.309-240.967L275.98 77.719l66.453-68.05 301.399 309.017-301.399 308.646z"
      ></path>
    </svg>
  )
}
