export default function Muted({ className }: { className?: string }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <g fill="#000CED" clipPath="url(#clip0_2251_20085)">
        <path d="M9.6 14l-3.5 3.4L2.7 14l-1.4 1.3 3.5 3.5-3.5 3.4 1.4 1.4 3.4-3.5 3.5 3.5 1.3-1.4-3.4-3.4 3.4-3.5L9.6 14z"></path>
        <path d="M17.6.6C17.2.7 8.5 6 8.5 6c-.8.9-1.3 2.9-1.4 5 3.9.5 6.9 3.8 6.9 7.8 0 .8-.1 1.5-.3 2.2 1.9 1.1 3.6 2.1 4 2.2 2.8 1 5.8-3.6 5.8-11.3C23.4 4.2 20.1-.4 17.6.6zm2.6 18.6c-.9 2.1-2.9 2.2-3.8 0-.6-1.3-.9-3.1-1.1-4.7h.5c1.5 0 2.7-1.2 2.7-2.7 0-1.5-1.2-2.7-2.7-2.7h-.5c.2-1.6.6-3.4 1.2-4.6 1-2.1 2.8-2.2 3.8 0 1.6 3.7 1.8 10.5-.1 14.7zM6.1 6.2H2.5C1.3 7.3.6 9.6.6 11.9c0 .4 0 .9.1 1.3 1.2-1.2 2.8-2 4.5-2.2.1-1.9.4-3.6.9-4.8z"></path>
      </g>
      <defs>
        <clipPath id="clip0_2251_20085">
          <path fill="#fff" d="M0 0H24V24H0z"></path>
        </clipPath>
      </defs>
    </svg>
  )
}
