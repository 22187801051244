// @ubo
import { LosseLink, useLosseLanguage } from '@ubo/losse-sjedel'
import Loading from '~/components/elements/svg/Loading'
import Form from '~/components/elements/Form'
import Content from '~/components/elements/Content'
import X from '~/components/elements/svg/X'
import type { Page_Flexcontent_Flex_Form } from '~/graphql/types'

export default function FormContact({ fields }: { fields: Page_Flexcontent_Flex_Form }) {
  const [, locale] = useLosseLanguage()

  return (
    <section
      data-component="FormContact"
      data-scrollindicatortitle={fields.scrollIndicatorTitle ?? undefined}
      id={fields.sectionId ?? undefined}
      className="relative min-h-screen overflow-hidden bg-ubo-whiscash"
    >
      <X inView={true} className="absolute right-0 top-0 block h-full w-auto" />
      <div className="container relative pb-52 pt-40 lg:pt-52 2xl:pl-24 2xl:pt-72">
        <div className="flex flex-col items-start text-ubo-delibird lg:flex-row lg:justify-between">
          <div className="mb-12 mr-12 lg:mb-0">
            <Content className="mb-4 children-a:border children-a:border-ubo-delibird children-a:px-2 children-a:py-1">
              {fields?.description}
            </Content>
            <Content className="content-2xl content-stroke content-stroke-light">{fields?.title}</Content>
          </div>

          <Form
            data={fields.form}
            generate
            renderLoading={() => <Loading />}
            renderStatus={({ content }) => (
              <Content className="content-xl mr-24 mt-24 border-2 border-ubo-whiscash bg-ubo-taillow p-12 text-white">{content}</Content>
            )}
            className="form-contact flex h-full lg:ml-20"
            privacyUrl={locale === 'nl_NL' ? '/privacyverklaring/' : '/en/privacy-statement/'}
            privacyUrlPlacement={locale === 'nl_NL' ? 'privacybeleid' : 'privacy policy'}
            scrollToConfirm={false}
          />
          {/* <FormInner /> */}
          {/* </Form> */}
        </div>
        <div className="mt-12 flex flex-col items-start justify-between text-ubo-delibird lg:mt-40 lg:flex-row">
          <div>
            <Content className="content-2xl content-stroke content-stroke-light mb-6 lg:mb-0">{fields?.contactInfo?.title}</Content>
          </div>
          <div>
            <div className="grid grid-cols-2 gap-x-8 lg:gap-x-40">
              {fields.contactInfo?.locations?.map((edge) => {
                return (
                  <div key={edge?.name} className="col-span-2 mb-12 sm:col-span-1 sm:mb-0">
                    <h3 className="text-xl font-bold sm:text-2xl">{edge?.name}</h3>
                    <Content className="mb-6 mt-3">{edge?.address}</Content>
                    <LosseLink to={edge?.phone?.url} className="group flex items-center sm:text-xl">
                      <span className="mr-3 font-bold">T</span>
                      <span className="group-hover:underline">{edge?.phone?.title}</span>
                    </LosseLink>
                    <LosseLink to={edge?.email?.url} className="group flex items-center sm:text-xl">
                      <span className="mr-3 font-bold">E</span>
                      <span className="group-hover:underline">{edge?.email?.title}</span>
                    </LosseLink>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

// const FormInner = () => {
//   return (
//     <>
//       <div className="mt-12 flex flex-col items-center sm:flex-row">
//         <div className="mr-4 w-full sm:mr-6">
//           <FormDuufField id={4} />
//         </div>
//         <div className="mr-4 w-full sm:mr-8">
//           <FormDuufField id={1} />
//         </div>
//       </div>
//       <div className="flex flex-col items-center sm:flex-row">
//         <div className="mr-4 w-full sm:mr-6">
//           <FormDuufField id={6} />
//         </div>
//         <div className="mr-4 w-full sm:mr-6">
//           <FormDuufField id={7} />
//         </div>
//       </div>
//       <div className="flex items-end justify-between">
//         <div className="mr-4 w-full sm:mr-8">
//           <FormDuufField id={2} />
//         </div>
//       </div>
//       <div className="mt-6 flex items-center justify-between">
//         <FormDuufField id={3} />

//         <FormDuufSubmit />
//       </div>
//     </>
//   )
// }
