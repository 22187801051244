import { LosseFlexShell } from "@ubo/losse-sjedel"
import type { LosseFlexShellProps, LosseFlexShellType } from "@ubo/losse-sjedel"
import WebinarsSlider from "./WebinarsSlider"

export default function WebinarsShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    slider: WebinarsSlider,
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
