// Types
import { LosseLink } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import { useState } from 'react'
import Content from '~/components/elements/Content'
import FormModal from '~/components/elements/FormModal'
import type { Page_Flexcontent_Flex_Cta } from '~/graphql/types'

export default function CtaDefault({ fields }: { fields: Page_Flexcontent_Flex_Cta }) {
  const [formOpen, setFormOpen] = useState(false)

  return (
    <section
      data-component="CtaDefault"
      data-scrollindicatortitle={fields.scrollIndicatorTitle ?? undefined}
      id={fields.sectionId ?? undefined}
      className="overflow-hidden bg-ubo-whiscash py-12 text-white lg:py-24"
    >
      <div className="container">
        <div className="grid grid-cols-2 items-center 2xl:pl-24">
          <div className="col-span-2 lg:col-span-1">
            <Content className="content-sm children-headings:font-museo">{fields.title}</Content>
            <h3 className="text-lg">{fields.subtitle}</h3>
            <div className="mt-7 flex flex-wrap">
              {fields.links?.map((edge) => {
                return (
                  <div key={edge?.link?.title} className="flex flex-col">
                    {!edge?.linkIsForm && (
                      <>
                        <LosseLink
                          src={edge?.link}
                          className={clsx(
                            edge?.buttonType === 'outline' && 'btn--light',
                            edge?.buttonType === 'filled' && 'btn--light--filled',
                            'btn--sm mr-3 w-fit text-sm sm:text-base lg:mr-7'
                          )}
                        />
                      </>
                    )}
                    {edge?.linkIsForm && (
                      <>
                        <div
                          role={'button'}
                          onClick={() => setFormOpen(true)}
                          className={clsx(
                            edge?.buttonType === 'outline' && 'btn--light',
                            edge?.buttonType === 'filled' && 'btn--light--filled',
                            'btn--sm mr-3 w-fit text-sm sm:text-base lg:mr-7'
                          )}
                        >
                          <span>{edge.link?.title}</span>
                        </div>
                        <span className="px-2 pt-2 text-xs md:px-4 lg:text-sm">{edge?.microcopy}</span>

                        <FormModal isOpen={formOpen} form={edge.form} setFormOpen={setFormOpen} description={edge.formDescription} />
                      </>
                    )}
                  </div>
                )
              })}
            </div>
          </div>
          <div className="col-span-2 mt-6 lg:col-span-1 lg:mt-0">
            <Content className="content-3xl content-stroke-light text-right">{fields.description}</Content>
          </div>
        </div>
      </div>
    </section>
  )
}
