import type { Dispatch, SetStateAction } from 'react'
import { useEffect, useRef } from 'react'
import ReactModal from 'react-modal'
import type { GfForm, Maybe } from '~/graphql/types'
import useClientMediaQuery from '~/hooks/useMediaQuery'
import useOnClickOutside from '~/hooks/useOnClickOutside'
import Content from './Content'
import Form from './Form'
import Loading from './svg/Loading'

// Tailwind watch:
// md:col-span-1 lg:col-span-1 col-span-1 col-span-2

export default function FormModal({
  isOpen,
  form,
  setFormOpen,
  description
}: {
  description?: Maybe<string | undefined>
  isOpen: boolean
  form: Maybe<GfForm> | undefined
  setFormOpen: Dispatch<SetStateAction<boolean>>
}) {
  const isDesktop = useClientMediaQuery('(min-width: 992px)')

  const ref = useRef(null)

  useOnClickOutside(ref, handleClickOutside)

  function handleClickOutside() {
    setFormOpen(false)
  }

  useEffect(() => {
    const { documentElement: html } = document
    if (isOpen) {
      html.classList.add('overflow-hidden')
    } else {
      html.classList.remove('overflow-hidden')
    }
  }, [isOpen])

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: isDesktop ? 'auto' : '0%',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      background: 'transparent',
      border: 0,
      width: isDesktop ? '50%' : '100%',
      height: 'auto',
      backgroundColor: 'rgb(0 7 236)',
      borderRadius: '1.5rem'
    }
  }

  return (
    <ReactModal isOpen={isOpen} style={customStyles}>
      <div ref={ref} className="overflow-y-scroll py-8 px-4 sm:px-10 lg:px-12 lg:py-8">
        <Content className="content-md mb-16 text-white">{description}</Content>
        <Form
          className="form-modal"
          generate
          data={form}
          renderLoading={() => <Loading />}
          renderStatus={({ content }) => <Content className="content-xs text-white">{content}</Content>}
        />
      </div>
    </ReactModal>
  )
}
