export default function CursorMove({ className = '' }: { className?: string }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" fill="none" viewBox="0 0 46 46" className={className}>
      <path
        fill="#fff"
        d="M44.764 26.348c-.447.505-.886.984-1.187 1.285l-4.303 4.375a1.918 1.918 0 01-2.734-2.683l4.315-4.39.016-.018H24.92v15.822l4.28-4.206a1.917 1.917 0 012.685 2.734l-4.37 4.293c-.312.312-.79.75-1.291 1.194a4.937 4.937 0 01-3.167 1.234.576.576 0 01-.054.012h-.02a4.954 4.954 0 01-3.329-1.24 32.666 32.666 0 01-1.284-1.187l-4.377-4.306a1.917 1.917 0 112.684-2.734l4.39 4.315.02.015V24.917H5.137l.029.028 4.301 4.38a1.917 1.917 0 11-2.733 2.683l-4.293-4.364a32.383 32.383 0 01-1.196-1.294A4.946 4.946 0 010 23.021V23c0-.02.01-.036.012-.055a4.931 4.931 0 011.234-3.169 35.87 35.87 0 011.185-1.284l4.304-4.378a1.917 1.917 0 012.733 2.684L5.26 21.083h15.827V5.137l-.029.027-4.38 4.3a1.916 1.916 0 11-2.684-2.73l4.367-4.292c.313-.312.792-.75 1.294-1.196a4.96 4.96 0 016.575 0c.503.447.98.882 1.283 1.187l4.378 4.3a1.918 1.918 0 01-.547 3.204 1.916 1.916 0 01-2.136-.472L24.92 5.26v15.823h15.827l-4.217-4.28a1.918 1.918 0 112.733-2.683l4.292 4.368c.313.313.75.792 1.197 1.294a4.962 4.962 0 01.011 6.566z"
      ></path>
    </svg>
  )
}
