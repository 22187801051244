import type { MouseEvent } from 'react'
import { useEffect, useRef, useState } from 'react'
import Content from '~/components/elements/Content'
import Identity from '~/components/elements/svg/Identity'
import { motion, useMotionValue, useScroll, useSpring, useTransform } from 'framer-motion'
import type { Page_Flexcontent_Flex_Hero } from '~/graphql/types'
import { LosseLink, useLosseLanguage } from '@ubo/losse-sjedel'
import ExternalURL from '~/components/elements/svg/ExternalURL'

export default function HeroMagic({ fields }: { fields: Page_Flexcontent_Flex_Hero }) {
  const [, locale] = useLosseLanguage()
  const identityRef = useRef(null)
  const reelRef = useRef<HTMLVideoElement | null>(null)
  const buttonRef = useRef<HTMLDivElement>(null)
  const [hoverReel, setHoverReel] = useState(false)
  const [initialButtonPosition, setInitialButtonPosition] = useState({
    x: 0,
    y: 0
  })

  const { scrollYProgress } = useScroll({
    target: identityRef
  })

  const cursorX = useMotionValue(190)
  const cursorY = useMotionValue(120)

  const transform = useTransform(scrollYProgress, [0, 1], [-50, -10])
  const xPercentage = useTransform(transform, (value) => `${value}%`)

  function handleCursor(event: MouseEvent<HTMLVideoElement, globalThis.MouseEvent>) {
    if (reelRef.current) {
      const { left, top } = reelRef.current.getBoundingClientRect()

      cursorX.set(event.clientX - left - 100)
      cursorY.set(event.clientY - top - 36)
    }
  }

  useEffect(() => {
    if (!hoverReel) {
      cursorX.set(initialButtonPosition.x)
      cursorY.set(initialButtonPosition.y)
    }

    const videoWidth = reelRef.current?.clientWidth
    const videoHeight = reelRef.current?.clientHeight

    if (videoWidth && videoHeight) {
      const buttonX = videoWidth / 2
      const buttonY = videoHeight / 2

      if (!buttonRef.current) return

      setInitialButtonPosition({
        x: buttonX - buttonRef.current.clientWidth / 2,
        y: buttonY - buttonRef.current.clientHeight / 2
      })
    }
  }, [cursorX, cursorY, hoverReel, initialButtonPosition.x, initialButtonPosition.y])

  const springConfig = { damping: 25, stiffness: 700 }
  const cursorXSpring = useSpring(cursorX, springConfig)
  const cursorYSpring = useSpring(cursorY, springConfig)

  return (
    <section
      data-component="HeroMagic"
      data-scrollindicatortitle={fields.scrollIndicatorTitle ?? undefined}
      id={fields.sectionId ?? undefined}
      className="presentation-section relative overflow-hidden bg-ubo-delibird"
    >
      <div className="absolute -bottom-6 overflow-hidden lg:-bottom-20" ref={identityRef}>
        <div className="relative h-full w-full overflow-hidden">
          <motion.div key="moving_identity" style={{ translateX: xPercentage }} className="w-full">
            <Identity type="dark" className="h-full w-full" />
          </motion.div>
        </div>
      </div>

      <div className="container py-24">
        <div className="grid grid-cols-12">
          <div className="col-span-12 max-md:mb-4 md:col-span-5">
            <Content className="content-3xl">{fields.title}</Content>
          </div>
          <div className="col-span-12 md:col-span-7">
            <Content>{fields.description}</Content>
            <LosseLink to={fields.link?.url} className="group mt-4 inline-flex items-center text-ubo-whiscash">
              <div className="mr-3 flex items-center justify-center rounded-full border-2 border-ubo-whiscash px-6 py-3">
                <ExternalURL className="h-4 w-4 transition-transform group-hover:-translate-y-1 group-hover:translate-x-1 children:fill-ubo-whiscash" />
              </div>
              <span className="group-hover:underline">{fields.link?.title}</span>
            </LosseLink>
          </div>
        </div>
        <div
          className="relative mx-auto mt-10 h-full cursor-pointer md:mt-20 xl:w-[75%]"
          onMouseEnter={() => setHoverReel(true)}
          onMouseLeave={() => setHoverReel(false)}
        >
          <motion.div
            key={`reel`}
            ref={buttonRef}
            className="btn btn--light--filled pointer-events-none absolute z-[99] px-2 shadow-sm lg:px-4"
            style={{
              translateX: cursorXSpring,
              translateY: cursorYSpring
            }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{
              duration: 3
            }}
          >
            <LosseLink to={fields?.link?.url} target={fields?.link?.target || ''} className="ml-2">
              {locale === 'nl_NL' ? 'Bezoek onze studio' : 'Visit our studio'}
            </LosseLink>
          </motion.div>
          <motion.video
            ref={reelRef}
            onMouseMove={(event) => handleCursor(event)}
            muted
            autoPlay
            playsInline
            loop
            initial={{ x: '70vw' }}
            animate={{ x: '0vw' }}
            transition={{
              delay: 0.25,
              bounce: 0,
              duration: 0.75
            }}
            className="h-full w-full object-cover"
          >
            <source
              src="https://player.vimeo.com/progressive_redirect/playback/856692337/rendition/1080p/file.mp4?loc=external&log_user=0&signature=cc16b3deedbed5fe8ac4e4f75dd37373f2aa1155ecb6ea14207c7c8e0d8af108"
              type="video/mp4"
            />
          </motion.video>
        </div>
      </div>
    </section>
  )
}
