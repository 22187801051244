import { useRef } from 'react'
import { motion, useInView } from 'framer-motion'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Banner } from '~/graphql/types'
import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'

export default function BannerSupport({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  const sectionRef = useRef<HTMLElement>(null)

  const inView = useInView(sectionRef, { once: true })

  return (
    <section
      data-component="BannerSupport"
      data-scrollindicatortitle={fields.scrollIndicatorTitle ?? undefined}
      id={fields.sectionId ?? undefined}
      ref={sectionRef}
      className="mx-auto max-w-screen-3xl pt-28 lg:pt-32"
    >
      <div className="container">
        <div className="grid gap-x-10 lg:grid-cols-2 xl:gap-x-20">
          <motion.div
            initial={{ x: '-110%' }}
            animate={inView ? { x: 0 } : { x: '-110%' }}
            transition={{
              bounce: 0,
              duration: 1
            }}
            className="col-span-1 mb-6 bg-white pl-6 lg:mb-0 lg:pl-24"
          >
            <Content className="content-2xl xl:content-3xl text-stroke stroke-dark lg:leading-[131px]">{fields?.title}</Content>
          </motion.div>
          <motion.div
            initial={{ x: '-110%' }}
            animate={inView ? { x: 0 } : { x: '110%' }}
            transition={{
              bounce: 0,
              duration: 1
            }}
            className="col-span-1 bg-ubo-whiscash px-6 lg:py-8"
          >
            <div className="flex h-full items-start justify-center py-6 lg:items-center lg:justify-end lg:py-0">
              <div className="pr-7">
                <Content className="content-sm mb-6 text-white">{fields?.ctaSupport?.description}</Content>
                <LosseLink to={fields?.ctaSupport?.phone?.url} className="group mb-3 flex items-center justify-end">
                  <span className="pr-4 font-light text-white group-hover:underline sm:text-xl">{fields?.ctaSupport?.phone?.title}</span>
                  <svg width="19" height="19" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M22.1313 16.0686C22.6876 16.6264 22.9999 17.382 22.9999 18.1698C22.9999 18.9576 22.6876 19.7132 22.1313 20.271L21.2559 21.2789C13.3934 28.8025 -5.73634 9.67856 1.67404 1.79303L2.77696 0.833138C3.33571 0.292498 4.08466 -0.00671032 4.86212 0.00011423C5.63958 0.00693878 6.38317 0.319249 6.93234 0.869614C6.96114 0.899371 8.73982 3.2108 8.73982 3.2108C9.26744 3.76508 9.56124 4.50134 9.56017 5.2666C9.55909 6.03186 9.26324 6.76729 8.73406 7.3201L7.62154 8.71867C8.23669 10.2133 9.14111 11.5717 10.2829 12.7158C11.4246 13.8598 12.7811 14.767 14.2746 15.3851L15.6808 14.2669C16.2336 13.738 16.9688 13.4423 17.7338 13.4412C18.4989 13.4401 19.2349 13.7337 19.7892 14.2611C19.7892 14.2611 22.1015 16.0398 22.1313 16.0686ZM20.8114 17.4643C20.8114 17.4643 18.5144 15.6961 18.4847 15.6673C18.2869 15.4713 18.0197 15.3612 17.7412 15.3612C17.4627 15.3612 17.1955 15.4713 16.9978 15.6673C16.9719 15.6923 15.0358 17.2358 15.0358 17.2358C14.9053 17.3397 14.75 17.4077 14.5852 17.4333C14.4205 17.4589 14.2518 17.4411 14.096 17.3817C12.1601 16.6618 10.4018 15.5338 8.94029 14.0744C7.47882 12.6149 6.34846 10.858 5.62592 8.92312C5.56271 8.76581 5.54229 8.59457 5.56674 8.42681C5.59119 8.25905 5.65963 8.10075 5.76511 7.96803C5.76511 7.96803 7.30766 6.03192 7.33357 6.00601C7.52965 5.80825 7.63966 5.54105 7.63966 5.26257C7.63966 4.98409 7.52965 4.71688 7.33357 4.51913C7.30478 4.49033 5.53665 2.19235 5.53665 2.19235C5.33606 2.01225 5.07418 1.9157 4.80468 1.92248C4.53519 1.92926 4.27849 2.03886 4.08721 2.22882L2.9843 3.18872C-2.43334 9.69872 14.1469 25.3603 19.8515 19.9696L20.727 18.9617C20.9334 18.7723 21.0577 18.5098 21.0735 18.2301C21.0892 17.9505 20.9952 17.6757 20.8114 17.4643Z"
                      fill="white"
                    />
                  </svg>
                </LosseLink>
                <LosseLink to={`mailto:${fields?.ctaSupport?.email}`} className="group flex items-center">
                  <span className="pr-4 font-light text-white group-hover:underline sm:text-xl">{fields?.ctaSupport?.email}</span>
                  <svg width="20" height="20" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M25.046 0.954878C24.6575 0.561756 24.1743 0.275454 23.6428 0.123633C23.1114 -0.0281878 22.5498 -0.0403955 22.0123 0.0881894L4.67664 3.73911C3.6783 3.87601 2.73801 4.28889 1.96166 4.93126C1.18532 5.57362 0.603754 6.41995 0.282462 7.37494C-0.0388302 8.32993 -0.0870878 9.35565 0.143124 10.3366C0.373335 11.3175 0.872872 12.2147 1.58548 12.9271L3.44689 14.7872C3.54764 14.8879 3.62753 15.0075 3.68201 15.1391C3.73648 15.2707 3.76446 15.4118 3.76435 15.5542V18.9863C3.76674 19.4689 3.87784 19.9447 4.08939 20.3784L4.08072 20.386L4.10889 20.4142C4.42637 21.0524 4.94489 21.5686 5.58459 21.8832L5.61276 21.9114L5.62035 21.9027C6.05413 22.1143 6.53 22.2253 7.01261 22.2277H10.4451C10.7322 22.2275 11.0078 22.3413 11.2111 22.5441L13.0714 24.4042C13.5704 24.9086 14.1643 25.3094 14.8188 25.5832C15.4734 25.8571 16.1757 25.9988 16.8853 26C17.4766 25.9993 18.0638 25.9027 18.6242 25.714C19.5706 25.4033 20.4113 24.8343 21.0515 24.0713C21.6917 23.3083 22.1059 22.3816 22.2474 21.3957L25.9041 4.02404C26.0394 3.48197 26.0314 2.91405 25.881 2.37599C25.7305 1.83793 25.4428 1.34821 25.046 0.954878ZM4.98109 13.2575L3.1186 11.3974C2.6849 10.9742 2.38094 10.4361 2.24237 9.84619C2.10381 9.25631 2.13641 8.63914 2.33633 8.06714C2.53016 7.48031 2.88847 6.96149 3.36866 6.57239C3.84885 6.18328 4.4307 5.94027 5.04502 5.87225L22.2084 2.25924L5.92914 18.5389V15.5542C5.93078 15.1278 5.84783 14.7052 5.68511 14.311C5.52238 13.9167 5.28309 13.5587 4.98109 13.2575ZM20.1183 21.0252C20.0352 21.6236 19.7868 22.1869 19.4012 22.652C19.0156 23.1171 18.5079 23.4655 17.9353 23.6582C17.3626 23.8508 16.7476 23.88 16.1593 23.7425C15.5709 23.6051 15.0325 23.3064 14.6045 22.8799L12.741 21.0165C12.4401 20.7141 12.0824 20.4743 11.6883 20.3111C11.2942 20.1478 10.8716 20.0643 10.4451 20.0654H7.46009L23.7415 3.79111L20.1183 21.0252Z"
                      fill="white"
                    />
                  </svg>
                </LosseLink>
              </div>
              <div className="aspect-square h-[100px] w-[100px] xl:h-[205px] xl:w-[205px]">
                <LossePlaatjie src={fields?.ctaSupport?.image} className="h-full w-full rounded-full object-cover object-top" />
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  )
}
