export default function WebinarX({ className = '' }: { className?: string }) {
  return (
    <svg width="1920" height="954" viewBox="0 0 1920 954" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <mask id="mask0_302_379" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="0" y="-1" width="1920" height="955">
        <path d="M0 -0.00012207L0 953.991L1920 953.991V-0.00012207L0 -0.00012207Z" fill="white" />
      </mask>
      <g mask="url(#mask0_302_379)">
        <path d="M1709.42 -359.045L762.98 476.987L1709.42 1313.02H2103.45L1157.01 476.987L2103.45 -359.045H1709.42Z" fill="#030250" />
        <path d="M2300.47 -359.045L1354.03 476.987L2300.47 1313.02H2694.49L1748.06 476.987L2694.5 -359.045H2300.47Z" fill="#030250" />
        <path d="M210.574 1313.02L1157.02 476.987L210.584 -359.045H-183.445L762.99 476.987L-183.445 1313.02H210.574Z" fill="#030250" />
        <path d="M-380.47 1313.02L565.965 476.987L-380.47 -359.045H-774.5L171.936 476.987L-774.5 1313.02H-380.47Z" fill="#030250" />
        <path d="M1906.43 1661.09L959.995 825.061L13.5598 1661.09V2009.16L959.995 1173.13L1906.43 2009.16V1661.09Z" fill="#030250" />
        <path d="M13.5598 -707.111L959.995 128.921L1906.43 -707.111V-1055.18L959.995 -219.144L13.5598 -1055.18V-707.111Z" fill="#030250" />
      </g>
    </svg>

  )
}
