import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import { useRef, useState } from 'react'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Banner } from '~/graphql/types'
import { motion, useInView } from 'framer-motion'
import Arrow from '~/components/elements/svg/Arrow'
import FormModal from '~/components/elements/FormModal'

export default function BannerDetail({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  const [formOpen, setFormOpen] = useState(false)

  const sectionRef = useRef<HTMLElement>(null)
  const contentRef = useRef<HTMLDivElement>(null)

  const inView = useInView(sectionRef, { once: true })

  return (
    <section
      data-component="BannerDetail"
      data-scrollindicatortitle={fields.scrollIndicatorTitle ?? undefined}
      ref={sectionRef}
      id={fields.sectionId ?? undefined}
      className="mb-8 overflow-hidden pt-20"
    >
      <div className={clsx(fields.image?.mimeType === 'image/svg+xml' ? 'pt-20' : 'pt-7 sm:pb-20 sm:pt-24', 'container lg:py-20')}>
        <div className="grid grid-cols-3 items-start lg:gap-x-12 2xl:gap-x-24">
          <div className="col-span-3 lg:col-span-2">
            <div ref={contentRef}>
              <div className="relative sm:mb-12">
                <motion.div initial={{ scale: 0.25, opacity: 0 }} animate={inView ? { scale: 1, opacity: 1 } : { scale: 0.25, opacity: 0 }}>
                  <LossePlaatjie
                    src={fields.image}
                    maxwidth={600}
                    className={clsx(
                      fields.appearance?.imageWidthMobile === 'default' ? 'w-2/5 max-w-[150px] md:max-w-[200px]' : 'w-1/3',
                      fields.image?.mimeType === 'image/svg+xml' ? '-top-20 opacity-75' : 'top-0 opacity-100 sm:-top-24',
                      'absolute -right-5 lg:hidden'
                    )}
                  />
                </motion.div>
                {fields.previousLink?.url && (
                  <LosseLink className="group mb-6 inline-flex items-center font-september text-xl" to={fields.previousLink?.url}>
                    <Arrow type="lightBlue" className="mr-3 rotate-180" />
                    <span className="group-hover:underline">{fields.previousLink?.title}</span>
                  </LosseLink>
                )}

                <motion.div
                  animate={inView ? { x: 0 } : { x: '-100%' }}
                  className={clsx(fields.image?.mimeType !== 'image/svg+xml' && 'max-lg:mb-28 max-md:mb-12 max-sm:w-1/2')}
                >
                  <Content
                    className={clsx(
                      fields.appearance?.titleSize === 'huge' && 'content-2xl',
                      fields.appearance?.titleSize === 'big' && 'content-xl max-sm:children-headings:text-3xl',
                      fields.appearance?.titleSize === 'small' && 'content-lg',
                      'content-stroke relative children-headings:mb-4 children-h2:text-2xl children-h2:leading-none lg:children-h2:pl-2'
                    )}
                  >
                    {fields.title}
                  </Content>
                  {fields.subtitle && (
                    <Content className="content-md mb-6 children-headings:font-museo children-headings:leading-[1.25] children-headings:text-ubo-whiscash max-lg:children-headings:text-3xl max-sm:children-headings:text-xl">
                      {fields.subtitle}
                    </Content>
                  )}
                </motion.div>
              </div>
              <motion.div initial={{ opacity: 0 }} animate={inView ? { x: 0, opacity: 1 } : { x: '-100%', opacity: 0 }}>
                <div className="lg:pl-12 2xl:pl-24">
                  <Content
                    className={clsx(
                      fields.appearance?.contentListStyle === 'checks' && 'content-list-style-checkmarks',
                      'content-xs children-headings:mb-5 lg:children-ul:text-xl'
                    )}
                  >
                    {fields.description}
                  </Content>
                  {fields.cta?.description && (
                    <div className="mt-6 flex flex-col xl:flex-row xl:items-center">
                      <Content className="sm:pr-12 xl:w-2/3 2xl:pr-24">{fields.cta.description}</Content>
                      <div className="mt-3 xl:mt-0 xl:w-1/3">
                        {fields.cta.link?.url && fields.cta.link.title && !fields.cta.linkIsForm && (
                          <LosseLink
                            className="btn--sm btn--primary--filled"
                            to={fields.cta.link?.url}
                            target={fields.cta.link.target || '_self'}
                          >
                            {fields.cta.icon && <LossePlaatjie className="mr-3 h-6 w-5" src={fields.cta.icon} />}
                            <span>{fields.cta.link?.title}</span>
                          </LosseLink>
                        )}
                        {fields.cta.linkIsForm && (
                          <>
                            <button onClick={() => setFormOpen(true)} className="btn--sm btn--primary--filled">
                              {fields.cta.icon && <LossePlaatjie className="mr-3" src={fields.cta.icon} />}
                              <span>{fields.cta.link?.title}</span>
                            </button>
                            <FormModal
                              isOpen={formOpen}
                              form={fields.cta?.form}
                              setFormOpen={setFormOpen}
                              description={fields.cta.formDescription}
                            />
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </motion.div>
            </div>
          </div>
          <div className="col-span-3 -mr-20 hidden lg:col-span-1 lg:block">
            <motion.div
              initial={{ scale: 0.25 }}
              animate={inView ? { scale: 1 } : { scale: 0.25 }}
              transition={{
                duration: 0.5
              }}
            >
              <LossePlaatjie
                src={fields.image}
                maxwidth={600}
                className={clsx(fields.appearance?.titleSize === 'huge' && 'lg:h-auto lg:w-[28rem] 2xl:w-[33rem]', 'object-cover')}
              />
            </motion.div>
          </div>
        </div>
      </div>
    </section>
  )
}
