import { useLosseBlogBink } from '@ubo/losse-sjedel'
import type { Category, Maybe } from '~/graphql/types'

export default function PostsFilters({ title = 'Filters', filters }: { title?: string; filters: Maybe<Maybe<Category>[]> | undefined }) {
  const bb = useLosseBlogBink()

  if (!filters || filters?.length === 0) return null

  const parentFilters = filters?.filter((edge) => !edge?.parentDatabaseId)
  const activeFilters = filters?.filter((edge) => bb.isFilterActive(edge?.databaseId))

  return (
    <div className="mt-6">
      <span className="mb-3 block font-semibold text-ubo-delibird">{title}</span>
      <div className="flex flex-wrap items-center gap-4">
        {activeFilters?.map((filter) => (
          <div
            key={filter?.databaseId}
            className="flex items-center gap-2 rounded-full border-2 border-white bg-white px-5 py-2 text-ubo-whiscash"
          >
            <button
              type="button"
              onClick={() => {
                bb.toggleFilter(filter?.databaseId)
              }}
            >
              <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_3081_3653)">
                  <path
                    d="M15.8048 0.695435C15.6798 0.570454 15.5102 0.500244 15.3334 0.500244C15.1567 0.500244 14.9871 0.570454 14.8621 0.695435L8.0001 7.55744L1.1381 0.695435C1.01308 0.570454 0.843545 0.500244 0.666768 0.500244C0.489992 0.500244 0.320454 0.570454 0.195435 0.695435C0.0704544 0.820454 0.000244141 0.989992 0.000244141 1.16677C0.000244141 1.34354 0.0704544 1.51308 0.195435 1.6381L7.05744 8.5001L0.195435 15.3621C0.0704544 15.4871 0.000244141 15.6567 0.000244141 15.8334C0.000244141 16.0102 0.0704544 16.1798 0.195435 16.3048C0.320454 16.4298 0.489992 16.5 0.666768 16.5C0.843545 16.5 1.01308 16.4298 1.1381 16.3048L8.0001 9.44277L14.8621 16.3048C14.9871 16.4298 15.1567 16.5 15.3334 16.5C15.5102 16.5 15.6798 16.4298 15.8048 16.3048C15.9298 16.1798 16 16.0102 16 15.8334C16 15.6567 15.9298 15.4871 15.8048 15.3621L8.94277 8.5001L15.8048 1.6381C15.9298 1.51308 16 1.34354 16 1.16677C16 0.989992 15.9298 0.820454 15.8048 0.695435Z"
                    fill="#000CED"
                  />
                </g>
              </svg>
            </button>
            {filter?.name}
          </div>
        ))}
        {parentFilters?.map((group) => {
          const items =
            filters?.filter((edge) => edge?.parentDatabaseId === group?.databaseId && !bb.isFilterActive(edge?.databaseId)) || []

          if (items.length === 0) return null

          return (
            <select
              key={group?.databaseId}
              className="custom--select--arrow rounded-full border-2 border-white bg-transparent py-2 pl-5 pr-8 text-white children-option:text-ubo-whiscash"
              onChange={(event) => {
                bb.toggleFilter(Number(event.target.value))
              }}
            >
              <option value="">{group?.name}</option>
              {items?.map((item) => (
                <option key={item?.databaseId} value={item?.databaseId}>
                  {item?.name}
                </option>
              ))}
            </select>
          )
        })}
      </div>
    </div>
  )
}
