import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import Content from '~/components/elements/Content'
import type { KnowledgeBaseItem, Page_Flexcontent_Flex_Featured } from '~/graphql/types'

const CATEGORY_NAME: { [key: number]: string } = {
  0: 'Lees hier',
  99: 'Lees hier de whitepaper',
  97: 'Bekijk hier de template'
}

export default function FeaturedWhitepaper({ fields }: { fields: Page_Flexcontent_Flex_Featured }) {
  const categoryIds = fields.filters?.map((filter) => filter?.databaseId ?? 0) ?? []

  const featured = fields?.whitepaper?.nodes?.find((node) => {
    const hasWhitepaperCategory =
      (node as KnowledgeBaseItem)?.categories?.nodes?.filter((category) => [99, 97].includes(Number(category.databaseId))) || []

    const hasCategory =
      (node as KnowledgeBaseItem)?.categories?.nodes?.filter((category) => categoryIds.includes(Number(category.databaseId))) || []

    return hasCategory.length > 0 && hasWhitepaperCategory.length > 0
  }) as KnowledgeBaseItem

  if (!featured) return null

  return (
    <section
      data-component="FeaturedWhitepaper"
      data-scrollindicatortitle={fields.scrollIndicatorTitle ?? undefined}
      id={fields.sectionId ?? undefined}
      className="section bg-ubo-whiscash"
    >
      <div className="container py-16 lg:py-20">
        <div className="grid grid-cols-2 gap-4 sm:gap-6 lg:gap-10">
          <div className="flex items-center">
            <div className="w-full">
              <Content className="content-3xl content-stroke-light uppercase text-white">{fields?.title}</Content>
              <Content className="content mt-10 line-clamp-4 text-white lg:mt-16">
                {featured?.recap?.shortExcerpt || featured?.recap?.excerpt}
              </Content>
              <div className="max-sm:hidden">
                <Links featured={featured} fields={fields} />
              </div>
            </div>
          </div>
          <div className="flex justify-end">
            <LossePlaatjie
              src={featured.recap?.highlightedThumbnail || featured.recap?.thumbnail}
              className="w-full max-w-[650px] object-contain"
            />
          </div>
        </div>
        <div className="sm:hidden">
          <Links featured={featured} fields={fields} />
        </div>
      </div>
    </section>
  )
}

function Links({ fields, featured }: { fields: Page_Flexcontent_Flex_Featured; featured: KnowledgeBaseItem }) {
  const categoryIds = Object.keys(CATEGORY_NAME).map((key) => Number(key))
  const categoryId = featured?.categories?.nodes?.filter((category) => categoryIds.includes(category?.databaseId))?.[0]

  return (
    <>
      <LosseLink to={featured?.uri ?? ''} className="btn btn--light--filled mt-10 lg:mt-16">
        <svg width="20" height="23" viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-3 h-4">
          <path
            d="M1 18.125V21.125C1 21.6773 1.44772 22.125 2 22.125H18C18.5523 22.125 19 21.6773 19 21.125V18.125"
            stroke="currentColor"
            strokeWidth="1.4"
            strokeLinecap="round"
          />
          <path
            d="M10.7 0.875C10.7 0.488401 10.3866 0.175 10 0.175C9.6134 0.175 9.3 0.488401 9.3 0.875L10.7 0.875ZM9.50502 16.37C9.77839 16.6433 10.2216 16.6433 10.495 16.37L14.9497 11.9152C15.2231 11.6418 15.2231 11.1986 14.9497 10.9253C14.6764 10.6519 14.2332 10.6519 13.9598 10.9253L10 14.8851L6.0402 10.9253C5.76683 10.6519 5.32362 10.6519 5.05025 10.9253C4.77689 11.1986 4.77689 11.6418 5.05025 11.9152L9.50502 16.37ZM9.3 0.875L9.3 15.875L10.7 15.875L10.7 0.875L9.3 0.875Z"
            fill="currentColor"
          />
        </svg>
        {CATEGORY_NAME[categoryId?.databaseId ?? 0]}
      </LosseLink>
      <LosseLink
        to={fields.link?.url}
        target={fields.link?.target || ''}
        className="mt-6 flex items-center text-base text-white lg:text-xl"
      >
        <span className="mr-3 block rounded-full border-2 border-white px-6 py-4">
          <svg width="17" height="11" viewBox="0 0 17 11" fill="none" xmlns="http://www.w3.org/2000/svg" className="">
            <path
              d="M1 4.8C0.613401 4.8 0.3 5.1134 0.3 5.5C0.3 5.8866 0.613401 6.2 1 6.2L1 4.8ZM16.495 5.99498C16.7683 5.72161 16.7683 5.27839 16.495 5.00503L12.0402 0.550253C11.7668 0.276886 11.3236 0.276886 11.0503 0.550253C10.7769 0.823621 10.7769 1.26684 11.0503 1.5402L15.0101 5.5L11.0503 9.4598C10.7769 9.73317 10.7769 10.1764 11.0503 10.4497C11.3236 10.7231 11.7668 10.7231 12.0402 10.4497L16.495 5.99498ZM1 6.2L16 6.2L16 4.8L1 4.8L1 6.2Z"
              fill="currentColor"
            />
          </svg>
        </span>
        {fields.link?.title}
      </LosseLink>
    </>
  )
}
