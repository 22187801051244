export default function StripeSmall({ className = '' }: { className?: string }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="656" height="90" fill="none" viewBox="0 0 656 90" className={className}>
      <g strokeMiterlimit="10" clipPath="url(#clip0_1458_19654)">
        <path
          stroke="#000CED"
          strokeWidth="2"
          d="M187.83 178.4l744.81-744.82M274.92 147.8l685.97-685.97M322.551 156.63l666.57-666.57M375.721 160.15L1017.47-481.6M403.949 188.39L1045.7-453.36M432.189 216.62l641.751-641.75M-134.34 75.33l641.75-641.75M-106.1 103.58l641.75-641.75M-77.86 131.81l641.751-641.75M-49.52 160.15L592.23-481.6M-21.29 188.39l641.751-641.75M6.95 216.62l641.749-641.75"
        ></path>
        <path
          stroke="#070744"
          strokeWidth="3"
          d="M-540.939 75.33l641.75-641.75M-512.689 103.58l641.75-641.75M-484.459 131.81l641.75-641.75M-456.119 160.15l641.75-641.75M-427.879 188.39l641.75-641.75M-399.65 216.62L242.1-425.13"
        ></path>
        <path stroke="#000CED" strokeWidth="2" d="M620.371 169.93l736.349-736.35"></path>
      </g>
      <defs>
        <clipPath id="clip0_1458_19654">
          <path fill="#fff" d="M0 0H656V90H0z"></path>
        </clipPath>
      </defs>
    </svg>
  )
}
