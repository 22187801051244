import Content from '~/components/elements/Content'
import HubspotForm from 'react-hubspot-form'
import type { Page_Flexcontent_Flex_Form } from '~/graphql/types'
import { useLosseLanguage } from '@ubo/losse-sjedel'
import BrandingLeft from '~/components/elements/svg/BrandingLeft'

export default function FormWebinar({ fields }: { fields: Page_Flexcontent_Flex_Form }) {
  const [, locale] = useLosseLanguage()

  return (
    <section
      data-component="FormWebinar"
      data-scrollindicatortitle={fields.scrollIndicatorTitle ?? undefined}
      id={fields.sectionId ?? undefined}
      className="bg-ubo-whiscash pt-16 sm:pt-20"
    >
      <div className="container">
        <div className="grid gap-10 lg:grid-cols-2 lg:gap-20">
          <div className="col-span-1">
            <Content className="content content-stroke-light max-w-2xl text-white children-headings:mb-4 children-headings:text-5xl children-em:text-4xl sm:children-headings:text-7xl sm:children-em:text-6xl lg:children-em:text-7xl lg:children-p:text-xl">
              {fields?.description}
            </Content>
          </div>

          <div className="col-span-1">
            <HubspotForm
              portalId="25411680"
              formId={`${fields?.formidHubspot ? fields.formidHubspot : '77785cfb-2ff6-459b-8cf5-431926a87840'}`}
              loading={<div>{locale === 'nl_NL' ? 'Aan het laden...' : 'Loading ...'}</div>}
              // Apparantly the react-hubspot-form required JQuery to work? WTF
              // https://github.com/escaladesports/react-hubspot-form/issues/22
              // https://stackoverflow.com/questions/65919777/how-to-force-the-onformsubmit-function-in-hbspt-forms-create-requires-jquery-i
              onReady={() => {
                const script = document.createElement('script')
                script.src = 'https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js'
                let jqueryScript = document.getElementsByTagName('script')
                let src = Array.from(jqueryScript).filter((item) => item.src === script.src)
                if (src.length === 0) {
                  document.body.appendChild(script)
                }
              }}
              onSubmit={() => {}}
            />
          </div>
        </div>
      </div>
      <BrandingLeft className="relative bottom-0 left-0 z-0 mt-6" />
    </section>
  )
}
