import { useSearchParams } from '@ubo/losse-sjedel'
import { useState } from 'react'
import Arrow from './svg/Arrow'

export default function PostsSearch() {
  const [searchParams, setSearchParams] = useSearchParams()
  const [search, setSearch] = useState(searchParams.get('_q') || '')

  return (
    <div className="flex w-full items-center border-b-2 border-ubo-delibird">
      <input
        type="text"
        name="_q"
        placeholder="Zoeken"
        onChange={(e) => setSearch(e.target.value)}
        value={search}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault()
            searchParams.set('_q', search)
            setSearchParams(searchParams, {
              replace: true,
              state: {
                scroll: false
              }
            })
          }
        }}
        className="w-full bg-transparent px-4 py-1 text-xl text-white placeholder:text-white/90"
      />
      <div
        role="button"
        onClick={(e) => {
          e.preventDefault()
          searchParams.set('_q', search)
          setSearchParams(searchParams, {
            replace: true,
            state: {
              scroll: false
            }
          })
        }}
      >
        <Arrow className="h-6 w-6" />
      </div>
    </div>
  )
}
