import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'

// Components
import PostsEmployees from './PostsEmployees'
import PostsBlog from './PostsBlog'
import PostsCases from './PostsCases'
import PostsKnowledgeBase from './PostsKnowledgeBase'
import PostsKnowledgeBaseAlt from './PostsKnowledgeBaseAlt'

export default function PostsShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    blog: PostsBlog,
    employees: PostsEmployees,
    cases: PostsCases,
    knowledge_base: PostsKnowledgeBase,
    knowledge_base_alt: PostsKnowledgeBaseAlt
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
