// Types
import { useInView } from 'framer-motion'

// Hooks
import { useEffect, useRef } from 'react'
import useLayout from '~/hooks/useLayout'

export default function ScrollEffectStart() {
  const { setScrollEffect, scrollEffect, smallWindow } = useLayout()

  const effectStartRef = useRef(null)

  const inView = useInView(effectStartRef)

  useEffect(() => {
    if (!scrollEffect && inView && !smallWindow) {
      setScrollEffect(true)
    }
  }, [inView, scrollEffect, setScrollEffect, smallWindow])

  return <section key="scroll_effect_start" data-component="ScrollEffectStart" ref={effectStartRef} />
}
