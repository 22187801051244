// Components
import { LossePlaatjie } from '@ubo/losse-sjedel'
import { useInView } from 'framer-motion'
import { useRef } from 'react'
import { motion } from 'framer-motion'
import Content from '~/components/elements/Content'

// Types
import type { Page_Flexcontent_Flex_Text } from '~/graphql/types'

export default function TextIcon({ fields }: { fields: Page_Flexcontent_Flex_Text }) {
  const sectionRef = useRef<HTMLElement>(null)

  const inView = useInView(sectionRef)

  return (
    <section
      data-component="TextIcon"
      data-scrollindicatortitle={fields.scrollIndicatorTitle ?? undefined}
      id={fields.sectionId ?? undefined}
      ref={sectionRef}
      className="overflow-hidden bg-ubo-taillow"
    >
      <div className="container pb-10 pt-10 sm:pb-0 sm:pt-20">
        <div className="grid grid-cols-2">
          <div className="col-span-2 sm:col-span-1">
            <motion.div
              animate={inView ? { x: 0 } : { x: '-100%' }}
              transition={{
                duration: 1.25
              }}
            >
              <LossePlaatjie src={fields.image} className="mx-auto mb-7 max-w-[12rem] sm:-ml-7 sm:mb-0 sm:max-w-[470px]" />
            </motion.div>
          </div>
          <motion.div
            className="col-span-2 sm:col-span-1"
            animate={inView ? { opacity: 1, x: 0 } : { opacity: 0, x: '100%' }}
            transition={{ duration: 1.25 }}
          >
            <Content className="text-ubo-delibird">{fields?.description}</Content>
          </motion.div>
        </div>
      </div>
    </section>
  )
}
