/* eslint-disable @typescript-eslint/no-non-null-assertion */
// @ubo
import { LosseLink, LossePlaatjie, useLosseLanguage } from '@ubo/losse-sjedel'

// Hooks
import type { MouseEvent } from 'react'
import { useRef, useState } from 'react'

// Types
import type { Page_Flexcontent_Flex_Featured } from '~/graphql/types'

import Content from '~/components/elements/Content'
import { AnimatePresence, motion, useMotionValue, useSpring } from 'framer-motion'
import clsx from 'clsx'

import OldFeaturedCases from './_FeaturedCases'

export default function FeaturedCases({ fields }: { fields: Page_Flexcontent_Flex_Featured }) {
  const [, locale] = useLosseLanguage()

  const sectionRef = useRef<HTMLDivElement>(null)
  const videoRef = useRef<HTMLDivElement>(null)
  const buttonRef = useRef<HTMLDivElement>(null)

  const [hoverCaseVideo, setHoverCaseVideo] = useState(false)
  const [clickedIndexes, setClickedIndexes] = useState<number[]>([])
  const [currentIndex, setCurrentIndex] = useState(0)

  const handleButtonClick = async (index: number) => {
    const indexesToAdd = []
    for (let i = 0; i <= index; i++) {
      indexesToAdd.push(i - 1)
    }
    setClickedIndexes(indexesToAdd)
    setCurrentIndex(index)
  }

  const cursorX = useMotionValue(0)
  const cursorY = useMotionValue(0)

  const springConfig = { damping: 35, stiffness: 700 }

  const cursorXSpring = useSpring(cursorX, springConfig)
  const cursorYSpring = useSpring(cursorY, springConfig)

  function handleCursor(event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) {
    if (videoRef.current) {
      const { left, top } = videoRef.current.getBoundingClientRect()

      if (!buttonRef.current) return

      if (hoverCaseVideo) {
        cursorX.set(event.clientX - left + 375)
        cursorY.set(event.clientY - top + 130)
      }
    }
  }

  const currentCase = fields.cases![currentIndex]

  let loadingPercentage = clickedIndexes.length * 20 - 20

  if (currentIndex < 1) {
    loadingPercentage = 0
  }

  return (
    <section
      data-component="FeaturedCases"
      data-scrollindicatortitle={fields.scrollIndicatorTitle ?? undefined}
      id={fields.sectionId ?? undefined}
    >
      <OldFeaturedCases fields={fields} />
      <div
        ref={sectionRef}
        className="presentation-section relative hidden overflow-hidden lg:block lg:bg-ubo-whiscash lg:text-ubo-delibird"
      >
        <motion.div
          ref={buttonRef}
          style={{
            translateX: cursorXSpring,
            translateY: cursorYSpring
          }}
          initial={{ opacity: 0 }}
          animate={hoverCaseVideo ? { opacity: 1 } : { opacity: 0 }}
          transition={{ duration: 0.5, bounce: 0 }}
          className="pointer-events-none absolute z-[99]"
        >
          <span className="btn btn--light--filled">{locale === 'nl_NL' ? 'Lees deze case' : 'Read this case'}</span>
        </motion.div>
        <div className="absolute -right-24 -top-24">
          <svg width="1198" height="508" viewBox="0 0 1198 508" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_3003_7676" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="1198" height="508">
              <rect x="1198" y="508" width="1198" height="508" transform="rotate(180 1198 508)" fill="#070744" />
            </mask>
            <g mask="url(#mask0_3003_7676)">
              <path d="M1565.48 -45.3221L1198.15 321.986" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M1549.25 -61.5497L1181.92 305.759" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M1533.09 -77.7101L1165.76 289.599" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M1516.93 -93.8701L1149.6 273.438" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M1373.73 -13.0027L1006.4 354.306" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M1357.55 -29.163L990.244 338.146" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M1341.39 -45.3221L974.084 321.986" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M1325.19 -61.5496L957.856 305.759" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M1309.01 -77.7099L941.697 289.599" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M1292.85 -93.87L925.537 273.439" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M1168 354.171L1087.11 435.038" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M1151.82 337.989L1070.95 418.857" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M1135.66 321.829L1054.79 402.696" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M1119.43 305.602L1038.57 386.491" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M1103.27 289.442L1022.41 370.331" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M1232.78 418.945L1151.91 499.835" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M1216.57 402.74L1135.68 483.608" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M1200.46 386.626L1119.59 467.493" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M1184.25 370.399L1103.36 451.288" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M1265.19 451.355L1184.32 532.244" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M1249.03 435.195L1168.16 516.062" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M1149.67 -13.0027L782.364 354.306" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M1133.51 -29.163L766.204 338.146" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M1117.35 -45.3221L750.022 321.986" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M1101.13 -61.5497L733.817 305.759" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M1084.97 -77.7101L717.657 289.599" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M1068.81 -93.8701L701.497 273.438" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M943.94 354.171L863.073 435.038" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M927.782 337.989L846.915 418.857" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M911.622 321.829L830.755 402.696" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M895.394 305.602L814.526 386.491" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M879.233 289.442L798.366 370.331" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M1008.74 418.946L927.87 499.835" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M992.511 402.74L911.644 483.608" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M976.419 386.626L895.552 467.493" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M960.19 370.399L879.323 451.288" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M1089.69 499.903L1008.83 580.792" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M1073.53 483.742L992.667 564.609" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M1057.38 467.582L976.509 548.449" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M1041.15 451.355L960.28 532.244" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M1024.99 435.195L944.12 516.062" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M925.633 -13.0027L558.302 354.306" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M909.473 -29.163L542.142 338.146" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M893.313 -45.3221L525.982 321.986" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M877.086 -61.5497L509.755 305.759" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M860.926 -77.7101L493.595 289.599" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M844.766 -93.87L477.435 273.439" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M719.903 354.171L639.036 435.038" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M703.743 337.989L622.853 418.857" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M687.583 321.829L606.693 402.696" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M671.354 305.602L590.487 386.491" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M655.196 289.442L574.306 370.331" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M784.698 418.946L703.808 499.835" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M768.471 402.74L687.604 483.608" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M752.356 386.626L671.489 467.493" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M736.151 370.399L655.284 451.288" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M865.655 499.903L784.788 580.792" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M849.473 483.742L768.606 564.609" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M833.313 467.582L752.446 548.449" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M817.108 451.355L736.218 532.244" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M800.948 435.195L720.058 516.062" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M701.571 -13.0027L334.262 354.306" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M685.411 -29.1628L318.102 338.146" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M669.251 -45.3221L301.942 321.986" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M653.024 -61.5497L285.716 305.759" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M636.864 -77.7099L269.555 289.599" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M620.704 -93.87L253.395 273.439" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M495.839 354.171L414.972 435.038" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M479.68 337.989L398.813 418.857" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M463.52 321.829L382.653 402.696" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M447.292 305.602L366.425 386.491" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M431.132 289.442L350.264 370.331" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M560.635 418.946L479.768 499.835" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M544.409 402.74L463.542 483.608" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M528.317 386.626L447.45 467.493" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M512.089 370.399L431.222 451.288" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M641.593 499.903L560.725 580.792" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M625.432 483.742L544.565 564.609" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M609.272 467.582L528.405 548.449" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M593.046 451.355L512.179 532.244" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M576.885 435.195L496.018 516.062" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M477.531 -13.0027L110.2 354.306" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M461.371 -29.163L94.0401 338.146" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M445.211 -45.3221L77.8799 321.986" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M428.984 -61.5497L61.6533 305.759" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M412.824 -77.7101L45.4932 289.599" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M396.664 -93.8701L29.3331 273.438" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M271.801 354.171L190.934 435.038" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M255.641 337.989L174.751 418.857" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M239.48 321.829L158.591 402.696" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M223.252 305.602L142.385 386.491" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M207.092 289.442L126.225 370.331" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M336.598 418.946L255.708 499.835" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M320.369 402.74L239.502 483.608" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M304.254 386.626L223.387 467.493" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M288.049 370.399L207.182 451.288" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M417.553 499.903L336.686 580.792" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M401.395 483.742L320.505 564.609" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M385.211 467.582L304.344 548.449" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M369.006 451.355L288.116 532.244" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
              <path d="M352.846 435.195L271.956 516.062" stroke="#070744" strokeWidth="2.24133" strokeMiterlimit="10" />
            </g>
          </svg>
        </div>
        <div className="container relative py-12">
          <Content className="content-3xl relative z-20">{fields.title}</Content>
          <div className="-mt-6 grid grid-cols-12 gap-x-14 pl-32">
            <div className="col-span-2">
              <div className="relative mt-20 flex flex-col items-center">
                <div className="absolute h-full w-1 bg-ubo-muk"></div>
                <motion.div
                  animate={{ height: `${loadingPercentage}%` }}
                  className="loading-indicator absolute h-full w-1 bg-white"
                ></motion.div>
                <div
                  className="absolute bottom-0 z-10 w-1 bg-ubo-whiscash"
                  style={{ height: `${100 / Number(fields.cases?.length)}%` }}
                ></div>
                {fields.cases?.map((edge, index) => {
                  const isActive = currentIndex === index
                  const shouldShowLoadedIndicator = clickedIndexes.includes(index - 1)

                  return (
                    <div key={edge?.title} className="relative flex flex-col items-center justify-center overflow-hidden">
                      <button
                        onClick={() => {
                          handleButtonClick(index)
                        }}
                        className={clsx(
                          shouldShowLoadedIndicator || index === 0 || isActive
                            ? 'border-transparent bg-white text-ubo-whiscash'
                            : 'border-ubo-muk bg-transparent bg-ubo-whiscash',
                          'relative z-20 mb-20 block w-fit border-2 p-[2px]'
                        )}
                      >
                        <span className="inline-block w-full text-center">{edge?.title}</span>
                      </button>
                    </div>
                  )
                })}
              </div>
            </div>
            <div className="col-span-10">
              <LosseLink to={currentCase?.uri}>
                <AnimatePresence key={currentCase?.recap?.video} mode="wait">
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    key={currentCase?.recap?.video}
                    className="min-h-[220px] w-full lg:min-h-[640px]"
                    onMouseEnter={() => setHoverCaseVideo(true)}
                    onMouseLeave={() => setHoverCaseVideo(false)}
                    onMouseMove={(event) => handleCursor(event)}
                    ref={videoRef}
                  >
                    {currentCase?.recap?.video && (
                      <motion.div
                        key={currentCase.recap.video}
                        initial={{ x: '100%', opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        exit={{ x: '100%', opacity: 0 }}
                      >
                        <video muted playsInline autoPlay loop className="h-auto overflow-hidden object-cover">
                          <source src={currentCase?.recap?.video || ''} type="video/mp4" />
                        </video>
                      </motion.div>
                    )}

                    {!currentCase?.recap?.video && (
                      <div>
                        <LossePlaatjie
                          src={currentCase?.recap?.featuredImage}
                          className="h-full w-full object-cover"
                          maxwidth={1999}
                          loading="eager"
                        />
                      </div>
                    )}
                  </motion.div>
                </AnimatePresence>
              </LosseLink>
            </div>
          </div>
          <div className="ml-5 mt-12 flex flex-col">
            <h2 className="mb-6 font-september text-7xl font-black">{currentCase?.title}</h2>
            <div className="grid grid-cols-12 gap-x-10">
              <div className="col-span-9 flex items-center">
                <Content>{currentCase?.recap?.description}</Content>
              </div>
              <div className="col-span-3 flex items-center">
                <LosseLink to={currentCase?.uri} className="btn btn--light">
                  {locale === 'nl_NL' ? 'Lees deze case' : 'Read this case'}
                </LosseLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
