// @ubo
import { LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'

// Third party
import { motion, useInView } from 'framer-motion'
import { useRef } from 'react'

// Components
import Content from '~/components/elements/Content'

// Types
import type { Page_Flexcontent_Flex_Hero } from '~/graphql/types'

export default function HeroContent({ fields }: { fields: Page_Flexcontent_Flex_Hero }) {
  const sectionRef = useRef<HTMLElement>(null)
  const inView = useInView(sectionRef, { once: true })

  return (
    <section
      data-component="HeroContent"
      data-scrollindicatortitle={fields.scrollIndicatorTitle ?? undefined}
      id={fields.sectionId ?? undefined}
      ref={sectionRef}
      className="overflow-hidden"
    >
      <LossePlaatjie
        maxwidth={1999}
        src={fields.image}
        className="mx-auto aspect-square max-h-48 object-cover md:max-h-96 lg:aspect-auto lg:max-h-[51rem]"
      />
      <div className="bg-ubo-taillow pb-8 text-ubo-delibird lg:pb-24">
        <div className="container">
          <div
            className={clsx(fields.appearance?.contentHasQuote ? 'justify-between' : 'justify-end', 'relative flex flex-col lg:flex-row')}
          >
            <motion.div
              animate={inView ? { scale: [0.99, 1, 0.99] } : { scale: [1, 1, 1] }}
              transition={{
                repeat: Infinity,
                repeatType: 'mirror'
              }}
              className="absolute -top-16 left-0 sm:-top-32 lg:-top-96"
            >
              <LossePlaatjie
                src={fields.icon}
                className="h-32 w-32 sm:h-64 sm:w-64 lg:h-[650px] lg:w-[300px] 2xl:h-[650px] 2xl:w-[650px]"
              />
            </motion.div>
            {fields.appearance?.contentHasQuote && fields.quote?.description && (
              <motion.div
                animate={inView ? { x: 0 } : { x: '100%' }}
                transition={{
                  duration: 1.25
                }}
                className="relative order-last mt-8 bg-ubo-whiscash p-4 text-ubo-delibird sm:p-7 lg:order-first lg:-mb-28 lg:-mr-20 lg:mt-80 lg:w-2/5 lg:p-12"
              >
                <Content className="children-p:font-bold children-ul:text-2xl lg:children-p:text-2xl">{fields.quote?.description}</Content>
              </motion.div>
            )}
            <div className={clsx(fields.appearance?.contentHasQuote ? 'lg:w-3/5' : 'lg:w-3/4')}>
              <motion.div
                animate={inView ? { x: 0 } : { x: '120%' }}
                transition={{
                  duration: 1.25
                }}
              >
                <Content className="children-h2:text-stroke relative children-h2:my-12 children-h2:text-4xl children-h3:mb-4 children-h3:text-3xl children-h3:leading-tight lg:children-h2:text-7xl lg:children-h3:pl-28 lg:children-p:pl-28 xl:children-h2:text-8xl 2xl:children-h2:text-10xl">
                  {fields?.description}
                </Content>
              </motion.div>
            </div>
          </div>

          {fields.appearance?.contentHasVideo && (
            <div className="relative grid grid-cols-2 lg:px-24">
              <div className="col-span-2 lg:col-span-1">
                <video key={fields.heroVideo} muted controls playsInline>
                  <source src={fields.heroVideo || ''} type="video/mp4" />
                </video>
              </div>
              <div className="col-span-2 self-center lg:col-span-1">
                <Content className="px-4 py-5 md:px-12">{fields.videoDescription}</Content>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  )
}
