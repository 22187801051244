// Types
import { useInView } from 'framer-motion'
import { useEffect, useRef } from 'react'
import useLayout from '~/hooks/useLayout'

export default function ScrollEffectEnd() {
  const { scrollEffect, setScrollEffect, smallWindow } = useLayout()
  const effectEndRef = useRef(null)
  const inView = useInView(effectEndRef)

  useEffect(() => {
    if (scrollEffect && inView) {
      setScrollEffect(false)
    } else if (!inView && !scrollEffect && !smallWindow) {
      setTimeout(() => {
        document.documentElement?.classList.add('snap-y', 'snap-proximity')
        setScrollEffect(true)
      }, 1)
    }
  }, [inView, scrollEffect, setScrollEffect, smallWindow])

  return <section key="scroll-effect-end" data-component="ScrollEffectEnd" ref={effectEndRef} />
}
