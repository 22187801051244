// @ubo
import { LossePlaatjie } from '@ubo/losse-sjedel'

// Third party
import { motion, useInView, useScroll, useTransform } from 'framer-motion'
import { useRef, useState } from 'react'

// Components
import Content from '~/components/elements/Content'
import Identity from '~/components/elements/svg/Identity'

// Types
import type { Page_Flexcontent_Flex_Text } from '~/graphql/types'

export default function TextImage({ fields }: { fields: Page_Flexcontent_Flex_Text }) {
  const [pulsingImage, setPulsingImage] = useState(true)
  const sectionRef = useRef<HTMLElement>(null)
  const contentRef = useRef<HTMLDivElement>(null)
  const identityRef = useRef(null)

  const contentInView = useInView(contentRef, { once: true })

  const { scrollYProgress } = useScroll({
    target: identityRef
  })

  const transform = useTransform(scrollYProgress, [0, 1], [-10, -50])
  const xPercentage = useTransform(transform, (value) => `${value}%`)

  return (
    <section
      data-component="TextImage"
      data-scrollindicatortitle={fields.scrollIndicatorTitle ?? undefined}
      id={fields.sectionId ?? undefined}
      className="relative overflow-hidden pb-20 lg:pb-48"
      ref={sectionRef}
    >
      <div className="absolute inset-0 top-[5%] h-[95%] bg-ubo-delibird" />
      <div className="absolute -bottom-6 overflow-hidden lg:-bottom-20" ref={identityRef}>
        <div className="relative h-full w-full overflow-hidden">
          <motion.div key="moving_identity" style={{ translateX: xPercentage }} className="w-full">
            <Identity type="dark" className="h-full w-full" />
          </motion.div>
        </div>
      </div>
      <div className="container relative">
        <div className="grid grid-cols-2 items-start gap-x-24">
          <div className="col-span-2 flex lg:col-span-1">
            <motion.div
              onClick={() => setPulsingImage(!pulsingImage)}
              animate={pulsingImage && contentInView ? { scale: [1.01, 1, 1.01] } : { scale: [1, 1, 1] }}
              transition={{
                repeat: 5,
                repeatType: 'mirror'
              }}
              className="flex-1 cursor-pointer transition-opacity hover:opacity-90"
            >
              <LossePlaatjie
                src={fields.image}
                maxwidth={900}
                className="mx-auto h-full w-full max-w-[275px] object-cover lg:max-w-[650px]"
              />
            </motion.div>
          </div>
          <div className="col-span-2 lg:col-span-1" ref={contentRef}>
            <motion.div initial={{ x: '110%' }} animate={contentInView ? { x: 0 } : { x: '110%' }}>
              <Content className="content-xxl content-stroke py-8 children-h2:mb-3 children-h3:mb-2 children-h3:text-2xl children-h3:leading-none lg:pb-28 lg:pt-32">
                {fields?.description}
              </Content>
            </motion.div>
          </div>
        </div>
      </div>
    </section>
  )
}
