// Components
import Content from '~/components/elements/Content'

// Types
import type { Page_Flexcontent_Flex_Text } from '~/graphql/types'

export default function TextFeaturing({ fields }: { fields: Page_Flexcontent_Flex_Text }) {
  return (
    <section
      data-component="TextFeaturing"
      data-scrollindicatortitle={fields.scrollIndicatorTitle ?? undefined}
      id={fields.sectionId ?? undefined}
      className="bg-ubo-delibird py-7 md:py-20"
    >
      <div className="container py-5">
        <div className="grid grid-cols-2 gap-x-12 2xl:px-44">
          <div className="col-span-2 mb-6 md:col-span-1 md:mb-0">
            <Content className="content-md children-headings:mb-5">{fields?.description}</Content>
          </div>
          <div className="col-span-2 md:col-span-1">
            <Content className="content-md children-headings:mb-3 md:children-headings:mb-5">{fields?.descriptionTwo}</Content>
          </div>
        </div>
      </div>
    </section>
  )
}
