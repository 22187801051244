export default function Stroke({
  className = '',
  direction = 'vertical',
  type = 'light'
}: {
  className?: string
  direction?: 'vertical' | 'horizontal'
  type?: 'light' | 'dark'
}) {
  return (
    <>
      {direction === 'vertical' ? (
        <svg xmlns="http://www.w3.org/2000/svg" width="4000" height="1117" fill="none" className={className}>
          <path fill={type === 'light' ? '#070744' : '#000CED'} d="M0 0H1728V1117H0z"></path>
          <mask id="mask0_317_7669" style={{ maskType: 'alpha' }} width="608" height="1117" x="0" y="0" maskUnits="userSpaceOnUse">
            <path fill={type === 'light' ? '#070744' : '#000CED'} d="M0 0H608V1117H0z"></path>
          </mask>
          <g fill={type === 'light' ? '#000CED' : '#070744'} fillRule="evenodd" clipRule="evenodd" mask="url(#mask0_317_7669)">
            <path d="M14.792-151.793L-1330.79 1193.79l-2.12-2.12L12.671-153.914l2.121 2.121zM35.157-81.158L-1169.16 1123.16l-2.12-2.12L33.036-83.28l2.121 2.122zM66.582-21.582L-1018.58 1063.58l-2.12-2.12L64.46-23.703l2.122 2.121zM207.595-71.595L-977.595 1113.59l-2.121-2.12L205.473-73.716l2.122 2.121zM246.057-19.057L-834.057 1061.06l-2.122-2.12L243.935-21.179l2.122 2.122zM392.097-74.097L-798.097 1116.1l-2.121-2.12L389.975-76.218l2.122 2.121zM445.64-36.64L-669.64 1078.64l-2.121-2.12L443.519-38.761l2.121 2.12zM551.464-51.464L-593.464 1093.46l-2.121-2.12L549.343-53.585l2.121 2.121zM617.072-26.072L-477.072 1068.07l-2.121-2.12L614.951-28.193l2.121 2.121zM699.772-17.771L-377.771 1059.77l-2.122-2.12L697.65-19.892l2.122 2.12zM820.173-47.174L-316.174 1089.17l-2.121-2.12L818.052-49.295l2.121 2.121zM925.997-61.997L-239.997 1104l-2.122-2.12L923.876-64.119l2.121 2.122zM945.86 9.14L-77.86 1032.86l-2.121-2.12L943.738 7.019l2.122 2.121zM1076.32-30.316L-26.316 1072.32l-2.121-2.13L1074.19-32.437l2.13 2.121zM1278.46-141.456L-46.456 1183.46l-2.121-2.13L1276.33-143.577l2.13 2.121zM1349.6-121.605L64.395 1163.6l-2.121-2.12L1347.48-123.726l2.12 2.121zM1412.21-93.208L183.792 1135.21l-2.122-2.12L1410.09-95.33l2.12 2.122zM1486.88-76.876L291.124 1118.88l-2.122-2.13L1484.75-78.998l2.13 2.122zM1614.33-113.328L345.672 1155.33l-2.121-2.12L1612.21-115.449l2.12 2.121zM1796.78-113.328L528.121 1155.33l-2.121-2.12L1794.66-115.449l2.12 2.121zM1711.62-119.617L430.383 1161.62l-2.122-2.12L1709.5-121.738l2.12 2.121z"></path>
            <path d="M1771.71-88.707L552.293 1130.71l-2.121-2.12L1769.59-90.828l2.12 2.121z"></path>
          </g>
        </svg>
      ) : (
        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="351" fill="none" viewBox="0 0 1440 351" className={className}>
          <mask id="mask0_1458_20293" style={{ maskType: 'alpha' }} width="1728" height="351" x="0" y="0" maskUnits="userSpaceOnUse">
            <path fill={type === 'light' ? '#070744' : '#000CED'} d="M0 0H1728V351H0z"></path>
          </mask>
          <g fill={type === 'light' ? '#000CED' : '#070744'} fillRule="evenodd" clipRule="evenodd" mask="url(#mask0_1458_20293)">
            <path d="M317.68-105.679L-695.679 907.68l-2.122-2.121L315.558-107.801l2.122 2.122zM428.53-125.53L-624.531 927.53l-2.121-2.121L426.409-127.652l2.121 2.122zM552.954-158.954L-566.954 960.954l-2.121-2.121L550.833-161.075l2.121 2.121zM630.124-145.124L-462.124 947.124l-2.122-2.121L628.003-147.245l2.121 2.121zM777.169-201.169L-427.169 1003.17l-2.122-2.12L775.048-203.291l2.121 2.122zM938.792-271.793L-406.793 1073.79l-2.121-2.12L936.671-273.914l2.121 2.121zM959.158-201.158L-245.157 1003.16l-2.122-2.12L957.037-203.279l2.121 2.121zM990.582-141.582L-94.582 943.582l-2.122-2.121L988.461-143.704l2.121 2.122zM1131.59-191.595L-53.595 993.595l-2.121-2.121L1129.47-193.716l2.12 2.121zM1170.06-139.057L-32 1063l-2.121-2.12L1167.94-141.178l2.12 2.121zM1316.1-194.097L-23.5 1145.5l-2.121-2.12L1313.98-196.218l2.12 2.121zM1369.64-156.64L40 1173l-2.121-2.12L1367.52-158.762l2.12 2.122zM1475.46-171.464L107.879 1196.12l-2.121-2.12L1473.34-173.585l2.12 2.121zM1541.07-146.072L196.5 1198.5l-2.121-2.12L1538.95-148.193l2.12 2.121zM1623.77-137.771L337.5 1148.5l-2.121-2.12L1621.65-139.893l2.12 2.122zM1744.17-167.174L390 1187l-2.122-2.12L1742.05-169.295l2.12 2.121zM1850-181.997L481.5 1186.5l-2.122-2.12L1847.88-184.119l2.12 2.122zM1869.86-110.86L587 1172l-2.122-2.12L1867.74-112.981l2.12 2.121zM2000.32-150.316L703.5 1146.5l-2.121-2.12L1998.19-152.437l2.13 2.121zM2202.46-261.456L769 1172l-2.121-2.12L2200.33-263.577l2.13 2.121zM2273.61-241.605L819 1213l-2.121-2.12L2271.48-243.726l2.13 2.121zM2336.21-213.208L1107.79 1015.21l-2.12-2.12L2334.09-215.329l2.12 2.121zM2410.88-196.876L1215.12 998.876l-2.12-2.121L2408.75-198.998l2.13 2.122zM2538.33-233.328L1269.67 1035.33l-2.12-2.12L2536.21-235.449l2.12 2.121zM2635.62-239.617L1354.38 1041.62l-2.12-2.12L2633.5-241.739l2.12 2.122zM2695.71-208.707L1476.29 1010.71l-2.12-2.12L2693.59-210.828l2.12 2.121zM220.885-92.886l-987.771 987.771-2.121-2.121L218.764-95.007l2.121 2.121zM136.892-92.886l-987.771 987.771-2.121-2.121L134.771-95.007l2.121 2.121z"></path>
          </g>
        </svg>
      )}
    </>
  )
}
