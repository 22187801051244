import type { Page_Flexcontent_Flex_Webinars } from '~/graphql/types'
import { Mousewheel, Navigation } from 'swiper/modules'
import Content from '~/components/elements/Content'
import EndlessContainer from '~/components/elements/EndlessContainer'
import Slider, { Slide } from '~/components/elements/Slider'
import WebinarX from '~/components/elements/svg/WebinarX'
import { useState } from 'react'
import WebinarModal from '~/components/elements/WebinarModal'
import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import Branding from '~/components/elements/svg/Branding'
import Stroke from '~/components/elements/svg/Stroke'

export default function WebinarsSlider({ fields }: { fields: Page_Flexcontent_Flex_Webinars }) {
  const [modalStates, setModalStates] = useState(Array(fields?.item && fields?.item.length).fill(false))

  const handleModalToggle = (index: any) => {
    const newModalStates = [...modalStates]
    newModalStates[index] = !newModalStates[index]
    setModalStates(newModalStates)
  }

  const firstWebinar = fields?.item.find((item: any) => {
    const date = new Date(item?.sentence[0]?.description)
    const now = new Date()
    return date > now
  })

  return (
    <>
      <section data-component="WebinarsSlider" className="relative overflow-hidden bg-ubo-whiscash text-ubo-delibird lg:min-h-[950px]">
        <Stroke direction="horizontal" type="dark" className="absolute top-0 block h-1/5 w-full lg:hidden" />
        <WebinarX className="absolute left-0 top-0 hidden h-full w-auto lg:block" />
        <div className="presentation-container relative z-10 py-10 lg:py-20">
          <Content className="content-xl content-stroke-light mb-10 children:uppercase">{fields?.title}</Content>
          <div className="container mb-9 flex items-center justify-start lg:ml-32">
            <button type="button" className="custom-swiper-button-prev mr-7">
              <svg width="15" height="27" viewBox="0 0 15 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M11.9385 26.4629L0.525313 14.7961C-0.175056 14.0802 -0.175056 12.9195 0.525314 12.2035L11.9385 0.536783C12.6389 -0.179148 13.7744 -0.179148 14.4748 0.536783C15.1751 1.25271 15.1751 2.41346 14.4748 3.12939L6.12312 11.6666C4.87385 13.1192 4.88507 13.9256 6.12312 15.3331L14.4748 23.8703C15.1751 24.5862 15.1751 25.747 14.4748 26.4629C13.7744 27.1788 12.6389 27.1788 11.9385 26.4629Z"
                  fill="white"
                />
              </svg>
            </button>
            <button type="button" className="custom-swiper-button-next">
              <svg width="15" height="27" viewBox="0 0 15 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M3.0615 0.537114L14.4747 12.2039C15.1751 12.9198 15.1751 14.0805 14.4747 14.7965L3.0615 26.4632C2.36113 27.1791 1.22561 27.1791 0.525238 26.4632C-0.175131 25.7473 -0.175131 24.5865 0.525238 23.8706L8.87688 15.3334C10.1261 13.8808 10.1149 13.0744 8.87688 11.6669L0.52524 3.12972C-0.175129 2.4138 -0.175129 1.25304 0.52524 0.537114C1.22561 -0.178815 2.36113 -0.178815 3.0615 0.537114Z"
                  fill="white"
                />
              </svg>
            </button>
          </div>
          <EndlessContainer className="xl:ml-28">
            <Slider
              breakpoints={{
                0: {
                  slidesPerView: 1,
                  spaceBetween: 20
                },
                768: {
                  slidesPerView: 1.5,
                  spaceBetween: 40
                },
                1024: {
                  slidesPerView: 2.5,
                  spaceBetween: 40
                },
                1280: {
                  slidesPerView: 3.5,
                  spaceBetween: 40
                }
              }}
              modules={[Navigation, Mousewheel]}
              navigation={{
                prevEl: '.custom-swiper-button-prev',
                nextEl: '.custom-swiper-button-next'
              }}
              mousewheel={{
                forceToAxis: true
              }}
              className="mb-8 lg:-mt-2 lg:mb-24"
            >
              {fields?.item?.map((item: any, index: number) => {
                const day = item?.sentence[0]?.description?.split(' ')[0]
                const month = item?.sentence[0]?.description?.split(' ')[1]
                const year = item?.sentence[0]?.description?.split(' ')[2]

                return (
                  <Slide key={item?.title} className="group relative !h-auto bg-white">
                    <div className="flex items-center bg-ubo-delibird px-5 py-8">
                      <p className="flex min-h-[65px] min-w-[65px] items-center justify-center rounded-[5px] bg-ubo-taillow px-3 font-september text-5xl font-extrabold text-ubo-delibird">
                        {day}
                      </p>
                      <p className="ml-4 uppercase text-ubo-taillow">
                        <span>
                          {month} {year}
                        </span>
                        <br />
                        <span>{item?.sentence[1]?.description}</span>
                      </p>
                    </div>
                    <div className="relative -right-[12rem] -top-10 xl:-right-[16.5rem]">
                      {index % 2 === 0 ? (
                        <svg width="119" height="119" viewBox="0 0 119 119" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clipPath="url(#clip0_302_343)">
                            <path
                              d="M29.333 118.58V0.420394H44.2217V118.58H29.333Z"
                              fill="#000CED"
                              stroke="#000CED"
                              strokeWidth="0.840787"
                            />
                            <path
                              d="M0.420394 44.2956V29.407H118.58V44.2956H0.420394Z"
                              fill="#000CED"
                              stroke="#000CED"
                              strokeWidth="0.840787"
                            />
                            <path
                              d="M74.7784 118.58V0.420394H89.667V118.58H74.7784Z"
                              fill="#000CED"
                              stroke="#000CED"
                              strokeWidth="0.840787"
                            />
                            <path
                              d="M0.420394 89.593V74.7043H118.58V89.593H0.420394Z"
                              fill="#000CED"
                              stroke="#000CED"
                              strokeWidth="0.840787"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_302_343">
                              <rect width="119" height="119" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      ) : (
                        <svg width="115" height="119" viewBox="0 0 115 125" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M75.4169 49.7749C78.6174 51.1284 82.0112 51.8138 85.5053 51.8138C88.9995 51.8138 92.3951 51.1284 95.5957 49.7749C98.6823 48.4696 101.452 46.6025 103.83 44.2257C106.209 41.8488 108.077 39.08 109.381 35.9926C110.735 32.7932 111.421 29.4008 111.421 25.9079C111.421 22.415 110.735 19.0187 109.381 15.8212C108.077 12.7358 106.209 9.96502 103.828 7.58623C101.45 5.21131 98.6803 3.34613 95.5937 2.03896C92.3912 0.685445 88.9975 0 85.5053 0C82.0131 0 78.6174 0.687376 75.4149 2.03896C72.3283 3.3442 69.5604 5.20938 67.1808 7.58623C64.8011 9.96309 62.9333 12.7319 61.6295 15.8193C60.2755 19.0187 59.5898 22.4131 59.5898 25.906C59.5898 29.3988 60.2775 32.7913 61.6295 35.9907C62.9333 39.0761 64.8011 41.8469 67.1789 44.2218C69.5527 46.5987 72.3245 48.4658 75.4169 49.7729V49.7749ZM79.3089 19.7118C80.9643 18.0571 83.1662 17.1458 85.5072 17.1458C87.8483 17.1458 90.0483 18.0571 91.7036 19.7118C93.3589 21.3666 94.2706 23.5677 94.2706 25.9079C94.2706 28.248 93.3589 30.4473 91.7036 32.102C90.0483 33.7567 87.8463 34.6681 85.5072 34.6681C83.1681 34.6681 80.9662 33.7567 79.3089 32.102C77.6536 30.4473 76.7419 28.2461 76.7419 25.9079C76.7419 23.5696 77.6536 21.3685 79.3089 19.7138V19.7118Z"
                            fill="#000CED"
                          />
                          <path
                            d="M44.2438 44.8859C41.8661 42.5071 39.0963 40.64 36.0078 39.3367C29.6086 36.6296 22.2282 36.6296 15.827 39.3367C12.7404 40.64 9.96867 42.5071 7.59095 44.8859C5.21322 47.2627 3.34543 50.0315 2.03971 53.1189C0.685696 56.3183 0 59.7108 0 63.2037C0 66.6965 0.685696 70.0929 2.03971 73.2903C3.34543 76.3777 5.21322 79.1485 7.59095 81.5214C9.96867 83.9002 12.7385 85.7673 15.827 87.0707C19.0276 88.4242 22.4232 89.1096 25.9155 89.1096C29.4077 89.1096 32.8053 88.4222 36.0058 87.0707C39.0924 85.7673 41.8642 83.9002 44.24 81.5234C46.6177 79.1504 48.4855 76.3796 49.7893 73.2942C51.1452 70.0948 51.8309 66.7004 51.8309 63.2037C51.8309 59.7069 51.1433 56.3145 49.7912 53.117C48.4855 50.0296 46.6177 47.2608 44.2419 44.8859H44.2438ZM32.1157 69.3997C30.4604 71.0544 28.2584 71.9658 25.9174 71.9658C23.5764 71.9658 21.3763 71.0544 19.7229 69.3997C18.0676 67.745 17.1559 65.5438 17.1559 63.2037C17.1559 60.8635 18.0676 58.6643 19.7229 57.0096C21.3763 55.3548 23.5764 54.4435 25.9174 54.4435C28.2584 54.4435 30.4604 55.3548 32.1157 57.0096C33.771 58.6643 34.6827 60.8654 34.6827 63.2037C34.6827 65.5419 33.771 67.743 32.1157 69.3997Z"
                            fill="#000CED"
                          />
                          <path
                            d="M111.979 89.0093C110.675 85.9238 108.808 83.1531 106.426 80.7743C104.048 78.3994 101.278 76.5342 98.1919 75.227C91.7869 72.52 84.42 72.52 78.0111 75.227C74.9245 76.5323 72.1566 78.3975 69.777 80.7743C67.3973 83.1512 65.5295 85.92 64.2257 89.0074C62.8717 92.2068 62.186 95.6012 62.186 99.094C62.186 102.587 62.8737 105.979 64.2257 109.179C65.5295 112.264 67.3973 115.035 69.7751 117.41C72.1489 119.787 74.9207 121.654 78.0131 122.961C81.2136 124.315 84.6073 125 88.1015 125C91.5956 125 94.9913 124.315 98.1919 122.961C101.278 121.656 104.048 119.789 106.426 117.412C108.806 115.035 110.673 112.266 111.977 109.179C113.331 105.979 114.017 102.587 114.017 99.094C114.017 95.6012 113.331 92.2048 111.977 89.0074L111.979 89.0093ZM94.2998 105.29C92.6445 106.945 90.4425 107.856 88.1034 107.856C85.7643 107.856 83.5624 106.945 81.9051 105.29C80.2498 103.635 79.3381 101.434 79.3381 99.096C79.3381 96.7577 80.2498 94.5566 81.9051 92.9019C83.5604 91.2471 85.7624 90.3358 88.1034 90.3358C90.4444 90.3358 92.6445 91.2471 94.2998 92.9019C95.9551 94.5566 96.8668 96.7577 96.8668 99.0979C96.8668 101.438 95.9551 103.637 94.2998 105.292V105.29Z"
                            fill="#000CED"
                          />
                        </svg>
                      )}
                    </div>
                    <div className="mb-3 flex min-h-[50%] flex-col justify-between px-4 lg:px-8">
                      <div>
                        <Content className="children:text-ubo-taillow children-h2:mb-2 children-h2:text-xl children-h2:font-extrabold children-p:mb-2 children-p:text-base">
                          {item?.description}
                        </Content>
                        <p className="text-lg font-light text-ubo-whiscash">Spreker(s): {fields?.presentators[0].title.split(' ')[0]}</p>
                      </div>
                      <div role="button" onClick={() => handleModalToggle(index)} className="btn btn--blue my-5 w-fit font-bold">
                        <span>Meer informatie</span>
                      </div>
                      <WebinarModal
                        isOpen={modalStates[index]}
                        setIsOpen={() => handleModalToggle(index)}
                        item={item}
                        presentators={fields?.presentators}
                      />
                    </div>
                  </Slide>
                )
              })}
            </Slider>
          </EndlessContainer>
        </div>
      </section>
      <section id="nextWebinar" className="relative bg-ubo-delibird pb-10 pt-10 lg:pb-20">
        <Branding className="absolute right-0 top-0 z-0 hidden lg:block" />
        <Stroke direction="horizontal" type="light" className="relative -top-9 block h-1/5 w-full lg:hidden" />
        <h2 className="text-stroke stroke-ubo px-4 font-september text-4xl font-extrabold uppercase md:text-5xl lg:px-0 lg:text-7xl">
          Eerstvolgende
          <br /> webinar
        </h2>
        <div className="container">
          <div className="mt-10 lg:mt-20 lg:px-16 xl:px-32">
            <Content className="content-md w-3/4 children-h2:mb-4 lg:children-p:text-[24px]">{firstWebinar?.description}</Content>
            <div className="mt-10 grid lg:mt-20 lg:grid-cols-10">
              <div className="col-span-4">
                <ul>
                  {firstWebinar?.sentence?.map((item: any, index: number) => (
                    <li className="list-after relative mb-8 flex items-center lg:mb-16" key={index}>
                      <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M18.84 32.68C26.4836 32.68 32.68 26.4836 32.68 18.84C32.68 11.1964 26.4836 5 18.84 5C11.1964 5 5 11.1964 5 18.84C5 26.4836 11.1964 32.68 18.84 32.68Z"
                          stroke="#000CED"
                          strokeWidth="9"
                          strokeMiterlimit="10"
                        />
                      </svg>
                      <span className="ml-5 font-september text-3xl font-extrabold">{item.description}</span>
                    </li>
                  ))}
                </ul>
                <div className="flex items-center">
                  {fields?.presentators?.map((presentator: any, index: number) => (
                    <div key={index} className="mr-8">
                      <LossePlaatjie
                        src={presentator?.recap?.image}
                        className="h-[100px] w-[100px] rounded-full object-cover object-top lg:h-[141px] lg:w-[141px]"
                      />
                      <div className="mt-6">
                        <p className="text-lg font-light">{presentator?.title}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-span-6 mt-10 lg:mt-0">
                <Content>{firstWebinar?.secondDescription}</Content>
                <div className="mt-8 flex items-center lg:mt-16">
                  <LosseLink
                    to="#inschrijven"
                    className="btn--primary--filled rounded-full px-6 py-2 font-bold lg:px-10 lg:py-4 lg:text-xl"
                  >
                    Inschrijven
                  </LosseLink>
                  <p className="ml-4 text-base font-light lg:ml-8">Beperkt aantal plaatsen beschikbaar</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
