export default function Sound() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <path
        fill="#000CED"
        d="M2 6h3.828c-1.335 2.905-1.335 9.096 0 12H2c-1.311-1.108-2-3.55-2-5.995C0 9.555.692 7.105 2 6zm22 6.005c.005 8.031-3.145 12.864-6.121 11.864-.774-.26-9.567-5.579-9.567-5.579-1.993-2.22-1.993-10.288 0-12.508 0 0 9.161-5.476 9.548-5.633C20.551-.937 23.996 3.97 24 12.005zm-3.383-7.693c-1.053-2.264-3.002-2.226-4.034.002-.588 1.271-.993 3.165-1.21 4.797h.527a2.874 2.874 0 012.873 2.875 2.874 2.874 0 01-2.873 2.875h-.515c.217 1.603.616 3.538 1.206 4.89.988 2.271 3.062 2.232 4.033-.002 1.946-4.477 1.772-11.609-.007-15.437z"
      ></path>
    </svg>
  )
}
