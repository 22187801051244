// @ubo
import { useLosseLanguage } from '@ubo/losse-sjedel'

// Types
import type { Page_Flexcontent_Flex_Banner } from '~/graphql/types'

export default function BannerDeclaratie({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  const [, locale] = useLosseLanguage()

  return (
    <section
      data-component="BannerDefault"
      data-scrollindicatortitle={fields.scrollIndicatorTitle ?? undefined}
      id={fields.sectionId ?? undefined}
      className="h-[250px] bg-ubo-whiscash lg:h-[350px]"
    >
      <div className="container flex h-full items-end justify-center">
        <div className="py-5">
          <h1 className="mb-4 font-september text-3xl text-white lg:text-7xl">
            {locale === 'nl_NL' ? 'UBO DECLARATIE' : 'UBO DECLARATION'}
          </h1>
        </div>
      </div>
    </section>
  )
}
