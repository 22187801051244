// Components
import Content from '~/components/elements/Content'

// Types
import type { Page_Flexcontent_Flex_Hero } from '~/graphql/types'

export default function HeroBranding({ fields }: { fields: Page_Flexcontent_Flex_Hero }) {
  const animatedWords = fields.branding
    ?.concat(fields.branding)
    .concat(fields.branding)
    .concat(fields.branding)
    .concat(fields.branding)
    .concat(fields.branding)
    .concat(fields.branding)
    .concat(fields.branding)
    .concat(fields.branding)
    .concat(fields.branding)
    .concat(fields.branding)
    .concat(fields.branding)

  const sentences = []
  const chunkSize = 8

  for (let i = 0; i < Number(animatedWords?.length); i += chunkSize) {
    const chunk = animatedWords?.slice(i, i + chunkSize)

    sentences.push(chunk)
  }

  return (
    <section
      data-component="HeroBranding"
      data-scrollindicatortitle={fields.scrollIndicatorTitle ?? undefined}
      id={fields.sectionId ?? undefined}
      className="mx-auto h-[35vh] max-h-80 overflow-hidden bg-ubo-whiscash text-white md:max-h-[580px]  lg:h-[60vh] xl:max-h-[1050px] 2xl:h-screen"
    >
      <div className="absolute inset-0 -left-[5%] -top-[12%] w-[110%] opacity-25 lg:-left-[12.5%] lg:w-[125%]">
        {sentences.map((edge, i) => {
          return (
            <div key={i} className="flex">
              {edge?.map((edge) => {
                return (
                  <span
                    key={edge?.word}
                    className="text-stroke mr-4 font-september text-4xl font-extrabold leading-none sm:mr-8 sm:text-5xl md:text-6xl lg:text-7xl xl:text-8xl 2xl:text-10xl"
                  >
                    {edge?.word}
                  </span>
                )
              })}
            </div>
          )
        })}
      </div>
      <div className="container relative h-full w-full">
        <div className="flex h-full items-center justify-center">
          <Content className="content-2xl children-headings:mb-0 children-headings:leading-none children-strong:bg-ubo-whiscash">
            {fields.description}
          </Content>
        </div>
      </div>
    </section>
  )
}
