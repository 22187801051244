import { LosseLink, LossePlaatjie, useLosseLanguage } from '@ubo/losse-sjedel'
import { useRef } from 'react'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Banner } from '~/graphql/types'

export default function BannerWebinarSeries({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  const [, locale] = useLosseLanguage()
  const element = useRef<HTMLDivElement>(null)

  return (
    <section
      data-component="BannerWebinarSeries"
      data-scrollindicatortitle={fields.scrollIndicatorTitle ?? undefined}
      id={fields.sectionId ?? undefined}
      className="relative bg-ubo-taillow pt-10"
      ref={element}
    >
      <div className="container relative z-10 pt-20 text-white">
        <div className="container max-w-screen-xl">
          <div className="relative max-w-5xl pl-12 sm:pl-32 md:pl-48">
            <Plus className="pointer-events-none absolute right-[calc(100%-theme(space.6))] top-0 h-[75px] w-[75px] sm:right-[calc(100%-theme(space.24))] sm:h-[150px] sm:w-[150px] md:right-[calc(100%-theme(space.32))] md:h-[200px] md:w-[200px] lg:h-[250px] lg:w-[250px]" />
            <div className="font-september text-xl font-black uppercase text-ubo-whiscash sm:text-2xl md:text-3xl lg:text-4xl">
              {locale === 'nl_NL' ? 'Webinar serie' : 'Webinar series'}
            </div>
            <Content className="content children-h1:break-words content-stroke-light mt-2 children-em:text-2xl children-h1:text-3xl sm:children-em:text-4xl sm:children-h1:text-5xl md:children-em:text-5xl md:children-h1:text-6xl lg:mt-4 lg:children-em:text-7xl lg:children-h1:text-8xl">
              {fields?.title}
            </Content>
          </div>
        </div>

        <div className="mt-14 grid max-w-screen-xl grid-cols-2 gap-8 sm:mt-20 sm:gap-10 md:gap-16 lg:gap-20 xl:gap-40">
          <div className="relative flex justify-end max-lg:pb-20">
            <div className="relative h-fit w-fit max-w-[450px] lg:absolute">
              <LossePlaatjie src={fields?.image} maxWidth={1000} className="relative z-0" />
              <div className="absolute bottom-0 left-0 right-0 z-10 p-4 text-white xs:p-6">
                <div className="font-september text-lg font-black xs:text-xl sm:text-2xl lg:text-3xl">{fields?.name}</div>
                <div className="text-sm font-light xs:text-base lg:text-lg">{fields?.jobFunction}</div>
              </div>
            </div>
          </div>
          <div className="lg:t-10 pb-20 sm:pb-32 md:pb-48">
            <ul className="flex list-none flex-col gap-4 lg:gap-8">
              {fields?.usps?.map((usp, index) => (
                <li key={index} className="flex items-center">
                  <svg
                    width="24"
                    height="39"
                    viewBox="0 0 24 39"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mr-3 h-5 w-5 max-xs:hidden sm:mr-5 lg:h-6 lg:w-6"
                  >
                    <path
                      d="M15.6426 19.7759L15.9962 19.4223L15.6426 19.0688L0.850357 4.27655L4.4198 0.707107L23.135 19.4223L4.42232 38.1351L0.852879 34.5656L15.6426 19.7759Z"
                      fill="#000CED"
                      stroke="#000CED"
                    />
                  </svg>
                  <span className="font-bold xs:text-lg sm:text-xl lg:text-2xl">{usp?.label}</span>
                </li>
              ))}
            </ul>

            <div className="mt-10 items-center md:mt-20 xl:flex">
              <LosseLink
                src={fields?.link}
                className="btn--primary--filled mr-3 inline-flex rounded-full px-6 py-2 font-bold lg:mr-5 lg:px-10 lg:py-4 lg:text-xl"
              />
              <span className="font-light max-xl:mt-4 max-xl:block max-md:text-sm max-xs:text-xs lg:text-lg">
                {locale === 'nl_NL' ? 'Beperkt aantal plaatsen beschikbaar' : 'Limited number of places available'}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="absolute bottom-0 left-0 z-20 flex w-full justify-center">
        <button
          type="button"
          onClick={() => {
            window.scrollTo({
              top: element.current?.offsetHeight ?? 0,
              behavior: 'smooth'
            })
          }}
          className="p-4"
        >
          <svg width="34" height="21" viewBox="0 0 34 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              opacity="0.4"
              d="M3.67959 0.0315714L8.50701e-06 3.71115L16.709 20.4201L33.4202 3.70898L29.7406 0.0294017L16.709 13.061L3.67959 0.0315714Z"
              fill="white"
            />
          </svg>
        </button>
      </div>
      <ArrowRight className="pointer-events-none absolute bottom-10 left-0 z-0 h-[125px] w-[150px] sm:h-[275px] sm:w-[300px] md:h-[375px] md:w-[400px] lg:bottom-40 lg:h-[500px] lg:w-[550px]" />
    </section>
  )
}

function Plus({ className }: { className?: string }) {
  return (
    <svg width="287" height="287" viewBox="0 0 287 287" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <g clipPath="url(#clip0_264_3)">
        <path d="M157.724 28.4649V0H287V129.276H258.535V28.4649H157.724Z" fill="white" />
        <path d="M28.4649 129.276H0V0H129.276V28.4649H28.4649V129.276Z" fill="white" />
        <path d="M129.276 258.535V287H0V157.724H28.4649V258.535H129.276Z" fill="white" />
        <path d="M258.535 157.724H287V287H157.724V258.535H258.535V157.724Z" fill="white" />
        <path d="M157.724 57.0137H129.259V230.389H157.724V57.0137Z" fill="white" />
        <path d="M230.188 129.477H56.8122V157.942H230.188V129.477Z" fill="white" />
      </g>
    </svg>
  )
}

export function ArrowRight({ className }: { className?: string }) {
  return (
    <svg width="578" height="508" viewBox="0 0 578 508" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <mask
        id="mask0_211_4"
        style={{
          maskType: 'alpha'
        }}
        maskUnits="userSpaceOnUse"
        x="-620"
        y="0"
        width="1198"
        height="508"
      >
        <rect width="1198" height="508" transform="matrix(1 0 0 -1 -620 508)" fill="#070744" />
      </mask>
      <g mask="url(#mask0_211_4)">
        <path d="M-347.633 -13.0027L19.6977 354.306" stroke="#000CED" strokeWidth="2.24133" strokeMiterlimit="10" />
        <path d="M-331.473 -29.163L35.8579 338.146" stroke="#000CED" strokeWidth="2.24133" strokeMiterlimit="10" />
        <path d="M-315.313 -45.3221L52.018 321.986" stroke="#000CED" strokeWidth="2.24133" strokeMiterlimit="10" />
        <path d="M-299.086 -61.5497L68.2446 305.759" stroke="#000CED" strokeWidth="2.24133" strokeMiterlimit="10" />
        <path d="M-282.926 -77.7101L84.4047 289.599" stroke="#000CED" strokeWidth="2.24133" strokeMiterlimit="10" />
        <path d="M-266.766 -93.87L100.565 273.439" stroke="#000CED" strokeWidth="2.24133" strokeMiterlimit="10" />
        <path d="M-77.1958 289.442L3.69367 370.331" stroke="#000CED" strokeWidth="2.24133" strokeMiterlimit="10" />
        <path d="M-123.571 -13.0027L243.738 354.306" stroke="#000CED" strokeWidth="2.24133" strokeMiterlimit="10" />
        <path d="M-107.411 -29.1628L259.898 338.146" stroke="#000CED" strokeWidth="2.24133" strokeMiterlimit="10" />
        <path d="M-91.2509 -45.3221L276.058 321.986" stroke="#000CED" strokeWidth="2.24133" strokeMiterlimit="10" />
        <path d="M-75.0242 -61.5497L292.284 305.759" stroke="#000CED" strokeWidth="2.24133" strokeMiterlimit="10" />
        <path d="M-58.864 -77.7099L308.445 289.599" stroke="#000CED" strokeWidth="2.24133" strokeMiterlimit="10" />
        <path d="M-42.7039 -93.87L324.605 273.439" stroke="#000CED" strokeWidth="2.24133" strokeMiterlimit="10" />
        <path d="M82.1614 354.171L163.028 435.038" stroke="#000CED" strokeWidth="2.24133" strokeMiterlimit="10" />
        <path d="M98.3196 337.989L179.187 418.857" stroke="#000CED" strokeWidth="2.24133" strokeMiterlimit="10" />
        <path d="M114.48 321.829L195.347 402.696" stroke="#000CED" strokeWidth="2.24133" strokeMiterlimit="10" />
        <path d="M130.708 305.602L211.575 386.491" stroke="#000CED" strokeWidth="2.24133" strokeMiterlimit="10" />
        <path d="M146.868 289.442L227.736 370.331" stroke="#000CED" strokeWidth="2.24133" strokeMiterlimit="10" />
        <path d="M17.3645 418.946L98.2316 499.835" stroke="#000CED" strokeWidth="2.24133" strokeMiterlimit="10" />
        <path d="M33.5911 402.74L114.458 483.608" stroke="#000CED" strokeWidth="2.24133" strokeMiterlimit="10" />
        <path d="M49.6829 386.626L130.55 467.493" stroke="#000CED" strokeWidth="2.24133" strokeMiterlimit="10" />
        <path d="M65.9114 370.399L146.778 451.288" stroke="#000CED" strokeWidth="2.24133" strokeMiterlimit="10" />
        <path d="M-63.5925 499.903L17.2746 580.792" stroke="#000CED" strokeWidth="2.24133" strokeMiterlimit="10" />
        <path d="M-47.4324 483.742L33.4347 564.609" stroke="#000CED" strokeWidth="2.24133" strokeMiterlimit="10" />
        <path d="M-31.2722 467.582L49.5948 548.449" stroke="#000CED" strokeWidth="2.24133" strokeMiterlimit="10" />
        <path d="M-15.0458 451.355L65.8212 532.244" stroke="#000CED" strokeWidth="2.24133" strokeMiterlimit="10" />
        <path d="M1.1145 435.195L81.9816 516.062" stroke="#000CED" strokeWidth="2.24133" strokeMiterlimit="10" />
        <path d="M100.469 -13.0027L467.8 354.306" stroke="#000CED" strokeWidth="2.24133" strokeMiterlimit="10" />
        <path d="M116.629 -29.163L483.96 338.146" stroke="#000CED" strokeWidth="2.24133" strokeMiterlimit="10" />
        <path d="M132.789 -45.3221L500.12 321.986" stroke="#000CED" strokeWidth="2.24133" strokeMiterlimit="10" />
        <path d="M149.016 -61.5497L516.347 305.759" stroke="#000CED" strokeWidth="2.24133" strokeMiterlimit="10" />
        <path d="M165.176 -77.7101L532.507 289.599" stroke="#000CED" strokeWidth="2.24133" strokeMiterlimit="10" />
        <path d="M181.336 -93.8701L548.667 273.438" stroke="#000CED" strokeWidth="2.24133" strokeMiterlimit="10" />
        <path d="M306.199 354.171L387.066 435.038" stroke="#000CED" strokeWidth="2.24133" strokeMiterlimit="10" />
        <path d="M322.359 337.989L403.249 418.857" stroke="#000CED" strokeWidth="2.24133" strokeMiterlimit="10" />
        <path d="M338.519 321.829L419.409 402.696" stroke="#000CED" strokeWidth="2.24133" strokeMiterlimit="10" />
        <path d="M354.748 305.602L435.615 386.491" stroke="#000CED" strokeWidth="2.24133" strokeMiterlimit="10" />
        <path d="M370.908 289.442L451.775 370.331" stroke="#000CED" strokeWidth="2.24133" strokeMiterlimit="10" />
        <path d="M241.402 418.946L322.292 499.835" stroke="#000CED" strokeWidth="2.24133" strokeMiterlimit="10" />
        <path d="M257.631 402.74L338.498 483.608" stroke="#000CED" strokeWidth="2.24133" strokeMiterlimit="10" />
        <path d="M273.746 386.626L354.613 467.493" stroke="#000CED" strokeWidth="2.24133" strokeMiterlimit="10" />
        <path d="M289.951 370.399L370.818 451.288" stroke="#000CED" strokeWidth="2.24133" strokeMiterlimit="10" />
        <path d="M160.447 499.903L241.314 580.792" stroke="#000CED" strokeWidth="2.24133" strokeMiterlimit="10" />
        <path d="M176.605 483.742L257.495 564.609" stroke="#000CED" strokeWidth="2.24133" strokeMiterlimit="10" />
        <path d="M192.789 467.582L273.656 548.449" stroke="#000CED" strokeWidth="2.24133" strokeMiterlimit="10" />
        <path d="M208.994 451.355L289.884 532.244" stroke="#000CED" strokeWidth="2.24133" strokeMiterlimit="10" />
        <path d="M225.154 435.195L306.044 516.062" stroke="#000CED" strokeWidth="2.24133" strokeMiterlimit="10" />
      </g>
    </svg>
  )
}
