import { LosseBlogBink, LosseLink, LossePlaatjie, useLoaderData, useLosseBlogBink, useLosseLanguage } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import { motion, useInView } from 'framer-motion'
import { useRef } from 'react'
import Content from '~/components/elements/Content'
import PostsFilters from '~/components/elements/PostsFilters'
import Arrow from '~/components/elements/svg/Arrow'

import type { Case, Category, PageInfo, Page_Flexcontent_Flex_Posts } from '~/graphql/types'
import type { PageLoaderData } from '~/templates/page'

export default function PostsCases({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  const sectionRef = useRef<HTMLElement>(null)

  return (
    <section
      data-component="PostsCases"
      data-scrollindicatortitle={fields.scrollIndicatorTitle ?? undefined}
      className="relative bg-ubo-whiscash pb-32"
      ref={sectionRef}
    >
      <LosseBlogBink pageInfo={fields.posts?.pageInfo as PageInfo}>
        <Overview fields={fields} />
      </LosseBlogBink>
    </section>
  )
}

function Overview({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  const [, locale] = useLosseLanguage()
  const sectionRef = useRef(null)
  const inView = useInView(sectionRef)
  const { rest } = useLoaderData<PageLoaderData>()
  const bb = useLosseBlogBink()

  return (
    <>
      <div className="container pt-32">
        <div ref={sectionRef} className="grid grid-cols-12 items-end">
          <div className="col-span-12 xl:col-span-5">
            <motion.div
              initial={{ x: '-110%' }}
              animate={inView ? { x: 0 } : { x: '-110%' }}
              transition={{
                duration: 1
              }}
            >
              <div className="flex items-start lg:pt-14">
                <Content className="content-3xl text-white">{fields.description}</Content>
                <div className="-mt-4 flex h-6 w-6 items-center justify-center rounded-full bg-ubo-taillow sm:-mt-0 lg:h-9 lg:w-9">
                  <span className="text-sm font-bold text-ubo-delibird lg:text-xl">{rest.cases.pageInfo.total}</span>
                </div>
              </div>
            </motion.div>
          </div>
          <div className="col-span-12 xl:col-span-7">
            <PostsFilters filters={fields.filters} />
          </div>
        </div>
        <div className="mt-28 grid grid-cols-2 gap-4 lg:mx-8 xl:gap-20">
          {fields.posts?.edges.map((edge, i) => {
            const post = edge.node as Case

            return (
              <div
                key={post.title}
                className={clsx(
                  i !== 0 && i !== 1 && 'mt-0 lg:-mt-10 xl:-mt-20',
                  'col-span-2 !h-fit even:pt-0 lg:col-span-1 lg:even:pt-20'
                )}
              >
                <LosseLink to={post.uri} className="group relative block aspect-square overflow-hidden">
                  <LossePlaatjie
                    maxwidth={2000}
                    src={post.recap?.thumbnail}
                    className="h-full w-full object-cover transition-transform group-hover:scale-110"
                  />
                  <div className="absolute inset-0 flex flex-col justify-between px-6 py-5 transition-colors group-hover:bg-ubo-taillow/50 max-lg:bg-ubo-taillow/30 sm:p-4 xl:p-10">
                    <h2 className="font-september text-2xl font-black text-white opacity-100 transition-opacity group-hover:opacity-0 sm:text-3xl md:text-4xl xl:text-5xl">
                      {post.title}
                    </h2>
                    <div className="flex justify-center">
                      <div className="w-1/2">
                        <LossePlaatjie
                          src={post.recap?.logo}
                          className="block w-full opacity-0 transition-opacity group-hover:opacity-100"
                        />
                      </div>
                    </div>
                    <div className="flex flex-col items-start justify-between sm:flex-row sm:gap-4 lg:items-end">
                      <div className="flex w-full flex-grow flex-wrap gap-1 md:gap-2">
                        {post.categories?.edges.map((edge) => {
                          const category = edge.node as Category
                          const active = bb.isFilterActive(category?.databaseId)

                          if (category.databaseId === 1) return null

                          return (
                            <div
                              key={category?.databaseId}
                              className={clsx(
                                active && 'border-ubo-muk underline',
                                'w-fit rounded-full border-2 border-transparent bg-ubo-delibird px-1 text-xs text-ubo-whiscash xs:text-base sm:px-3 md:px-2 md:py-1 lg:text-base'
                              )}
                            >
                              {category?.name}
                            </div>
                          )
                        })}
                      </div>
                      <div className="flex w-full min-w-[250px] items-center justify-end">
                        <span className="btn btn--light mt-2 hidden max-lg:px-3 max-lg:py-[5px] max-lg:text-sm lg:block">
                          {locale === 'nl_NL' ? 'Lees deze case' : 'Read this case'}
                        </span>
                        <div className="mt-1 flex items-center justify-between lg:hidden">
                          <span className="mt-2 inline-flex items-center text-white max-sm:text-sm lg:hidden">
                            <span className="mr-2 max-sm:text-xs">{locale === 'nl_NL' ? 'Lees' : 'Read'}</span>
                            <Arrow />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </LosseLink>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}
