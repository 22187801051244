import { type LoaderData, useLoaderData, pageLoader as loader, useNavigation } from '@ubo/losse-sjedel'
import { useEffect } from 'react'
import Progress from 'nprogress'
import FlexContentHandler from '~/components/flex/FlexContentHandler'
import Layout from '~/components/layout/Layout'
import type { WpPageInfo } from '~/graphql/types'

export interface PageLoaderData extends LoaderData {
  rest: {
    cases: {
      pageInfo: WpPageInfo
    }
  }
}

export { loader }

export default function $page() {
  const { page } = useLoaderData<LoaderData>()

  const transition = useNavigation()

  useEffect(() => {
    if (transition.state === 'loading' || transition.state === 'submitting') {
      Progress.start()
    } else {
      Progress.done()
    }
  }, [transition.state])

  return (
    <Layout>
      <FlexContentHandler prefix={`${page.contentType?.node?.name}_Flexcontent`} flex={page.flexContent?.flex} />
    </Layout>
  )
}
