/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { LosseLink, LossePlaatjie, useLosseLanguage } from '@ubo/losse-sjedel'
import { useRef, useState, useEffect } from 'react'
import clsx from 'clsx'
import { useInView, AnimatePresence, motion, useMotionValue, useSpring } from 'framer-motion'
import { EffectCards, Navigation } from 'swiper/modules'
import Content from '~/components/elements/Content'
import Slider, { Slide } from '~/components/elements/Slider'
import CursorMove from '~/components/elements/svg/CursorMove'
import type Swiper from 'swiper'
import type { Page_Flexcontent_Flex_Hero } from '~/graphql/types'
import type { MouseEvent } from 'react'
import EndlessContainer from '~/components/elements/EndlessContainer'

export default function HeroSwipes({ fields }: { fields: Page_Flexcontent_Flex_Hero }) {
  const [, locale] = useLosseLanguage()
  const [currentIndex, setCurrentIndex] = useState(0)
  const [hoverSwipe, setHoverSwipe] = useState(false)
  const [swiper, setSwiper] = useState<Swiper>()
  const [titleSwiper, setTitleSwiper] = useState<Swiper>()
  const sectionRef = useRef(null)
  const swipesRef = useRef<HTMLDivElement>(null)
  const inView = useInView(sectionRef)

  const cursorX = useMotionValue(620)
  const cursorY = useMotionValue(50)

  useEffect(() => {
    if (!hoverSwipe) {
      cursorX.set(520)
      cursorY.set(50)
    }
  }, [cursorX, cursorY, hoverSwipe])

  function handleCursor(event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) {
    if (swipesRef.current) {
      const { left, top } = swipesRef.current.getBoundingClientRect()

      cursorX.set(event.clientX - left)
      cursorY.set(event.clientY - top)
    }
  }

  function handleSlideChange(slide: Swiper) {
    setCurrentIndex(slide.activeIndex)
    swiper?.slideTo(slide.activeIndex)
    titleSwiper?.slideTo(slide.activeIndex)
    if (swipesRef.current) {
      swipesRef.current.querySelectorAll('video').forEach((vid) => vid.pause())
    }
  }

  const springConfig = { damping: 25, stiffness: 700 }
  const cursorXSpring = useSpring(cursorX, springConfig)
  const cursorYSpring = useSpring(cursorY, springConfig)

  return (
    <section
      data-component="HeroSwipes"
      data-scrollindicatortitle={fields.scrollIndicatorTitle ?? undefined}
      id={fields.sectionId ?? undefined}
      className="presentation-section flex flex-col bg-ubo-taillow text-ubo-delibird"
      ref={sectionRef}
    >
      <div className="presentation-container flex h-full flex-1 flex-col justify-center overflow-hidden pb-24 pt-16">
        <div className="relative inline-flex self-start">
          <Content className="content-xl">{fields.description}</Content>
          <motion.div
            initial={{ x: '100%' }}
            animate={inView ? { x: 0 } : { x: '100%' }}
            exit={{ x: '100%' }}
            transition={{
              duration: 0.75
            }}
            className="absolute -bottom-2 left-full mb-[1.6rem] ml-6 h-[0.125rem] w-[2000px] bg-ubo-delibird sm:mb-[1.875rem]"
          />
        </div>
        <div className="mt-14 grid grid-cols-2 gap-x-28 lg:mt-32">
          <div className="col-span-2 hidden lg:col-span-1 lg:block">
            {fields.swipes?.map((edge, swipeIndex) => {
              const active = currentIndex === swipeIndex
              return (
                <div
                  role="button"
                  onClick={() => {
                    setCurrentIndex(swipeIndex)
                    swiper?.slideTo(swipeIndex)
                  }}
                  key={`${edge?.link?.title}_${swipeIndex}`}
                  className="relative mb-12 border-b-2 border-white py-5"
                >
                  <AnimatePresence>
                    {active && (
                      <motion.div
                        className="absolute -right-24 -top-24 sm:-right-48 lg:-right-40 2xl:-right-8"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{
                          duration: 0.5
                        }}
                      >
                        <LossePlaatjie
                          src={edge?.icon}
                          maxwidth={200}
                          className="pointer-events-none h-1/2 w-1/2 xl:h-2/3 xl:w-2/3 2xl:h-full 2xl:w-full"
                        />
                      </motion.div>
                    )}
                  </AnimatePresence>

                  <span
                    className={clsx(
                      !active && 'text-stroke',
                      'hover:text-nostroke relative block font-september font-black transition-colors lg:text-5xl xl:text-6xl 2xl:text-7xl'
                    )}
                  >
                    {edge?.link?.title}
                  </span>
                </div>
              )
            })}
          </div>
          <div className="relative col-span-2 min-h-[600px] self-center lg:col-span-1 lg:min-h-[740px]">
            <div
              className="relative aspect-video h-full  w-full"
              ref={swipesRef}
              onMouseMove={(event) => handleCursor(event)}
              onMouseEnter={() => setHoverSwipe(true)}
              onMouseLeave={() => setHoverSwipe(false)}
            >
              <motion.div
                className="pointer-events-none absolute z-[99]"
                style={{
                  translateX: cursorXSpring,
                  translateY: cursorYSpring
                }}
              >
                <CursorMove />
              </motion.div>

              <Slider
                key="video_slider"
                effect="cards"
                modules={[EffectCards]}
                cardsEffect={{
                  rotate: false
                }}
                onSwiper={(swiper) => setSwiper(swiper)}
                onSlideChange={(slide) => {
                  handleSlideChange(slide)
                }}
                className="h-full !overflow-visible"
              >
                {fields.swipes?.map((swipe, swipeIndex) => {
                  return (
                    <Slide key={`${swipe?.link?.title}_${swipeIndex}`} className="relative">
                      <div className="absolute h-full">
                        {swipe?.video ? (
                          <video controls muted playsInline className="cursor-move border-2 border-ubo-whiscash">
                            <source src={`${swipe?.video}#t=0.001` || ''} type="video/mp4" />
                          </video>
                        ) : (
                          <LossePlaatjie src={swipe?.image} className="h-full border-2 border-ubo-whiscash object-cover" />
                        )}
                      </div>
                    </Slide>
                  )
                })}
              </Slider>
            </div>
            <div className="mt-9 sm:mb-6 lg:hidden">
              <EndlessContainer className="pl-0">
                <Slider
                  key="title_slider"
                  modules={[Navigation]}
                  onSwiper={(swiper) => setTitleSwiper(swiper)}
                  onSlideChange={(slide) => {
                    handleSlideChange(slide)
                  }}
                  slidesPerView="auto"
                  className="h-full"
                >
                  {fields.swipes?.map((swipe, swipeIndex) => {
                    const active = currentIndex === swipeIndex
                    return (
                      <Slide key={`title_${swipe?.link?.title}_${swipeIndex}`}>
                        <div
                          role="button"
                          onClick={() => {
                            setCurrentIndex(swipeIndex)
                            swiper?.slideTo(swipeIndex)
                            titleSwiper?.slideTo(swipeIndex)
                          }}
                          className="relative block"
                        >
                          <div
                            className={clsx(
                              !active && 'text-stroke',
                              'hover:text-nostroke relative font-september text-3xl font-black transition-colors sm:text-4xl md:text-5xl'
                            )}
                          >
                            {swipe?.link?.title}
                          </div>
                        </div>
                      </Slide>
                    )
                  })}
                </Slider>
              </EndlessContainer>
            </div>
            <AnimatePresence mode="wait">
              <motion.div
                key={fields.swipes![currentIndex]?.description}
                initial={{
                  y: '-150%',
                  opacity: 0
                }}
                animate={inView ? { y: 20, opacity: 1 } : { y: '-150%', opacity: 0 }}
                exit={{ opacity: 0 }}
                transition={{
                  duration: 0.2
                }}
                className="overflow-hidden"
              >
                <Content className="sm:pr-12 2xl:pr-24">{fields.swipes![currentIndex]?.description}</Content>
                <div className="mt-10">
                  <LosseLink to={fields.swipes![currentIndex]?.link?.url} className="btn btn--light--filled">
                    {locale === 'nl_NL' ? 'Meer over ' : 'More about '}
                    {fields.swipes![currentIndex]?.link?.title}
                  </LosseLink>
                </div>
              </motion.div>
            </AnimatePresence>
          </div>
        </div>
      </div>
    </section>
  )
}
