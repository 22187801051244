// Components
import Loading from '~/components/elements/svg/Loading'
import Form from '~/components/elements/Form'
import Content from '~/components/elements/Content'

// Types
import type { Page_Flexcontent_Flex_Form } from '~/graphql/types'
import { LossePlaatjie, useLosseLanguage } from '@ubo/losse-sjedel'
import { FormDuufGroup, FormDuufSubmit } from '@ubo/form-duuf'
import { downloadValidationSchemaNL, downloadValidationSchemaEN } from './validation/downloadValidation'

export default function FormDeclaratie({ fields }: { fields: Page_Flexcontent_Flex_Form }) {
  const [, locale] = useLosseLanguage()

  return (
    <section
      data-component="FormDeclaratie"
      data-scrollindicatortitle={fields.scrollIndicatorTitle ?? undefined}
      id={fields.sectionId ?? undefined}
    >
      <div className="container py-7 lg:py-12">
        <div className="grid grid-cols-12 items-start">
          <div className="col-span-12 lg:col-span-6 lg:mr-8 2xl:col-span-7">
            <LossePlaatjie
              src={fields.image}
              className="mx-auto aspect-square w-full max-w-[280px] object-cover sm:max-w-[500px] md:max-w-none"
              maxwidth={800}
            />
          </div>
          <div className="col-span-12 mt-7 px-4 lg:col-span-6 lg:mt-4 lg:px-12 2xl:col-span-5">
            <Content className="content-xl content-stroke children-headings:mb-6 sm:children-headings:mb-4">{fields?.description}</Content>
            <Form
              generate={false}
              scrollToConfirm={false}
              yupSchema={locale === 'nl_NL' ? downloadValidationSchemaNL : downloadValidationSchemaEN}
              data={fields.form}
              renderLoading={() => <Loading />}
              renderStatus={({ content }) => <Content className="content-lg bg-ubo-whiscash p-12 text-white">{content}</Content>}
              className="form-download my-10"
            >
              <FormFields />
            </Form>
          </div>
        </div>
      </div>
    </section>
  )
}

function FormFields() {
  return (
    <div>
      <FormDuufGroup showIds={[2, 1, 3, 4, 5, 8, 7]} />

      <FormDuufSubmit />
    </div>
  )
}
