// Content
import Content from '~/components/elements/Content'

// Third party
import { motion, useInView } from 'framer-motion'

// Types
import type { Page_Flexcontent_Flex_Text } from '~/graphql/types'
import { useRef } from 'react'
import clsx from 'clsx'

export default function TextQuote({ fields }: { fields: Page_Flexcontent_Flex_Text }) {
  const sectionRef = useRef<HTMLElement>(null)
  const inView = useInView(sectionRef, { once: true })

  let bgClass
  let textClass
  switch (fields.appearance?.bgColor) {
    case 'darkBlue':
      bgClass = `bg-ubo-taillow`
      textClass = `text-ubo-delibird`
      break
    default:
      bgClass = `bg-transparent`
      textClass = `text-ubo-taillow`
      break
  }

  return (
    <section
      data-component="TextQuote"
      data-scrollindicatortitle={fields.scrollIndicatorTitle ?? undefined}
      ref={sectionRef}
      id={fields.sectionId ?? undefined}
      className={`relative z-10 overflow-hidden lg:overflow-visible ${bgClass}`}
    >
      <div className="container pb-10 pt-10 sm:pb-20 sm:pt-0">
        <div className="grid grid-cols-12 gap-y-10 lg:gap-x-20 lg:gap-y-0">
          <div className="col-span-12 overflow-hidden lg:col-span-7">
            {fields.quote?.title && fields.appearance?.titlePosition === 'left' && (
              <Content className="content-md mb-7 children-headings:mb-2">{fields.quote?.title}</Content>
            )}
            <motion.div animate={inView ? { opacity: 1 } : { opacity: 0 }} transition={{ duration: 1.25 }}>
              <Content
                className={`content-sm sm:pt-20 ${textClass} children-blockquote:bg-ubo-whiscash children-blockquote:p-8 children-blockquote:text-white sm:children-blockquote:ml-16`}
              >
                {fields?.description}
              </Content>
            </motion.div>
          </div>
          <div className="relative col-span-12 flex flex-col items-end justify-between sm:mt-28 lg:col-span-5">
            {fields.quote?.title && fields.appearance?.titlePosition === 'right' && (
              <Content
                className={clsx(
                  fields.appearance.bgColor === 'darkBlue' && 'text-ubo-delibird',
                  'content-md mb-10 mt-auto hidden text-right children-headings:mb-2 lg:block'
                )}
              >
                {fields.quote?.title}
              </Content>
            )}
            {fields.quote?.description && (
              <motion.div
                animate={inView ? { x: 0 } : { x: '100%' }}
                transition={{
                  duration: 1.25
                }}
                className="relative bg-ubo-whiscash p-4 text-ubo-delibird sm:p-7 lg:-mb-28 lg:-mr-20 lg:p-12"
              >
                <Content className="content-quote children-ul:text-lg lg:children-ul:text-2xl">{fields.quote?.description}</Content>
              </motion.div>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}
