// Components
import { LossePlaatjie } from '@ubo/losse-sjedel'
import Content from '~/components/elements/Content'

// Third party
import { motion, useInView } from 'framer-motion'

// Types
import type { Page_Flexcontent_Flex_Gallery } from '~/graphql/types'
import { useRef } from 'react'
import clsx from 'clsx'

export default function GalleryDefault({ fields }: { fields: Page_Flexcontent_Flex_Gallery }) {
  const galleryRef = useRef<HTMLDivElement>(null)

  const inView = useInView(galleryRef)

  return (
    <section
      data-component="GalleryDefault"
      data-scrollindicatortitle={fields.scrollIndicatorTitle ?? undefined}
      id={fields.sectionId ?? undefined}
      ref={galleryRef}
      className="overflow-hidden bg-ubo-whiscash pb-12 lg:pb-24"
    >
      <div className="container py-5">
        <Content className="content-3xl content-stroke content-stroke-light mb-12 text-ubo-delibird lg:text-right">
          {fields?.description}
        </Content>

        <div className="grid grid-cols-2 gap-3 lg:grid-cols-3 lg:gap-8">
          {fields.gallery?.map((edge, columnIndex) => {
            if (!edge?.columnWidth) {
              return null
            }

            const colClass = `col-span-${Math.floor(edge.columnWidth / 3 / 10)}`

            return (
              <div key={columnIndex} className={`${colClass} flex h-full flex-col justify-between`} ref={galleryRef}>
                {edge.images?.map((node, imageIndex) => {
                  const odd = columnIndex % 2 !== 0

                  return (
                    <motion.div
                      key={node?.image?.altText}
                      animate={inView ? { x: 0, opacity: 1 } : odd ? { x: '150%', opacity: 0 } : { x: '-150%', opacity: 0 }}
                      transition={{ delay: 0.5 * imageIndex, duration: 1.25 }}
                      className={clsx(edge?.images && edge?.images?.length > 1 && imageIndex > 0 && 'pt-8', `h-full w-full`)}
                    >
                      <LossePlaatjie src={node?.image} maxWidth={1280} className="h-full w-full object-cover" />
                    </motion.div>
                  )
                })}
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}
