/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import { motion, useMotionValue, useSpring } from 'framer-motion'
import { useEffect, useRef, useState } from 'react'
import type Swiper from 'swiper'
import { FreeMode, Mousewheel } from 'swiper/modules'
import Content from '~/components/elements/Content'
import Slider, { Slide } from '~/components/elements/Slider'
import Arrow from '~/components/elements/svg/Arrow'
import type { Employee, Page_Flexcontent_Flex_Posts } from '~/graphql/types'

export default function PostsEmployees({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  const sectionRef = useRef<HTMLDivElement>(null)
  // @ts-ignore
  const [posts, setPosts] = useState<Employee[]>(fields.posts?.edges.map(({ node }) => node) || [])
  const [currentIndex, setCurrentIndex] = useState<number>(0)

  const nextRef = useRef<HTMLButtonElement>(null)
  const prevRef = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    const shuffledPosts = [...posts] // Create a copy of the array
    for (let i = shuffledPosts.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1))
      ;[shuffledPosts[i], shuffledPosts[j]] = [shuffledPosts[j], shuffledPosts[i]] // Swap elements
    }
    setPosts(shuffledPosts)
  }, [])

  const prevButtonX = useMotionValue(0)
  const prevButtonY = useMotionValue(0)
  const nextButtonX = useMotionValue(0)
  const nextButtonY = useMotionValue(0)

  // const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 })

  const currentEmployee = posts[currentIndex]

  const [swiper, setSwiper] = useState<Swiper>()
  const [showPreviousButton, setShowPreviousButton] = useState(false)
  const [showNextButton, setShowNextButton] = useState(false)
  const [isSliderHovered, setIsSliderHovered] = useState(false)

  const handleMouseMove = (event: React.MouseEvent<HTMLElement>) => {
    let buttonOffset = {
      x: 0,
      y: 0
    }

    if (showPreviousButton) {
      buttonOffset = {
        x: (prevRef.current?.clientWidth || 0) / 2,
        y: (prevRef.current?.clientHeight || 0) / 2
      }
    }

    if (showNextButton) {
      buttonOffset = {
        x: (nextRef.current?.clientWidth || 0) / 2,
        y: (nextRef.current?.clientHeight || 0) / 2
      }
    }

    const sectionRect = event.currentTarget.getBoundingClientRect()
    const mouseX = event.clientX - sectionRect.left - buttonOffset.x
    const mouseY = event.clientY - sectionRect.top - buttonOffset.y

    prevButtonX.set(mouseX)
    prevButtonY.set(mouseY)
    nextButtonX.set(mouseX)
    nextButtonY.set(mouseY)

    // return setMousePosition({ x: mouseX, y: mouseY })
  }

  function handleMouseClick() {
    if (showNextButton) {
      if (currentIndex !== posts.length - 1) {
        setCurrentIndex((prev) => prev + 1)
      }
    }

    if (showPreviousButton && currentIndex > 0) {
      setCurrentIndex((prev) => prev - 1)
    }
  }

  useEffect(() => {
    const handleMouseEnter = (event: MouseEvent) => {
      const sectionWidth = sectionRef.current!.offsetWidth
      const mouseX = event.clientX - sectionRef.current!.getBoundingClientRect().left

      setShowPreviousButton(mouseX < sectionWidth * 0.5)
      setShowNextButton(mouseX > sectionWidth * 0.5)
    }

    const handleMouseLeave = () => {
      setShowPreviousButton(false)
      setShowNextButton(false)
    }

    const currentRef = sectionRef.current
    if (currentRef) {
      currentRef.addEventListener('mouseenter', handleMouseEnter)
      currentRef.addEventListener('mousemove', handleMouseEnter)
      currentRef.addEventListener('mouseleave', handleMouseLeave)
    }

    return () => {
      if (currentRef) {
        currentRef.removeEventListener('mouseenter', handleMouseEnter)
        currentRef.removeEventListener('mousemove', handleMouseEnter)
        currentRef.removeEventListener('mouseleave', handleMouseLeave)
      }
    }
  }, [])

  useEffect(() => {
    if (!swiper) return

    try {
      swiper?.slideTo(currentIndex)
    } catch (error) {
      console.error(error)
    }
  }, [currentIndex, swiper])

  const springConfig = { damping: 35, stiffness: 700 }

  const _prevX = useSpring(prevButtonX, springConfig)
  const _prevY = useSpring(prevButtonY, springConfig)
  const _nextX = useSpring(nextButtonX, springConfig)
  const _nextY = useSpring(nextButtonY, springConfig)

  return (
    <motion.section
      ref={sectionRef}
      data-component="PostsEmployees"
      data-scrollindicatortitle={fields.scrollIndicatorTitle ?? undefined}
      id={fields.sectionId ?? undefined}
      className="presentation-section relative flex h-full cursor-pointer flex-col overflow-hidden bg-ubo-taillow text-ubo-delibird lg:min-h-screen"
      onMouseMove={(e) => handleMouseMove(e)}
    >
      {currentIndex !== 0 && !isSliderHovered && (
        <motion.div
          className={clsx(
            showPreviousButton ? 'invisible lg:visible' : 'invisible lg:invisible',
            'pointer-events-none absolute left-0 z-[1000]'
          )}
          style={{ translateX: _prevX, translateY: _prevY }}
        >
          <button ref={prevRef} onClick={() => setCurrentIndex((prev) => prev - 1)} className="relative">
            <span className="absolute opacity-0">prev</span>

            <svg className="-scale-x-[1]" width="92" height="150" viewBox="0 0 92 150" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M59.0811 75.203L59.4346 74.8495L59.0811 74.4959L1.06607 16.4809L16.8398 0.707107L90.9822 74.8495L16.8496 148.982L1.07578 133.208L59.0811 75.203Z"
                fill="#000CED"
                stroke="#000CED"
              />
            </svg>
          </button>
        </motion.div>
      )}

      {currentIndex !== Number(fields.posts?.edges.length) - 1 && !isSliderHovered && (
        <motion.div
          className={clsx(
            showNextButton ? 'invisible lg:visible' : 'invisible lg:invisible',
            'pointer-events-none absolute left-0 z-[1000]'
          )}
          style={{ translateX: _nextX, translateY: _nextY }}
        >
          <button ref={nextRef} className="pointer-events-none relative">
            <span className="absolute opacity-0">volgende</span>
            <svg width="92" height="150" viewBox="0 0 92 150" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M59.0811 75.203L59.4346 74.8495L59.0811 74.4959L1.06607 16.4809L16.8398 0.707107L90.9822 74.8495L16.8496 148.982L1.07578 133.208L59.0811 75.203Z"
                fill="#000CED"
                stroke="#000CED"
              />
            </svg>
          </button>
        </motion.div>
      )}

      <div className="relative mx-auto flex h-full max-w-full flex-1 flex-col lg:p-12">
        <div onClick={() => handleMouseClick()} ref={sectionRef} className="flex flex-col">
          <Content className="content-2xl select-none font-black max-lg:p-4">{fields.description}</Content>
          {fields?.link?.url && (
            <div className="on-top-link relative">
              <div className="w-fit" onMouseEnter={() => setIsSliderHovered(true)} onMouseLeave={() => setIsSliderHovered(false)}>
                <LosseLink to={fields?.link?.url} target={fields?.link?.target || ''} className="btn btn--blue flex gap-2">
                  <Arrow />
                  <span>{fields?.link?.title}</span>
                </LosseLink>
              </div>
            </div>
          )}
          <div className="relative flex w-screen max-w-screen-3xl items-center justify-center max-lg:mt-12 lg:-ml-12">
            {currentIndex !== 0 && (
              <button ref={prevRef} onClick={() => setCurrentIndex((prev) => prev - 1)} className="absolute bottom-0 left-4 z-50 lg:hidden">
                <span className="absolute opacity-0">prev</span>

                <svg
                  className="h-auto w-7 -scale-x-[1]"
                  width="92"
                  height="150"
                  viewBox="0 0 92 150"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M59.0811 75.203L59.4346 74.8495L59.0811 74.4959L1.06607 16.4809L16.8398 0.707107L90.9822 74.8495L16.8496 148.982L1.07578 133.208L59.0811 75.203Z"
                    fill="#000CED"
                    stroke="#000CED"
                  />
                </svg>
              </button>
            )}
            {currentIndex !== Number(fields.posts?.edges.length) - 1 && (
              <button
                ref={nextRef}
                onClick={() => setCurrentIndex((prev) => prev + 1)}
                className="absolute bottom-0 right-4 z-50 lg:hidden"
              >
                <span className="absolute opacity-0">volgende</span>
                <svg className="h-auto w-7" width="92" height="150" viewBox="0 0 92 150" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M59.0811 75.203L59.4346 74.8495L59.0811 74.4959L1.06607 16.4809L16.8398 0.707107L90.9822 74.8495L16.8496 148.982L1.07578 133.208L59.0811 75.203Z"
                    fill="#000CED"
                    stroke="#000CED"
                  />
                </svg>
              </button>
            )}

            <div className="marquee">
              <div className="track">
                <div className="content">
                  <span className="text--stroke select-none font-september text-[15vw] font-black uppercase">
                    &nbsp;{currentEmployee.title} {currentEmployee.title} {currentEmployee.title}
                  </span>
                </div>
              </div>
            </div>

            <div className="relative">
              <motion.div
                key={currentEmployee.title}
                initial={{ opacity: 0, borderColor: 'transparent' }}
                animate={{ opacity: 1, borderColor: '#FFF' }}
                exit={{ opacity: 0, borderColor: 'transparent' }}
                className="mx-auto aspect-[9/14] h-[302px] max-h-[60vh] border-4 border-solid md:h-[475px] lg:h-[654px]"
              >
                <LossePlaatjie
                  src={currentEmployee.recap?.image}
                  className="relative h-full w-full select-none object-cover"
                  maxWidth={600}
                />
              </motion.div>
              <div
                className={clsx(
                  'pointer-events-none bottom-14 z-[1000] flex w-full flex-col items-center max-lg:mt-8 lg:absolute lg:-right-40 lg:w-[150%] lg:items-end'
                )}
              >
                <motion.div
                  transition={{
                    delay: 0.25
                  }}
                  key={currentEmployee.title}
                  initial={{ x: '-100%', opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  exit={{ x: '-100%', opacity: 0 }}
                >
                  <span className="text-shadow block select-none whitespace-nowrap text-start font-september text-2xl font-black md:text-3xl lg:text-right lg:text-4xl">
                    {currentEmployee.title}
                  </span>
                  <div className="mx-auto mt-3 flex w-fit items-center bg-ubo-whiscash px-5 py-3 text-lg lg:ml-auto">
                    <LossePlaatjie
                      src={currentEmployee.recap?.icon}
                      className="mr-4 h-4 w-4 sm:h-6 sm:w-6 lg:h-8 lg:w-8"
                      draggable="false"
                    />
                    <div className="select-none text-sm sm:text-base md:text-lg lg:text-xl">
                      <span>{currentEmployee.recap?.function}</span>
                      {currentEmployee.recap?.alternativefunction && (
                        <>
                          <span> | </span>
                          <span className="font-bold opacity-30">{currentEmployee.recap?.alternativefunction}</span>
                        </>
                      )}
                    </div>
                  </div>
                </motion.div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="relative z-[1000] mx-auto mt-auto max-2xl:max-w-full 2xl:max-w-screen-2xl"
          onMouseEnter={() => setIsSliderHovered(true)}
          onMouseLeave={() => setIsSliderHovered(false)}
        >
          <Slider
            onSwiper={(sw) => setSwiper(sw)}
            slideToClickedSlide
            freeMode
            draggable
            breakpoints={{
              0: {
                slidesPerView: 5.75,
                spaceBetween: 5
              },
              640: {
                slidesPerView: 8.75,
                spaceBetween: 10
              },
              768: {
                slidesPerView: 11.75,
                spaceBetween: 10
              },
              1024: {
                slidesPerView: 14.75,
                spaceBetween: 10
              }
            }}
            modules={[Mousewheel, FreeMode]}
            mousewheel={{
              forceToAxis: true
            }}
            className="relative !-mr-4 mt-10 !pr-4 max-lg:!pb-8 lg:!-mr-12 lg:!pr-12"
          >
            {posts?.map((edge, i) => {
              const post = edge as Employee

              const active = currentIndex === i

              return (
                <Slide
                  role="button"
                  onClick={() => {
                    return setCurrentIndex(i)
                  }}
                  key={post.title}
                  className={clsx(active ? 'border-white' : 'border-transparent hover:border-[#7979FB]', 'border-2')}
                >
                  <LossePlaatjie src={post.recap?.image} className="object-cover" maxWidth={300} />
                </Slide>
              )
            })}
          </Slider>
        </div>
      </div>
    </motion.section>
  )
}
