import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'

// Components
import FormContact from './FormContact'
import FormWhitepaper from './FormWhitepaper'
import FormContactDetail from './FormContactDetail'
import FormDownload from './FormDownload'
import FormDeclaratie from './FormDeclaratie'
import FormGrowthTool from './FormGrowthTool'
import FormSupport from './FormSupport'
import FormWebinar from './FormWebinar'

export default function FormShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    contact: FormContact,
    contact_detail: FormContactDetail,
    declaratie: FormDeclaratie,
    whitepaper: FormWhitepaper,
    download: FormDownload,
    growth_tool: FormGrowthTool,
    support: FormSupport,
    webinar: FormWebinar
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
