// Third party
import { LossePlaatjie, useLosseLanguage } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import { AnimatePresence, motion, useInView } from 'framer-motion'
import type { ReactNode } from 'react'
import { useRef, useState } from 'react'
import { Mousewheel, Navigation } from 'swiper/modules'

// Components
import Content from '~/components/elements/Content'
import Slider, { Slide } from '~/components/elements/Slider'
import Arrow from '~/components/elements/svg/Arrow'
import Stroke from '~/components/elements/svg/Stroke'

// Types
import type { Page_Flexcontent_Flex_Usps } from '~/graphql/types'

export default function UspsDefault({ fields }: { fields: Page_Flexcontent_Flex_Usps }) {
  const [, locale] = useLosseLanguage()
  const strokeRef = useRef<HTMLDivElement>(null)
  const strokeInView = useInView(strokeRef)
  const [currentUspIndex, setCurrentUspIndex] = useState(-1)

  function handleUspClick(i: number) {
    if (i === currentUspIndex) {
      setCurrentUspIndex(-1)
    } else {
      setCurrentUspIndex(i)
    }
  }

  return (
    <section
      data-component="UspsDefault"
      data-scrollindicatortitle={fields.scrollIndicatorTitle ?? undefined}
      id={fields.sectionId ?? undefined}
      className="relative overflow-hidden bg-ubo-taillow pb-28"
    >
      <div className="absolute bottom-0 w-full" ref={strokeRef}>
        <motion.div
          initial={{ x: '110%' }}
          animate={strokeInView ? { x: 0 } : { x: '110%' }}
          transition={{
            duration: 1.25
          }}
        >
          <Stroke direction="horizontal" className="w-full" />
        </motion.div>
      </div>

      <div className="container text-ubo-delibird">
        <Content className="content-2xl content-stroke-light my-16 px-6 lg:px-20">{fields.description}</Content>
      </div>
      <div className="relative">
        <div className="container mb-9 flex items-center justify-end">
          <button type="button" className="custom-swiper-button-prev mb-1 mr-7">
            <Arrow className="h-auto w-16 rotate-180" />
          </button>
          <button type="button" className="custom-swiper-button-next">
            <Arrow className="h-auto w-16" />
          </button>
        </div>
        <div className="container">
          <Slider
            modules={[Navigation, Mousewheel]}
            navigation={{
              prevEl: '.custom-swiper-button-prev',
              nextEl: '.custom-swiper-button-next'
            }}
            mousewheel={{
              forceToAxis: true
            }}
            className="relative"
            breakpoints={{
              0: {
                slidesPerView: 1.2,
                spaceBetween: 40
              },
              640: {
                slidesPerView: 1.8,
                spaceBetween: 50
              },
              1024: {
                slidesPerView: 2,
                spaceBetween: 70
              },
              1280: {
                slidesPerView: 3,
                spaceBetween: 40
              }
            }}
          >
            {fields.usps?.map((edge, uspIndex) => {
              const active = currentUspIndex === uspIndex
              return (
                <Slide
                  key={`${edge?.description}`}
                  className={clsx(currentUspIndex === -1 && '!h-auto', 'group cursor-pointer')}
                  onClick={() => handleUspClick(uspIndex)}
                >
                  <AnimatePresence mode="wait">
                    {!active ? (
                      <Card side="front" uspIndex={uspIndex}>
                        <LossePlaatjie
                          src={edge?.icon}
                          className="mx-auto mb-6 mt-6 h-32 w-32 transition-transform group-hover:scale-105 md:mb-16 md:mt-16 lg:mt-0 lg:h-72 lg:w-72"
                          maxwidth={288}
                        />

                        <Content className="content-xs mb-5 md:mb-0">{edge?.description}</Content>

                        <div className="absolute bottom-0 right-0 flex items-center bg-ubo-whiscash px-2 py-2 text-white transition-transform group-hover:scale-105 md:px-4 md:py-3">
                          <span className="hidden pr-3 text-sm group-hover:flex">{locale === 'nl_NL' ? 'Meer info' : 'More info'}</span>
                          <svg id="Laag_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 179.11 179.11" className="h-5 w-5">
                            <defs></defs>
                            <g id="Laag_1-2">
                              <g>
                                <path
                                  fill="currentColor"
                                  d="m89.56,53.45c7.16,0,12.97-5.81,12.97-12.97s-5.81-12.97-12.97-12.97-12.97,5.81-12.97,12.97,5.81,12.97,12.97,12.97Z"
                                />
                                <polygon
                                  fill="currentColor"
                                  points="98.2 112.38 98.2 95.08 98.2 77.79 98.2 60.5 80.91 60.5 72.26 60.5 72.26 77.79 80.91 77.79 80.91 95.08 80.91 112.38 80.91 129.67 80.91 146.96 98.2 146.96 106.85 146.96 106.85 129.67 98.2 129.67 98.2 112.38"
                                />
                                <path
                                  fill="currentColor"
                                  d="m89.56,0C40.17,0,0,40.18,0,89.56s40.17,89.56,89.56,89.56,89.56-40.18,89.56-89.56S138.94,0,89.56,0Zm0,162.46c-40.2,0-72.91-32.71-72.91-72.91S49.36,16.65,89.56,16.65s72.91,32.71,72.91,72.91-32.71,72.91-72.91,72.91Z"
                                />
                              </g>
                            </g>
                          </svg>
                        </div>
                      </Card>
                    ) : (
                      <Card side="back" uspIndex={uspIndex}>
                        <Content className="content-xs">{edge?.unfoldDescription}</Content>
                      </Card>
                    )}
                  </AnimatePresence>
                </Slide>
              )
            })}
          </Slider>
        </div>
      </div>
    </section>
  )
}

function Card({
  children,
  className,
  side = 'front',
  uspIndex
}: {
  children: ReactNode
  className?: string
  side?: 'front' | 'back'
  uspIndex: number
}) {
  return (
    <motion.div
      key={`${side}_${uspIndex}`}
      initial={{ scale: 0 }}
      animate={{ scale: 1 }}
      exit={{ scale: 1 }}
      transition={{ duration: 0.37 }}
      className={`group relative flex h-full flex-col items-center justify-between overflow-hidden bg-ubo-delibird ${className}`}
    >
      <div className="p-4 sm:p-12">{children}</div>
    </motion.div>
  )
}
