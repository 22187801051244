// Components
import Content from '~/components/elements/Content'

// Third party
import { AnimatePresence, motion, useInView } from 'framer-motion'

// Types
import type { Page_Flexcontent_Flex_Text } from '~/graphql/types'
import { useRef } from 'react'

export default function TextAnimatedBackground({ fields }: { fields: Page_Flexcontent_Flex_Text }) {
  const sectionRef = useRef<HTMLElement>(null)
  const inView = useInView(sectionRef, {
    margin: '-25% -25% -25% -25%'
  })
  const animatedWords = fields.animatedWords
    ?.concat(fields.animatedWords)
    .concat(fields.animatedWords)
    .concat(fields.animatedWords)
    .concat(fields.animatedWords)
    .concat(fields.animatedWords)

  return (
    <section
      data-component="TextAnimatedBackground"
      data-scrollindicatortitle={fields.scrollIndicatorTitle ?? undefined}
      ref={sectionRef}
      id={fields.sectionId ?? undefined}
      className="relative flex flex-col overflow-hidden bg-ubo-whiscash transition-all lg:min-h-[655px]"
    >
      <AnimatePresence>
        {inView && (
          <div>
            <div className="pointer-events-none absolute -left-[40%] -top-[6.75%] h-[120%] w-[180%]">
              {animatedWords?.map((edge, wordIndex) => {
                return (
                  <motion.span
                    key={edge?.title}
                    className="text-stroke font-september text-[7.5vw] font-black leading-[7vw] text-ubo-delibird"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 0.2 }}
                    transition={{
                      delay: 0.15 * wordIndex
                    }}
                  >
                    {`${edge?.title} `}
                  </motion.span>
                )
              })}
            </div>
          </div>
        )}
      </AnimatePresence>

      <div className="container flex h-full flex-1 flex-col items-center py-7 lg:py-20 xl:flex-row">
        <div className="mb-6 flex h-full justify-center lg:mb-0 xl:w-1/2">
          <Content className="content-2xl text-ubo-delibird 2xl:ml-24 2xl:mr-12">{fields.title}</Content>
        </div>
        <div className="xl:w-1/2 2xl:pl-24">
          {fields.sentences?.map((edge, sentenceIndex) => (
            <motion.p
              key={edge?.description}
              initial={{ y: '100%', opacity: 0 }}
              animate={inView ? { y: 0, opacity: 1 } : { y: '100%', opacity: 0 }}
              transition={{
                delay: 0.1 * sentenceIndex,
                duration: 1
              }}
              className="overflow-hidden text-center text-lg font-bold !leading-[2rem] text-ubo-delibird lg:text-[1.675rem] lg:!leading-[2.5rem] xl:text-left"
            >
              {edge?.description}
            </motion.p>
          ))}
        </div>
      </div>
    </section>
  )
}
