// Components
import Content from '~/components/elements/Content'

// Types
import type { Page_Flexcontent_Flex_Text } from '~/graphql/types'

export default function TextDefault({ fields }: { fields: Page_Flexcontent_Flex_Text }) {
  return (
    <section
      data-component="TextDefault"
      data-scrollindicatortitle={fields.scrollIndicatorTitle ?? undefined}
      id={fields.sectionId ?? undefined}
    >
      <div className="container py-5">
        <Content>{fields?.description}</Content>
      </div>
    </section>
  )
}
