import { motion } from 'framer-motion'

export default function X({ className = '', inView }: { className?: string; inView: boolean }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1324" height="1117" fill="none" viewBox="0 0 1324 1117" className={className}>
      <g fill="#070744" clipPath="url(#clip0_1458_23669)">
        <motion.path
          d="M2081.9-127h-285.11L1112 557.794l684.79 684.796h285.11l-684.8-684.796L2081.9-127z"
          initial={{ x: 200 }}
          animate={inView ? { x: 0 } : { x: 200 }}
          transition={{ delay: 0.5 }}
        />
        <motion.path
          d="M969.896 557.996h-.008l684.792-684.794h-285.1L827.345 415.446 285.101-126.798H0l684.795 684.794h-.007v.007L0 1242.79h285.101l542.237-542.236 542.242 542.236h285.1L969.888 558.003l.008-.007z"
          initial={{ opacity: 0, x: -200 }}
          animate={inView ? { opacity: 1, x: 0 } : { opacity: 0, x: -200 }}
          transition={{ delay: 0.75 }}
        />
        <motion.path
          d="M142.543 1527.9V1813l684.795-684.79L1512.13 1813v-285.1L827.338 843.104 142.543 1527.9zM1512.13-411.899V-697L827.345-12.205 142.543-697v285.101l684.802 684.794 684.785-684.794z"
          initial={{ y: 200 }}
          animate={inView ? { y: 0 } : { y: 200 }}
          transition={{ delay: 0.5 }}
        />
      </g>
      <defs>
        <clipPath id="clip0_1458_23669">
          <path fill="#fff" d="M0 0H1324V1117H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
