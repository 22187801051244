// @ubo
import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'

// Components
import TextDefault from './TextDefault'
import TextImage from './TextImage'
import TextQuote from './TextQuote'
import TextAnimatedBackground from './TextAnimatedBackground'
import TextIcon from './TextIcon'
import TextTitle from './TextTitle'
import TextFeaturing from './TextFeaturing'
import TextVideo from './TextVideo'
import TextUsps from './TextUsps'
import TextWebinar from './TextWebinar'

export default function TextShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    default: TextDefault,
    image: TextImage,
    quote: TextQuote,
    animated_background: TextAnimatedBackground,
    icon: TextIcon,
    title: TextTitle,
    featuring: TextFeaturing,
    video: TextVideo,
    with_usps: TextUsps,
    webinar: TextWebinar
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
