export default function BrandingLeft({ className }: { className?: string }) {
  return (
    <svg width="711" height="298" viewBox="0 0 711 298" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <mask id="mask0_299_32" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="711" height="302">
        <rect width="711" height="301.492" transform="matrix(1 0 0 -1 0 301.493)" fill="#070744" />
      </mask>
      <g mask="url(#mask0_299_32)">
        <path d="M-218.093 -26.898L-0.0859582 191.096" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M-208.463 -36.5288L9.54434 181.465" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M-198.872 -46.1201L19.1353 171.874" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M-189.281 -55.7109L28.7259 162.283" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M-104.296 -7.71681L113.711 210.277" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M-94.6927 -17.3076L123.301 200.686" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M-85.1018 -26.898L132.892 191.096" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M-75.4841 -36.5288L142.523 181.465" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M-65.8807 -46.1201L152.113 171.874" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M-56.2898 -55.7109L161.704 162.283" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M17.8025 210.197L65.8095 258.19" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M27.4073 200.593L75.401 248.587" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M36.9981 191.002L84.9918 238.996" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M46.6284 181.371L94.6221 229.378" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M56.2193 171.781L104.213 219.788" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M-20.6399 248.639L27.3538 296.646" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M-11.0223 239.022L36.9847 287.016" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M-1.45811 229.459L46.5356 277.453" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M8.15943 219.827L56.1665 267.834" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M-39.8749 267.874L8.11878 315.881" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M-30.2841 258.283L17.7097 306.277" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M28.6817 -7.71681L246.675 210.277" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M38.2726 -17.3076L256.266 200.686" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M47.8634 -26.898L265.87 191.096" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M57.4937 -36.5288L275.487 181.465" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M67.0847 -46.1201L285.078 171.874" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M76.6753 -55.7109L294.669 162.283" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M150.782 210.197L198.775 258.19" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M160.371 200.593L208.365 248.587" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M169.962 191.002L217.956 238.996" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M179.594 181.371L227.587 229.378" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M189.185 171.781L237.178 219.788" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M112.325 248.639L160.319 296.646" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M121.956 239.022L169.949 287.016" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M131.506 229.459L179.5 277.453" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M141.137 219.827L189.131 267.834" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M64.2783 296.687L112.272 344.694" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M73.8691 287.096L121.863 335.09" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M83.4589 277.505L131.453 325.499" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M93.0903 267.874L141.084 315.881" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M102.681 258.283L150.675 306.277" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M161.647 -7.71681L379.654 210.277" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M171.238 -17.3076L389.245 200.686" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M180.828 -26.898L398.835 191.096" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M190.459 -36.5288L408.466 181.465" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M200.05 -46.1201L418.057 171.874" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M209.641 -55.7109L427.648 162.283" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M283.746 210.197L331.739 258.19" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M293.336 200.593L341.343 248.587" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M302.927 191.002L350.934 238.996" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M312.559 181.371L360.552 229.378" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M322.148 171.781L370.155 219.788" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M245.29 248.639L293.297 296.646" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M254.921 239.022L302.914 287.016" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M264.485 229.459L312.479 277.453" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M274.102 219.827L322.096 267.834" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M197.243 296.687L245.237 344.694" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M206.847 287.096L254.841 335.09" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M216.438 277.505L264.432 325.499" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M226.055 267.874L274.062 315.881" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M235.646 258.283L283.653 306.277" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M294.625 -7.71681L512.619 210.277" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M304.216 -17.3076L522.21 200.686" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M313.807 -26.898L531.801 191.096" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M323.437 -36.5288L541.431 181.465" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M333.028 -46.1201L551.022 171.874" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M342.619 -55.7109L560.613 162.283" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M416.725 210.197L464.719 258.19" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M426.315 200.593L474.309 248.587" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M435.906 191.002L483.899 238.996" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M445.537 181.371L493.531 229.378" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M455.128 171.781L503.122 219.788" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M378.269 248.639L426.263 296.646" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M387.899 239.022L435.893 287.016" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M397.45 229.459L445.443 277.453" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M407.081 219.827L455.075 267.834" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M330.222 296.687L378.216 344.694" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M339.813 287.096L387.806 335.09" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M349.404 277.505L397.397 325.499" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M359.034 267.874L407.028 315.881" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M368.625 258.283L416.619 306.277" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M427.59 -7.71681L645.597 210.277" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M437.181 -17.3076L655.188 200.686" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M446.772 -26.898L664.779 191.096" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M456.402 -36.5288L674.409 181.465" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M465.993 -46.1201L684 171.874" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M475.584 -55.7109L693.591 162.283" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M549.689 210.197L597.683 258.19" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M559.28 200.593L607.287 248.587" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M568.871 191.002L616.878 238.996" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M578.502 181.371L626.496 229.378" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M588.093 171.781L636.087 219.788" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M511.233 248.639L559.24 296.646" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M520.864 239.022L568.858 287.016" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M530.429 229.459L578.422 277.453" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M540.046 219.827L588.04 267.834" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M463.187 296.687L511.181 344.694" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M472.777 287.096L520.784 335.09" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M482.381 277.505L530.375 325.499" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M491.999 267.874L540.006 315.881" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
        <path d="M501.59 258.283L549.597 306.277" stroke="white" strokeWidth="1.3302" strokeMiterlimit="10" />
      </g>
    </svg>
  )
}
