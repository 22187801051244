export default function ArrowRounded({ className = '' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="43" height="43" fill="none" viewBox="0 0 43 43" className={className ?? undefined}>
      <circle cx="21.5" cy="21.5" r="20.5" stroke="#000CED" strokeWidth="2"></circle>
      <path
        fill="#000CED"
        d="M11 20.5a1.5 1.5 0 000 3v-3zm23.06 2.56a1.5 1.5 0 000-2.12l-9.545-9.547a1.5 1.5 0 10-2.122 2.122L30.88 22l-8.486 8.485a1.5 1.5 0 102.122 2.122l9.546-9.546zM11 23.5h22v-3H11v3z"
      ></path>
    </svg>
  )
}
