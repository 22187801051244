// @ubo
import { LosseFlex } from '@ubo/losse-sjedel'

// Types
import type { Page_Flexcontent } from '~/graphql/types'

// Flex components
import BannerShell from './Banner/BannerShell'
import TextShell from './Text/TextShell'
import Fragment from './Fragment'
import FormShell from './Form/FormShell'
import PostsShell from './Posts/PostsShell'
import Splash from './Splash'
import HeroShell from './Hero/HeroShell'
import UspsShell from './Usps/UspsShell'
import FeaturedShell from './Featured/FeaturedShell'
import ScrollEffectShell from './ScrollEffect/ScrollEffectShell'
import GalleryShell from './Gallery/GalleryShell'
import CtaShell from './Cta/CtaShell'
import MiscShell from './Misc/MiscShell'
import WebinarsShell from './Webinars/WebinarsShell'

interface FlexContentHandlerProps {
  flex: Page_Flexcontent['flex']
  prefix: string
}

export default function FlexContentHandler({ flex, prefix = 'page_Flexcontent' }: FlexContentHandlerProps) {
  const flexers = {
    [`${prefix}_Flex_Banner`]: BannerShell,
    [`${prefix}_Flex_Text`]: TextShell,
    [`${prefix}_Flex_Hero`]: HeroShell,
    [`${prefix}_Flex_Usps`]: UspsShell,
    [`${prefix}_Flex_ScrollEffect`]: ScrollEffectShell,
    [`${prefix}_Flex_Posts`]: PostsShell,
    [`${prefix}_Flex_Featured`]: FeaturedShell,
    [`${prefix}_Flex_Gallery`]: GalleryShell,
    [`${prefix}_Flex_Cta`]: CtaShell,
    [`${prefix}_Flex_Form`]: FormShell,
    [`${prefix}_Flex_Splash`]: Splash,
    [`${prefix}_Flex_Misc`]: MiscShell,
    [`${prefix}_Flex_Webinars`]: WebinarsShell,
    [`${prefix}_Flex_Fragment`]: Fragment
  }

  // @ts-ignore
  return <LosseFlex flex={flex} flexers={flexers} />
}
