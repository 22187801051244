// @ubo
import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'

// Types
import type { Page_Flexcontent_Flex_Featured } from '~/graphql/types'

// Third party
import { useInView, motion, useMotionValue, useSpring } from 'framer-motion'

// Hooks
import type { MouseEvent } from 'react'
import { useEffect, useRef, useState } from 'react'

// SVG
import Arrow from '~/components/elements/svg/Arrow'
import X from '~/components/elements/svg/X'
import Play from '~/components/elements/svg/Play'
import Download from '~/components/elements/svg/Download'

// Components
import Content from '~/components/elements/Content'
import Stroke from '~/components/elements/svg/Stroke'

export default function FeaturedWebinarAlternate({ fields }: { fields: Page_Flexcontent_Flex_Featured }) {
  const [hoverThumbnail, setHoverThumbnail] = useState(false)
  const sectionRef = useRef(null)
  const thumbnailRef = useRef<HTMLImageElement | null>(null)
  const buttonRef = useRef(null)

  const cursorX = useMotionValue(190)
  const cursorY = useMotionValue(120)

  const inView = useInView(sectionRef)

  useEffect(() => {
    if (!hoverThumbnail) {
      cursorX.set(190)
      cursorY.set(120)
    }
  }, [cursorX, cursorY, hoverThumbnail])

  function handleCursor(event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) {
    if (thumbnailRef.current) {
      const { left, top } = thumbnailRef.current.getBoundingClientRect()

      cursorX.set(event.clientX - left - 110)
      cursorY.set(event.clientY - top - 36)
    }
  }

  const springConfig = { damping: 25, stiffness: 700 }
  const cursorXSpring = useSpring(cursorX, springConfig)
  const cursorYSpring = useSpring(cursorY, springConfig)

  return (
    <section
      data-component="FeaturedWebinarAlternate"
      data-scrollindicatortitle={fields.scrollIndicatorTitle ?? undefined}
      className="presentation-section relative bg-ubo-whiscash text-ubo-delibird"
      id="webinars"
      ref={sectionRef}
    >
      <X inView={inView} className="absolute right-0 top-0 hidden h-full w-auto lg:block" />
      <Stroke direction="horizontal" type="dark" className="absolute top-0 block h-1/5 w-full lg:hidden" />
      <div className="presentation-container relative flex flex-col py-12 lg:py-24">
        <>
          <Content className="content-3xl mb-9 block lg:hidden">{fields.title}</Content>
          <motion.div
            initial={{ x: '70vw' }}
            animate={inView ? { x: '0vw' } : { x: '70vw' }}
            transition={{
              delay: 0.5,
              bounce: 0,
              duration: 0.75
            }}
            className="relative ml-auto w-full lg:order-last lg:w-3/5"
            onMouseEnter={() => setHoverThumbnail(true)}
            onMouseLeave={() => setHoverThumbnail(false)}
          >
            <LosseLink to={fields.webinar?.uri}>
              <div className="absolute inset-0 h-full w-full bg-ubo-delibird opacity-50 lg:hidden" />
              <div
                ref={thumbnailRef}
                onMouseMove={(event) => handleCursor(event)}
                className="flex items-center justify-center lg:block lg:items-start lg:justify-start"
              >
                <motion.div
                  ref={buttonRef}
                  role="button"
                  className="btn btn--dark lg:btn--light pointer-events-none absolute z-[99] hidden sm:inline-flex"
                  style={{
                    translateX: cursorXSpring,
                    translateY: cursorYSpring
                  }}
                >
                  <Play className="hidden lg:block" type="light" />
                  <Play className="lg:hidden" type="dark" />
                  <span className="ml-2">Play webinar</span>
                </motion.div>
                <div ref={buttonRef} role="button" className="btn btn--dark pointer-events-none absolute z-[99] sm:hidden">
                  <Play type="dark" />
                  <span className="ml-2">Play webinar</span>
                </div>

                <LossePlaatjie src={fields.image} className="h-full w-full object-cover" />
              </div>
            </LosseLink>
          </motion.div>
          <motion.div
            initial={{ x: '-100vw' }}
            animate={{ x: 0 }}
            transition={{ delay: 0.5 }}
            exit={{ x: '-100vw' }}
            className="lg:order-first"
          >
            <Content className="content-3xl mb-9 hidden lg:block">{fields.title}</Content>
            <Content className="content-3xl mb-9 mt-7 w-full lg:mt-0 lg:w-1/2 lg:children-p:pr-16">{fields.description}</Content>
            <div>
              <LosseLink className="btn btn--light--filled" to={fields.download?.url} target={fields.download?.target || '_blank'}>
                <Download />
                <span className="ml-3">{fields.download?.title}</span>
              </LosseLink>
            </div>
            <div className="mt-5">
              <LosseLink
                to={fields.link?.url}
                className="group inline-flex items-center rounded-full border-2 border-transparent transition-all duration-300 hover:border-ubo-delibird"
              >
                <div className="rounded-full border-2 border-ubo-delibird p-4 transition-all duration-300 group-hover:border-transparent">
                  <Arrow />
                </div>
                <span className="px-3 lg:text-lg">{fields.link?.title}</span>
              </LosseLink>
            </div>
          </motion.div>
        </>
      </div>
    </section>
  )
}
