export default function Triangle({ className = '', type = 'dark' }: { className?: string; type?: 'dark' | 'light' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1000" height="1117" fill="none" viewBox="0 0 1000 1117" className={className}>
      <path
        className={type === 'dark' ? 'fill-ubo-taillow' : 'fill-ubo-whiscash'}
        fillRule="evenodd"
        d="M1120.73 555.089L2316.98-636.274l-559.3-561.406L0 552.992 1755.58 2315.56l561.4-559.31L1120.73 555.089z"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}
