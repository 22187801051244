// @ubo
import { LossePlaatjie } from '@ubo/losse-sjedel'

// Components
import Content from '~/components/elements/Content'

// Types
import type { Page_Flexcontent_Flex_Banner } from '~/graphql/types'

export default function BannerDefault({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  return (
    <section
      data-component="BannerDefault"
      data-scrollindicatortitle={fields.scrollIndicatorTitle ?? undefined}
      id={fields.sectionId ?? undefined}
    >
      <div className="container">
        <div className="relative h-[400px]">
          <LossePlaatjie
            maxwidth={1999}
            loading="eager"
            className="!absolute left-0 top-0 -z-10 h-full w-full rounded-2xl object-cover"
            src={fields.image}
          />
        </div>
        <div className="py-5">
          <Content className="text-light children-h1:text-secondary children-p:text-greyLight">{fields?.description}</Content>
        </div>
      </div>
    </section>
  )
}
