// @ubo
import { LosseLink } from '@ubo/losse-sjedel'

// Hooks
import { useEffect, useRef, useState } from 'react'

// Third party
import { AnimatePresence, motion, useInView, useMotionValue, useSpring } from 'framer-motion'

// Types
import type { Page_Flexcontent_Flex_Banner } from '~/graphql/types'
import type { MouseEvent } from 'react'

// SVG
import Identity from '~/components/elements/svg/Identity'
import Content from '~/components/elements/Content'
import Play from '~/components/elements/svg/Play'
import useLayout from '~/hooks/useLayout'

export default function BannerHome({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  const [fullscreen, setFullscreen] = useState(false)
  const [hoverReel, setHoverReel] = useState(false)
  const [initialButtonPosition, setInitialButtonPosition] = useState({
    x: 0,
    y: 0
  })
  const [muted, setMuted] = useState(true)
  const fullscreenRef = useRef(null)
  const fullscreenInView = useInView(fullscreenRef)
  const { splashSeen } = useLayout()

  const sectionRef = useRef<HTMLElement>(null)
  const reelRef = useRef<HTMLVideoElement | null>(null)
  const buttonRef = useRef<HTMLDivElement>(null)

  const cursorX = useMotionValue(190)
  const cursorY = useMotionValue(120)

  useEffect(() => {
    if (!fullscreenInView) {
      setFullscreen(false)
    }
  }, [fullscreenInView])

  useEffect(() => {
    if (!hoverReel) {
      cursorX.set(initialButtonPosition.x)
      cursorY.set(initialButtonPosition.y)
    }

    const videoWidth = reelRef.current?.clientWidth
    const videoHeight = reelRef.current?.clientHeight

    if (videoWidth && videoHeight) {
      const buttonX = videoWidth / 2
      const buttonY = videoHeight / 2

      if (!buttonRef.current) return

      setInitialButtonPosition({
        x: buttonX - buttonRef.current.clientWidth / 2,
        y: buttonY - buttonRef.current.clientHeight / 2
      })
    }
  }, [cursorX, cursorY, hoverReel, initialButtonPosition.x, initialButtonPosition.y])

  const handlePlay = () => {
    setFullscreen(true)
    setTimeout(() => {
      document.getElementById('fullscreen')?.scrollIntoView()
    }, 1)
    setMuted(false)
  }

  function handleCursor(event: MouseEvent<HTMLVideoElement, globalThis.MouseEvent>) {
    if (reelRef.current) {
      const { left, top } = reelRef.current.getBoundingClientRect()

      cursorX.set(event.clientX - left - 100)
      cursorY.set(event.clientY - top - 36)
    }
  }

  const springConfig = { damping: 25, stiffness: 700 }
  const cursorXSpring = useSpring(cursorX, springConfig)
  const cursorYSpring = useSpring(cursorY, springConfig)

  return (
    <>
      <AnimatePresence>
        {splashSeen && (
          <motion.section
            data-component="BannerHome"
            data-scrollindicatortitle={fields.scrollIndicatorTitle ?? undefined}
            id={fields.sectionId ?? undefined}
            ref={sectionRef}
            className="presentation-section w-screen max-w-full overflow-hidden bg-ubo-whiscash pb-24 pt-32 text-ubo-delibird lg:pt-40"
            initial={{ x: '100vw' }}
            animate={splashSeen ? { x: '0vw' } : { x: '100vw' }}
            transition={{
              bounce: 0,
              duration: 0.75
            }}
            layout
          >
            <motion.div
              className="absolute bottom-0 hidden lg:block"
              initial={{
                x: '-100vw'
              }}
              animate={
                splashSeen
                  ? {
                      x: '-15vw'
                    }
                  : {
                      x: '-100vw'
                    }
              }
              transition={{
                delay: 0.25,
                duration: 2,
                bounce: 0
              }}
            >
              <Identity />
            </motion.div>
            <div className="presentation-container grid grid-cols-5 pb-10 sm:pb-16">
              <div className="col-span-5 lg:col-span-3">
                <Content className="content-2xl">{fields.description}</Content>
              </div>
              <div className="col-span-5 mt-5 hidden lg:col-span-2 lg:block 2xl:mr-20">
                <Links fields={fields} />
              </div>
            </div>
            <div className="presentation-container relative">
              <div className="relative w-full">
                <div
                  className="relative mx-auto h-full cursor-pointer xl:w-[90%]"
                  onClick={() => handlePlay()}
                  onMouseEnter={() => setHoverReel(true)}
                  onMouseLeave={() => setHoverReel(false)}
                >
                  <motion.div
                    key={`reel_${fields.video}`}
                    role="button"
                    ref={buttonRef}
                    className="btn btn--light pointer-events-none absolute z-[99] px-2 shadow-sm lg:px-4"
                    style={{
                      translateX: cursorXSpring,
                      translateY: cursorYSpring,
                      textShadow: '1px 2px 2px rgba(0,0,0,0.3)'
                    }}
                    initial={{ opacity: 0 }}
                    animate={splashSeen ? { opacity: 1 } : { opacity: 0 }}
                    transition={{
                      duration: 3
                    }}
                  >
                    <Play className="block rounded-full shadow-lg" />
                    <span className="ml-2">Play</span>
                  </motion.div>
                  <motion.video
                    ref={reelRef}
                    onMouseMove={(event) => handleCursor(event)}
                    muted
                    autoPlay
                    playsInline
                    loop
                    initial={splashSeen ? { x: '70vw' } : { x: '0vw' }}
                    animate={splashSeen ? { x: '0vw' } : { x: '70vw' }}
                    transition={{
                      delay: 0.25,
                      bounce: 0,
                      duration: 0.75
                    }}
                    className="h-full w-full object-cover"
                  >
                    <source src={fields.video || ''} type="video/mp4" />
                  </motion.video>
                </div>
              </div>
              <div className="mt-8 grid grid-cols-2 gap-x-4 lg:hidden">
                <Links fields={fields} />
              </div>
            </div>
          </motion.section>
        )}
      </AnimatePresence>
      <div ref={fullscreenRef}>
        {fullscreen && (
          <section
            data-component="BannerHome_Fullscreen"
            className="h-[90vh] overflow-hidden bg-ubo-roggenrola md:h-[83vh] lg:h-screen"
            id="fullscreen"
          >
            <Fullscreen fields={fields} muted={muted} />
          </section>
        )}
      </div>
    </>
  )
}

function Links({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  const { splashSeen } = useLayout()

  return (
    <>
      {fields.links?.map((edge, index) => {
        return (
          <motion.div
            key={index}
            initial={{ x: '100vw', y: '0%' }}
            animate={splashSeen ? { x: '0vw' } : { x: '100vw' }}
            transition={{
              delay: index * 0.25 + 0.5
            }}
            className="relative col-span-2 mb-4 w-full sm:col-span-1 lg:col-span-2"
          >
            <LosseLink to={edge?.pagelink?.uri} key={index} className="btn btn--light w-full">
              <Content className="content-xl children-strong:!font-bold children-strong:opacity-50">{edge?.description}</Content>
            </LosseLink>
          </motion.div>
        )
      })}
    </>
  )
}

function Fullscreen({ fields, muted }: { fields: Page_Flexcontent_Flex_Banner; muted: boolean }) {
  const fullscreenRef = useRef<HTMLVideoElement>(null)
  const viewRef = useRef<HTMLDivElement>(null)
  const inView = useInView(viewRef, { margin: '-50px' })

  useEffect(() => {
    if (inView && fullscreenRef.current) {
      fullscreenRef.current.volume = 0.1
      fullscreenRef.current.play()
    }

    if (!inView && fullscreenRef.current) {
      fullscreenRef.current.pause()
    }
  }, [inView])

  return (
    <div className="relative flex h-full items-center justify-center">
      <div ref={viewRef} className="absolute" />
      <motion.video
        key={`fullscreen_${fields.video}`}
        onEnded={() => document.getElementById('services')?.scrollIntoView()}
        controls
        autoPlay
        playsInline
        muted={muted}
        ref={fullscreenRef}
        initial={{ height: 0 }}
        animate={inView ? { height: '100%' } : { height: 0 }}
        transition={{
          bounce: 0,
          duration: 0.5
        }}
        className="mx-auto aspect-video"
      >
        <source src={fields.video || ''} type="video/mp4" />
      </motion.video>
    </div>
  )
}
