/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ubo
import { LosseLink, LossePlaatjie, useLoaderData, useLosseLanguage } from '@ubo/losse-sjedel'

// Hooks
import type { MouseEvent } from 'react'
import { useEffect, useRef, useState } from 'react'

// Types
import type { Case, Page_Flexcontent_Flex_Featured } from '~/graphql/types'

// Third party
import clsx from 'clsx'
import { AnimatePresence, motion, useMotionValue, useSpring } from 'framer-motion'

// Components
import Content from '~/components/elements/Content'

// SVG
import Triangle from '~/components/elements/svg/Triangle'
import Stripe from '~/components/elements/svg/Stripe'
import EndlessContainer from '~/components/elements/EndlessContainer'
import Slider, { Slide } from '~/components/elements/Slider'
import { FreeMode, Mousewheel, Navigation } from 'swiper/modules'
import type { PageLoaderData } from '~/templates/page'
import Arrow from '~/components/elements/svg/Arrow'

export default function FeaturedCases({ fields }: { fields: Page_Flexcontent_Flex_Featured }) {
  const [, locale] = useLosseLanguage()
  const [hoverCaseVideo, setHoverCaseVideo] = useState(false)
  const [currentCaseIndex, setCurrentCaseIndex] = useState(0)
  const videoRef = useRef<HTMLVideoElement>(null)
  const animatedButtonRef = useRef<HTMLDivElement>(null)
  const staticButtonRef = useRef<HTMLDivElement>(null)
  const { rest } = useLoaderData<PageLoaderData>()
  const sectionRef = useRef<HTMLDivElement>(null)
  const cursorX = useMotionValue(520)
  const cursorY = useMotionValue(850)

  useEffect(() => {
    if (!hoverCaseVideo && staticButtonRef.current) {
      const { top, left } = staticButtonRef.current.getBoundingClientRect()

      cursorX.set(left)
      cursorY.set(top)
    }

    if (!animatedButtonRef.current) return
  }, [cursorX, cursorY, hoverCaseVideo])

  function handleCursor(event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) {
    if (videoRef.current) {
      const { left, top } = videoRef.current.getBoundingClientRect()

      if (!animatedButtonRef.current) return

      if (hoverCaseVideo) {
        cursorX.set(event.clientX - left - animatedButtonRef.current.clientWidth / 2)
        cursorY.set(event.clientY - top - animatedButtonRef.current.clientHeight / 2)
      }

      cursorX.set(event.clientX - left - animatedButtonRef.current.clientWidth / 2)
      cursorY.set(event.clientY - top - animatedButtonRef.current.clientHeight / 2)
    }
  }

  function handleCaseChange(index: number) {
    if (index !== currentCaseIndex) {
      setCurrentCaseIndex(index)
    }
  }

  const currentCase = fields.cases![currentCaseIndex] as Case
  const casesCount = Number(fields.cases?.length) - 1

  const springConfig = { damping: 35, stiffness: 700 }

  const cursorXSpring = useSpring(cursorX, springConfig)
  const cursorYSpring = useSpring(cursorY, springConfig)

  return (
    <div
      ref={sectionRef}
      className="presentation-section relative flex flex-col justify-center overflow-hidden sm:flex-row lg:hidden lg:bg-ubo-roggenrola"
    >
      <div className="relative max-w-[2245px]">
        <div className="relative overflow-hidden">
          <motion.div
            ref={animatedButtonRef}
            role="button"
            className="btn btn--light absolute z-[97] bg-ubo-delibird text-ubo-whiscash transition-colors"
            style={{
              translateX: cursorXSpring,
              translateY: cursorYSpring
            }}
            initial={{ opacity: 0 }}
            animate={hoverCaseVideo ? { opacity: 1 } : { opacity: 0 }}
            transition={{ duration: 0.5, bounce: 0 }}
          >
            <LosseLink to={currentCase.uri}>
              {locale === 'nl_NL' ? 'Meer over' : 'More about'} {currentCase.title}
            </LosseLink>
          </motion.div>
          <div className="overflow-hidden">
            <AnimatePresence key={currentCase.recap?.video} mode="wait">
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                key={currentCase.recap?.video}
                className="min-h-[220px] w-full lg:min-h-screen"
              >
                {currentCase.recap?.video ? (
                  <video
                    // onEnded={() => setCurrentCaseIndex((prevState) => prevState + 1)}
                    muted
                    playsInline
                    autoPlay
                    className="h-auto overflow-hidden object-cover xl:min-h-screen"
                    ref={videoRef}
                  >
                    <source src={currentCase.recap?.video || ''} type="video/mp4" />
                  </video>
                ) : (
                  // @ts-ignore
                  <div ref={videoRef as HTMLVideoElement}>
                    <LossePlaatjie
                      src={currentCase.recap?.featuredImage}
                      className="h-full w-full object-cover xl:min-h-screen"
                      maxwidth={1999}
                      loading="eager"
                    />
                  </div>
                )}
              </motion.div>
            </AnimatePresence>
          </div>
        </div>
        <div className="relative -mt-16 h-full overflow-hidden lg:-mt-24 xl:absolute xl:inset-0 xl:mt-0">
          <motion.div
            className="relative h-full w-full"
            initial={{ x: 0 }}
            animate={hoverCaseVideo ? { x: '-11%' } : { x: 0 }}
            transition={{ bounce: 0, duration: 0.2 }}
          >
            <Triangle
              type="light"
              className={clsx(
                hoverCaseVideo && 'opacity-30',
                'absolute -left-[5%] top-0 hidden h-full -scale-x-[1] transition-opacity xl:block 2xl:left-0'
              )}
            />
            <div className="container relative z-10 h-full xl:mx-0 xl:py-14">
              <div className="absolute left-0 top-4 overflow-hidden md:-left-10 lg:-left-20 lg:top-12 xl:hidden">
                <Stripe type="light" />
              </div>
              <motion.div
                className="relative flex items-start xl:px-6"
                initial={{ x: 0 }}
                animate={hoverCaseVideo ? { x: '18%' } : { x: 0 }}
              >
                <Content className="content-3xl relative text-ubo-delibird">{fields.title}</Content>
                <span className="ml-1 flex h-5 w-5 items-center justify-center rounded-full bg-ubo-delibird text-sm font-medium text-ubo-whiscash lg:h-8 lg:w-8 lg:text-base 2xl:bg-ubo-whiscash 2xl:text-white">
                  {rest?.cases?.pageInfo.total}
                </span>
              </motion.div>
              <EndlessContainer className="mb-5 mt-20 pl-0 lg:mt-12 xl:hidden">
                <Slider
                  modules={[Navigation, FreeMode, Mousewheel]}
                  mousewheel={{ forceToAxis: true }}
                  freeMode
                  slidesPerView="auto"
                  className="xl:hidden"
                >
                  {fields.cases?.map((edge, caseIndex) => {
                    const post = edge as Case
                    const active = currentCaseIndex === caseIndex

                    return (
                      <Slide className="group !w-auto px-1" key={post.title}>
                        <div
                          className={clsx(
                            active ? 'bg-ubo-whiscash text-white' : 'bg-white text-ubo-whiscash',
                            'relative z-10 mr-4 border border-ubo-whiscash px-1 text-center font-light'
                          )}
                          role="button"
                          onClick={() => {
                            setCurrentCaseIndex(caseIndex)
                          }}
                        >
                          {post.title}
                        </div>
                        <div className="absolute -right-2 bottom-0 top-0 m-auto h-[1px] w-[50px] bg-ubo-whiscash group-last:hidden"></div>
                      </Slide>
                    )
                  })}
                </Slider>
              </EndlessContainer>
              <div className="pb-16 lg:mb-40 xl:mt-[10vh] xl:w-2/3 xl:pb-0 2xl:w-1/2">
                <div className="grid sm:grid-cols-5">
                  <motion.div
                    key={currentCase.title}
                    className="col-span-1 mt-20 hidden overflow-hidden xl:block 2xl:col-span-2"
                    initial={{ opacity: 1 }}
                    animate={hoverCaseVideo ? { opacity: 0 } : { opacity: 1 }}
                  >
                    {fields.cases?.map((edge, caseIndex) => {
                      const post = edge as Case
                      const active = currentCaseIndex === caseIndex

                      return (
                        <div className={clsx(active ? 'opacity-1' : 'opacity-50', 'flex flex-col items-center')} key={post.title}>
                          <div
                            role="button"
                            className="border border-ubo-delibird p-1 text-center lg:text-sm xl:text-ubo-delibird 2xl:text-base"
                            onClick={() => {
                              handleCaseChange(caseIndex)
                            }}
                          >
                            {post.title}
                          </div>
                          {caseIndex !== casesCount && <div className="h-10 w-1 bg-ubo-delibird" />}
                        </div>
                      )
                    })}
                  </motion.div>
                  <div className="pr-8 transition-all sm:col-span-5 sm:pr-20 lg:col-span-4 lg:py-6 lg:pr-52 xl:py-20 xl:pl-8 2xl:col-span-3 2xl:pl-4 2xl:pr-12">
                    <AnimatePresence mode="wait">
                      <motion.div
                        key={currentCase.title}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{
                          duration: 0.2
                        }}
                      >
                        <h2 className="mb-6 font-september text-5xl font-black text-ubo-roggenrola xl:text-ubo-delibird">
                          {currentCase.title}
                        </h2>
                        <Content className="xl:text-ubo-delibird">{currentCase.recap?.description}</Content>
                        <motion.div
                          role="button"
                          ref={staticButtonRef}
                          initial={{ opacity: 0 }}
                          animate={!hoverCaseVideo ? { opacity: 1 } : { opacity: 0 }}
                          transition={{
                            duration: 0.25
                          }}
                          className="btn btn--light z-[97] mt-7 hidden xl:inline-flex"
                        >
                          <LosseLink to={currentCase.uri}>
                            {locale === 'nl_NL' ? 'Meer over' : 'More about'} {currentCase.title}
                          </LosseLink>
                        </motion.div>
                      </motion.div>
                    </AnimatePresence>

                    <LosseLink to={currentCase.uri} className="btn btn--primary--filled mt-6 fill-ubo-delibird xl:hidden">
                      <Arrow type="lightBlue" />
                      <span className="ml-2">
                        {locale === 'nl_NL' ? 'Meer over' : 'More about'} {currentCase.title}
                      </span>
                    </LosseLink>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        </div>
        <div
          className={clsx(hoverCaseVideo ? 'w-[60%]' : 'w-1/2', 'absolute right-0 top-0 z-[98] hidden h-full cursor-pointer xl:block')}
          onMouseMove={(event) => handleCursor(event)}
          onMouseEnter={() => setHoverCaseVideo(true)}
          onMouseLeave={() => setHoverCaseVideo(false)}
        >
          <LosseLink to={currentCase.uri} className="block h-full w-full opacity-0">
            {locale === 'nl_NL' ? 'Meer over' : 'More about'} {currentCase.title}
          </LosseLink>
        </div>
      </div>
    </div>
  )
}
