import { useRef } from 'react'

// Third party
import clsx from 'clsx'
import { useInView, motion } from 'framer-motion'

// Components
import Content from '~/components/elements/Content'

// Types
import type { Maybe, Page_Flexcontent_Flex_Usps, Page_Flexcontent_Flex_Usps_Usps } from '~/graphql/types'
import Stripes from '~/components/elements/svg/Stripes'
import { LosseLink } from '@ubo/losse-sjedel'

export default function UspsNumbers({ fields }: { fields: Page_Flexcontent_Flex_Usps }) {
  return (
    <section
      data-component="UspsNumbers"
      data-scrollindicatortitle={fields.scrollIndicatorTitle ?? undefined}
      id={fields.sectionId ?? undefined}
      className={clsx(
        fields.appearance?.bgColor === 'light' && 'bg-ubo-delibird',
        fields.appearance?.bgColor === 'lightBlue' && 'bg-ubo-whiscash text-ubo-delibird',
        fields.appearance?.bgColor === 'darkBlue' && 'bg-ubo-taillow text-ubo-delibird',
        'relative overflow-hidden py-8 sm:py-20 lg:py-32'
      )}
    >
      {fields.appearance?.showBranding && <Stripes className="absolute left-1/2 right-0 top-0" />}
      <div className="container">
        <div className="grid grid-cols-12">
          <div
            className={clsx(fields.appearance?.mirrorColumns ? 'lg:order-last lg:col-span-6 lg:ml-16' : 'lg:col-span-5', 'col-span-12 ')}
          >
            <Content
              className={clsx(
                fields.appearance?.bgColor === 'light' ? 'children-p:text-ubo-whiscash' : 'children-p:text-ubo-delibird',
                'content-xxl children-p:mt-4'
              )}
            >
              {fields?.description}
            </Content>
            {fields.link?.url && fields.appearance?.ctaPosition !== 'centered' && (
              <div className="mt-12">
                {fields.microcopy && <h3 className="mb-3 text-xl font-bold">{fields.microcopy}</h3>}
                <LosseLink src={fields.link} className="btn--sm btn--light--filled"></LosseLink>
              </div>
            )}
          </div>
          <div
            className={clsx(
              fields.appearance?.mirrorColumns ? 'lg:order-first lg:col-span-6 lg:mr-24' : 'lg:col-span-7 lg:ml-20',
              'col-span-12 mt-10  lg:mt-0'
            )}
          >
            {fields.usps?.map((edge, uspIndex) => {
              return (
                <div
                  key={edge?.description}
                  className={clsx(uspIndex !== Number(fields.usps?.length) - 1 && 'mb-12', 'flex items-start lg:items-center')}
                >
                  <Usp usp={edge} uspIndex={uspIndex} bgColor={fields.appearance?.bgColor} />
                </div>
              )
            })}
          </div>
        </div>
        {fields.link?.url && fields.appearance?.ctaPosition === 'centered' && (
          <div className="flex justify-center">
            <div className="mt-16 flex flex-col items-center">
              {fields.microcopy && <h3 className="mb-3 text-xl font-bold">{fields.microcopy}</h3>}
              <LosseLink src={fields.link} className="btn--sm btn--light--filled"></LosseLink>
            </div>
          </div>
        )}
      </div>
    </section>
  )
}

function Usp({
  usp,
  uspIndex,
  bgColor
}: {
  usp: Maybe<Page_Flexcontent_Flex_Usps_Usps>
  uspIndex: number
  bgColor: Maybe<string> | undefined
}) {
  const uspRef = useRef<HTMLDivElement | null>(null)

  const inView = useInView(uspRef)

  return (
    <motion.div
      ref={uspRef}
      key={`${usp?.description}`}
      className="flex items-start lg:items-center"
      initial={{ opacity: 0 }}
      animate={inView ? { opacity: 1 } : { opacity: 0 }}
      transition={{
        duration: 1
      }}
    >
      <span
        className={clsx(
          (bgColor === 'light' || bgColor === 'darkBlue') && 'text-ubo-whiscash',
          'mr-10 min-w-[5rem] font-september text-7xl font-black'
        )}
      >
        {uspIndex + 1}.
      </span>
      <Content className="content-xs">{usp?.description}</Content>
    </motion.div>
  )
}
