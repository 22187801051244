import Loading from '~/components/elements/svg/Loading'
import Form from '~/components/elements/Form'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Form } from '~/graphql/types'
import { LossePlaatjie, useLosseLanguage } from '@ubo/losse-sjedel'
import { FormDuufField, FormDuufSubmit } from '@ubo/form-duuf'
import Arrow from '~/components/elements/svg/Arrow'

export default function FormContactDetail({ fields }: { fields: Page_Flexcontent_Flex_Form }) {
  const [, locale] = useLosseLanguage()

  return (
    <section
      data-component="FormContactDetail"
      data-scrollindicatortitle={fields.scrollIndicatorTitle ?? undefined}
      id={fields.sectionId ?? undefined}
    >
      <div className="container py-7 lg:py-28">
        <div className="grid grid-cols-12 items-start">
          <div className="col-span-12 md:mr-8 lg:col-span-6 2xl:col-span-7">
            <LossePlaatjie
              src={fields.image}
              className="mx-auto aspect-square w-4/5 max-w-[280px] object-cover sm:max-w-[500px] md:max-w-none lg:mx-0 lg:ml-auto"
              maxwidth={1000}
            />
          </div>
          <div className="col-span-12 p-4 lg:col-span-6 lg:p-4 2xl:col-span-5">
            <Content className="content-xl content-stroke children-headings:mb-6 sm:children-headings:mb-0">{fields?.description}</Content>
            <Form
              generate={false}
              data={fields.form}
              renderLoading={() => <Loading />}
              renderStatus={({ content }) => <Content className="content-lg bg-ubo-whiscash p-12 text-white">{content}</Content>}
              className="form-contact-detail my-10"
              privacyUrl={locale === 'nl_NL' ? '/privacyverklaring/' : '/en/privacy-statement/'}
              privacyUrlPlacement={locale === 'nl_NL' ? 'privacybeleid' : 'privacy policy'}
              scrollToConfirm={false}
            >
              {fields?.form?.formId === 19 ? <FormCustomInner /> : <FormInner />}
            </Form>
          </div>
        </div>
      </div>
    </section>
  )
}

const FormInner = () => {
  return (
    <>
      <div className="mt-12 flex flex-col items-center sm:flex-row">
        <div className="w-full sm:mr-6 lg:mr-4">
          <FormDuufField id={4} />
        </div>
        <div className="w-full sm:mr-8 lg:mr-4">
          <FormDuufField id={1} />
        </div>
      </div>
      <div className="flex flex-col items-center sm:flex-row">
        <div className="w-full sm:mr-6 lg:mr-4">
          <FormDuufField id={6} />
        </div>
        <div className="w-full sm:mr-6 lg:mr-4">
          <FormDuufField id={7} />
        </div>
      </div>
      <div className="flex w-full items-end justify-between">
        <div className="w-full sm:mr-8 lg:mr-4">
          <FormDuufField id={2} />
        </div>
      </div>
      <div className="mt-6 flex w-full items-center justify-between">
        <FormDuufField id={3} />
        <div className="mr-3 flex flex-col items-center text-ubo-delibird">
          <div className="relative flex justify-center">
            <Arrow className="absolute top-0 h-6 w-9" />
            <FormDuufSubmit />
          </div>
        </div>
      </div>
    </>
  )
}

const FormCustomInner = () => {
  return (
    <>
      <div className="mt-12 flex flex-col items-center sm:flex-row">
        <div className="w-full sm:mr-6 lg:mr-4">
          <FormDuufField id={1} />
        </div>
        <div className="w-full sm:mr-8 lg:mr-4">
          <FormDuufField id={6} />
        </div>
      </div>
      <div className="flex flex-col items-center sm:flex-row">
        <div className="w-full sm:mr-6 lg:mr-4">
          <FormDuufField id={3} />
        </div>
        <div className="w-full sm:mr-6 lg:mr-4">
          <FormDuufField id={4} />
        </div>
      </div>
      <div className="mt-6 flex w-full items-center justify-between">
        <div className="mr-3 flex flex-col items-center text-ubo-delibird">
          <div className="relative flex justify-center">
            <Arrow className="absolute top-0 h-6 w-9" />
            <FormDuufSubmit />
          </div>
        </div>
      </div>
    </>
  )
}
