import { memo } from 'react'

function Play({ type = 'light', className }: { type?: 'light' | 'dark'; className?: string }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="none" viewBox="0 0 28 28" className={className}>
      <path
        className={type === 'light' ? 'fill-ubo-delibird' : 'fill-ubo-roggenrola'}
        fillRule="evenodd"
        d="M14 28c7.732 0 14-6.268 14-14S21.732 0 14 0 0 6.268 0 14s6.268 14 14 14zm5.41-12.757c1-.578 1-2.02 0-2.598l-6.66-3.846a1.5 1.5 0 00-2.25 1.3v7.69a1.5 1.5 0 002.25 1.3l6.66-3.846z"
        clipRule="evenodd"
      />
    </svg>
  )
}

export default memo(Play)
