import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'

// Components
import BannerDefault from './BannerDefault'
import BannerDetail from './BannerDetail'
import BannerHome from './BannerHome'
import BannerVideo from './BannerVideo'
import BannerOverview from './BannerOverview'
import BannerDeclaratie from './BannerDeclaratie'
import BannerSupport from './BannerSupport'
import BannerWebinarSeries from './BannerWebinarSeries'

export default function BannerShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    default: BannerDefault,
    home: BannerHome,
    detail: BannerDetail,
    declaratie: BannerDeclaratie,
    video: BannerVideo,
    overview: BannerOverview,
    support: BannerSupport,
    webinar_series: BannerWebinarSeries
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
