import { LosseLink } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Text } from '~/graphql/types'
import { ArrowRight } from '../Banner/BannerWebinarSeries'

export default function TextWebinar({ fields }: { fields: Page_Flexcontent_Flex_Text }) {
  return (
    <section
      data-component="TextWebinar"
      data-scrollindicatortitle={fields.scrollIndicatorTitle ?? undefined}
      id={fields.sectionId ?? undefined}
      className={clsx(fields?.alternative ? 'bg-ubo-taillow' : 'bg-ubo-delibird', 'relative overflow-hidden py-16 sm:py-20')}
    >
      <div className={clsx(fields?.alternative && 'flex sm:justify-end', 'container relative z-10 max-w-screen-2xl lg:px-10')}>
        <Content
          className={clsx(
            fields?.alternative ? '' : 'children-em:stroke-ubo',
            'content content-stroke-light text-4xl children-p:mb-4 last:children-p:mb-0 sm:text-6xl lg:text-8xl'
          )}
        >
          {fields?.title}
        </Content>
      </div>
      <div className="container relative z-10 mt-10 xl:px-48">
        <div className={clsx(fields?.alternative ? 'flex sm:justify-end' : '')}>
          <Content
            className={clsx(
              fields?.alternative ? 'text-white sm:text-right' : 'text-ubo-taillow',
              'content max-w-3xl children-headings:mb-2 children-headings:text-xl children-p:mb-4 last:children-p:mb-0 sm:children-headings:text-3xl lg:children-headings:text-4xl'
            )}
          >
            {fields?.description}
          </Content>
        </div>

        <div className="mt-10 grid gap-10 sm:mt-16 sm:grid-cols-12 sm:gap-6 lg:gap-10">
          <div className="flex justify-center sm:col-span-5 md:order-2">
            <div className="flex flex-col gap-16">
              {fields?.sentences?.map((sentence, index) => (
                <div key={index} className="flex items-center gap-4 md:gap-6 lg:gap-10">
                  <div className="relative">
                    <div className="relative z-10 flex h-7 w-7 items-center justify-center rounded-full bg-ubo-whiscash">
                      <div className={clsx(fields?.alternative ? 'bg-ubo-taillow' : 'bg-ubo-delibird', 'h-3 w-3 rounded-full')} />
                    </div>
                    {index > 0 && <div className="absolute bottom-1 left-[10px] z-0 h-28 w-2 bg-ubo-whiscash" />}
                  </div>
                  <div
                    className={clsx(
                      fields?.alternative ? 'text-white' : 'text-ubo-taillow',
                      'font-september text-lg font-black md:text-2xl lg:text-3xl'
                    )}
                  >
                    {sentence?.description}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className={clsx(fields?.alternative ? 'md:order-1' : 'md:order-2', 'sm:col-span-7')}>
            <Content
              className={clsx(
                fields?.alternative ? 'text-white' : 'text-ubo-taillow md:pl-10 lg:pl-16',
                'content children-p:mb-4 last:children-p:mb-0'
              )}
            >
              {fields?.descriptionTwo}
            </Content>
            <div className="mt-10 sm:mt-16 lg:mt-20">
              <LosseLink src={fields?.link} className="btn--primary--filled rounded-full px-6 py-2 font-bold lg:px-10 lg:py-4 lg:text-xl" />
            </div>
          </div>
        </div>
      </div>
      {fields?.alternative ? (
        <ArrowRight className="pointer-events-none absolute -left-[200px] top-0 z-0 h-[400px] w-[944px] max-lg:opacity-25" />
      ) : (
        <ArrowLeft className="pointer-events-none absolute -right-[200px] top-0 z-0 h-[400px] w-[944px] max-lg:opacity-25" />
      )}
    </section>
  )
}

function ArrowLeft({ className }: { className?: string }) {
  return (
    <svg width="530" height="400" viewBox="0 0 530 400" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <mask
        id="mask0_245_2"
        style={{
          maskType: 'alpha'
        }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="944"
        height="400"
      >
        <rect x="943.307" y="400" width="943.307" height="400" transform="rotate(180 943.307 400)" fill="#070744" />
      </mask>
      <g mask="url(#mask0_245_2)">
        <path d="M728.845 -10.2384L439.608 278.981" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M716.121 -22.963L426.884 266.256" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M703.396 -35.6869L414.159 253.532" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M690.619 -48.4645L401.382 240.755" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M677.895 -61.1891L388.658 228.03" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M665.17 -73.9134L375.933 215.306" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M566.853 278.875L503.178 342.55" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M554.128 266.133L490.436 329.808" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M541.404 253.409L477.711 317.084" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M528.625 240.631L464.95 304.324" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M515.902 227.907L452.21 291.599" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M592.406 304.43L528.731 368.105" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M579.646 291.652L515.972 355.345" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M552.418 -10.2384L263.199 278.981" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M539.694 -22.9628L250.474 266.257" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M526.969 -35.6869L237.75 253.532" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M514.192 -48.4645L224.973 240.755" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M501.468 -61.1888L212.248 228.031" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M488.743 -73.9134L199.524 215.306" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M390.424 278.875L326.749 342.55" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M377.701 266.133L314.026 329.808" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M364.977 253.409L301.302 317.084" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M352.198 240.631L288.523 304.324" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M339.474 227.907L275.799 291.599" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M441.445 329.878L377.77 393.571" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M428.668 317.118L364.994 380.793" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M415.998 304.43L352.323 368.105" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M403.219 291.652L339.544 355.345" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M505.191 393.624L441.516 457.316" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M492.466 380.899L428.792 444.574" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M479.742 368.175L416.067 431.85" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M466.965 355.397L403.29 419.09" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M454.241 342.673L390.566 406.348" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M376.009 -10.2384L86.772 278.981" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M363.284 -22.963L74.0474 266.256" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M350.56 -35.6869L61.3229 253.532" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M337.783 -48.4645L48.546 240.755" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M325.058 -61.1891L35.8214 228.03" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M312.334 -73.9134L23.097 215.306" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M214.016 278.875L150.342 342.55" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M201.292 266.133L137.6 329.808" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M188.567 253.409L124.875 317.084" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M175.789 240.631L112.114 304.324" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M163.064 227.907L99.3896 291.599" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M265.038 329.878L201.345 393.571" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M252.259 317.118L188.584 380.793" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M239.57 304.43L175.895 368.105" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M226.81 291.652L163.135 355.345" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M328.782 393.624L265.107 457.316" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M316.059 380.899L252.366 444.574" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M303.316 368.175L239.641 431.85" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M290.556 355.397L226.863 419.09" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
        <path d="M277.831 342.673L214.139 406.348" stroke="#000CED" strokeWidth="1.76482" strokeMiterlimit="10" />
      </g>
    </svg>
  )
}
