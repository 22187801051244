// @ubo
import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'

// Third party
import { AnimatePresence, motion, useInView, useMotionValue, useSpring } from 'framer-motion'

// SVG
import Play from '~/components/elements/svg/Play'
import Arrow from '~/components/elements/svg/Arrow'

// Components
import Content from '~/components/elements/Content'

// Hooks
import { useEffect, useState, useRef } from 'react'

// Types
import type { Maybe, Page_Flexcontent_Flex_Featured } from '~/graphql/types'
import type { MouseEvent } from 'react'

export default function FeaturedWebinar({ fields }: { fields: Page_Flexcontent_Flex_Featured }) {
  const [hoverWebinarThumbnail, setHoverThumbnail] = useState(false)
  const thumbnailWrapperRef = useRef<HTMLDivElement | null>(null)
  const thumbnailRef = useRef<HTMLDivElement | null>(null)

  const cursorX = useMotionValue(0)
  const cursorY = useMotionValue(0)

  useEffect(() => {
    if (!hoverWebinarThumbnail) {
      cursorX.set(650)
      cursorY.set(175)
    }
  }, [cursorX, cursorY, hoverWebinarThumbnail])

  function handleCursor(event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) {
    if (thumbnailRef.current) {
      const { left, top } = thumbnailRef.current.getBoundingClientRect()

      cursorX.set(event.clientX - left - 100)
      cursorY.set(event.clientY - top - 36)
    }
  }

  const springConfig = { damping: 25, stiffness: 700 }
  const cursorXSpring = useSpring(cursorX, springConfig)
  const cursorYSpring = useSpring(cursorY, springConfig)

  const thumbnailInView = useInView(thumbnailWrapperRef)

  return (
    <section
      data-component="FeaturedWebinar"
      data-scrollindicatortitle={fields.scrollIndicatorTitle ?? undefined}
      id={fields.sectionId ?? undefined}
      className="relative mx-auto overflow-hidden bg-ubo-delibird"
    >
      <div className="absolute -left-[50vw] hidden max-h-[47rem] w-[200vw] overflow-hidden lg:inline">
        <Line bgText={fields.appearance?.bgText} direction="ltr" />
        <Line bgText={fields.appearance?.bgText} direction="rtl" />
        <Line bgText={fields.appearance?.bgText} direction="ltr" />
        <Line bgText={fields.appearance?.bgText} direction="rtl" />
        <Line bgText={fields.appearance?.bgText} direction="ltr" />
        <Line bgText={fields.appearance?.bgText} direction="rtl" />
      </div>

      <div className="container relative mb-20">
        <div className="overflow-hidden lg:px-20" ref={thumbnailWrapperRef}>
          <motion.div
            key="thumbnail_webinar"
            role="button"
            ref={thumbnailRef}
            onMouseMove={(event) => handleCursor(event)}
            onMouseEnter={() => setHoverThumbnail(true)}
            onMouseLeave={() => setHoverThumbnail(false)}
            className="relative overflow-hidden"
            initial={{
              y: '100%',
              opacity: 0
            }}
            animate={
              thumbnailInView
                ? {
                    y: 0,
                    opacity: 1
                  }
                : {
                    y: '-100%',
                    opacity: 0
                  }
            }
            transition={{
              duration: 1.5
            }}
          >
            <LosseLink to={fields.webinar?.uri}>
              <LossePlaatjie src={fields.image} className="mt-20 w-full object-cover" maxwidth={1999} />
              <motion.div
                key={`thumbnail_cursor`}
                role="button"
                className="btn btn--light pointer-events-none absolute top-0 z-[99]"
                style={{
                  translateX: cursorXSpring,
                  translateY: cursorYSpring
                }}
              >
                <Play />
                <span className="ml-2">Play webinar</span>
              </motion.div>
            </LosseLink>
          </motion.div>
          <motion.div initial={{ opacity: 0 }} animate={thumbnailInView ? { opacity: 1 } : { opacity: 0 }} transition={{ delay: 0.75 }}>
            <div className="mt-10 grid grid-cols-12 items-center lg:px-12">
              <div className="col-span-12 lg:col-span-5">
                <Content className="content-2xl">{fields.title}</Content>
              </div>
              <div className="col-span-12 ml-6 mt-6 lg:col-span-7 lg:ml-16 lg:mt-0">
                <Content>{fields.description}</Content>
                <LosseLink className="btn btn--alternate mt-6" to={fields.webinar?.uri}>
                  <Arrow type="lightBlue" className="mr-3" />
                  <span>{fields.readmoretext}</span>
                </LosseLink>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  )
}

export function Line({ bgText, direction = 'ltr' }: { bgText: Maybe<string> | undefined; direction?: 'rtl' | 'ltr' }) {
  function randomIntFromInterval(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1) + min)
  }

  const duration = randomIntFromInterval(16, 20)

  let xAnimation = ['-25vw', '25vw']
  if (direction === 'rtl') {
    xAnimation = xAnimation.reverse()
  }

  return (
    <AnimatePresence>
      <motion.div
        className="inline-flex w-full"
        initial={{ x: xAnimation[0] }}
        animate={{ x: xAnimation }}
        transition={{ repeat: Infinity, repeatType: 'mirror', duration: duration }}
      >
        <Word word={bgText} />
        <Word word={bgText} />
        <Word word={bgText} />
        <Word word={bgText} />
        <Word word={bgText} />
      </motion.div>
    </AnimatePresence>
  )
}

export function Word({ word }: { word: Maybe<string> | undefined }) {
  return (
    <span className="text-stroke stroke-dark mr-10 inline whitespace-nowrap font-september text-[9vw] font-black leading-[7vw] opacity-10">
      {word}
    </span>
  )
}
