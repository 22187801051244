import * as Yup from 'yup'

export const downloadValidationSchemaNL = {
  input_14: Yup.string().when('input_9', {
    is: 'Fysiek',
    then: Yup.string().required('Plaatsnaam is verplicht.')
  }),
  input_13: Yup.string().when('input_9', {
    is: 'Fysiek',
    then: Yup.string().required('Straatnaam is verplicht.')
  }),
  input_10: Yup.string().when('input_9', {
    is: 'Fysiek',
    then: Yup.string().required('Postcode is verplicht.')
  }),
  input_11: Yup.string().when('input_9', {
    is: 'Fysiek',
    then: Yup.string().required('Huisnummer is verplicht.')
  })
}

export const downloadValidationSchemaEN = {
  input_14: Yup.string().when('input_9', {
    is: 'Fysiek',
    then: Yup.string().required('City is mandatory.')
  }),
  input_13: Yup.string().when('input_9', {
    is: 'Fysiek',
    then: Yup.string().required('Streetname is mandatory.')
  }),
  input_10: Yup.string().when('input_9', {
    is: 'Fysiek',
    then: Yup.string().required('Zipcode is mandatory.')
  }),
  input_11: Yup.string().when('input_9', {
    is: 'Fysiek',
    then: Yup.string().required('House number is mandatory.')
  })
}
