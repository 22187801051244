import { useFaq } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import { AnimatePresence, motion } from 'framer-motion'
import { useState } from 'react'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Misc } from '~/graphql/types'

export default function FAQ({ fields }: { fields: Page_Flexcontent_Flex_Misc }) {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)

  useFaq({
    items:
      fields?.questions?.map((edge) => ({
        question: edge?.question as string,
        answer: edge?.answer as string
      })) || []
  })

  return (
    <section data-component="FAQ" className="overflow-hidden py-10 lg:py-20">
      <div className="container flex flex-col items-center">
        <div className="relative">
          <Content className="content-md mb-4 children-h3:pb-4 xl:children-h3:pb-0">{fields?.description}</Content>
        </div>
        <div className="lg:px-16 xl:px-32">
          {fields.questions?.map((edge, questionIndex) => {
            const active = currentQuestionIndex === questionIndex

            return (
              <div key={edge?.question} className="relative flex items-center justify-between border-b-2 border-ubo-taillow">
                <div className="relative w-full">
                  <span
                    role="button"
                    onClick={() => setCurrentQuestionIndex(questionIndex)}
                    className="block py-12 font-september text-xl font-bold text-ubo-taillow hover:underline max-sm:pr-12 lg:text-2xl"
                  >
                    {edge?.question}
                    <div className="absolute right-0 top-0">
                      <svg
                        width="40"
                        height="39"
                        viewBox="0 0 40 39"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className={clsx(
                          active && 'rotate-45',
                          'transition-all',
                          'mt-[54px] h-[19px] w-[20px] lg:mt-10 lg:h-[39px] lg:w-[40px]'
                        )}
                      >
                        <path
                          d="M22.7806 22.2034H22.2806V22.7034V38.5H16.8737V22.7034V22.2034H16.3737H0.577148V16.8004H16.3737H16.8737V16.3004V0.5H22.2806V16.3004V16.8004H22.7806H38.5771V22.2034H22.7806Z"
                          fill="#000CED"
                          stroke="#000CED"
                        />
                      </svg>
                    </div>
                  </span>
                  <AnimatePresence>
                    {active && (
                      <motion.div
                        key={edge?.question}
                        initial={{ height: 0 }}
                        animate={{ height: 'fit-content' }}
                        exit={{ height: 0 }}
                        className="overflow-hidden"
                      >
                        <div className="flex w-11/12 flex-col items-start pl-4 pr-4 lg:pl-12 lg:pr-0">
                          <Content className="pb-12 lg:children:text-lg">{edge?.answer}</Content>
                        </div>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}
