export default function Stripes({ className = '' }) {
  return (
    <svg width="1728" height="75" viewBox="0 0 1728 75" fill="none" xmlns="http://www.w3.org/2000/svg" className={className ?? undefined}>
      <g clipPath="url(#clip0_2278_21132)">
        <path d="M1208.23 178.4L463.42 -566.42" stroke="#000CED" strokeWidth="2" strokeMiterlimit="10" />
        <path d="M1121.14 147.8L435.171 -538.17" stroke="#000CED" strokeWidth="2" strokeMiterlimit="10" />
        <path d="M1073.51 156.63L406.94 -509.94" stroke="#000CED" strokeWidth="2" strokeMiterlimit="10" />
        <path d="M1020.34 160.15L378.59 -481.6" stroke="#000CED" strokeWidth="2" strokeMiterlimit="10" />
        <path d="M992.111 188.39L350.361 -453.36" stroke="#000CED" strokeWidth="2" strokeMiterlimit="10" />
        <path d="M963.871 216.62L322.121 -425.13" stroke="#000CED" strokeWidth="2" strokeMiterlimit="10" />
        <path d="M1530.4 75.3301L888.65 -566.42" stroke="#000CED" strokeWidth="2" strokeMiterlimit="10" />
        <path d="M1502.16 103.58L860.41 -538.17" stroke="#000CED" strokeWidth="2" strokeMiterlimit="10" />
        <path d="M1473.92 131.81L832.17 -509.94" stroke="#000CED" strokeWidth="2" strokeMiterlimit="10" />
        <path d="M1445.58 160.15L803.83 -481.6" stroke="#000CED" strokeWidth="2" strokeMiterlimit="10" />
        <path d="M1417.35 188.39L775.6 -453.36" stroke="#000CED" strokeWidth="2" strokeMiterlimit="10" />
        <path d="M1389.11 216.62L747.361 -425.13" stroke="#000CED" strokeWidth="2" strokeMiterlimit="10" />
        <path d="M1937 75.3301L1295.25 -566.42" stroke="#000CED" strokeWidth="3" strokeMiterlimit="10" />
        <path d="M1908.75 103.58L1267 -538.17" stroke="#000CED" strokeWidth="3" strokeMiterlimit="10" />
        <path d="M1880.52 131.81L1238.77 -509.94" stroke="#000CED" strokeWidth="3" strokeMiterlimit="10" />
        <path d="M1852.18 160.15L1210.43 -481.6" stroke="#000CED" strokeWidth="3" strokeMiterlimit="10" />
        <path d="M1823.94 188.39L1182.19 -453.36" stroke="#000CED" strokeWidth="3" strokeMiterlimit="10" />
        <path d="M1795.71 216.62L1153.96 -425.13" stroke="#000CED" strokeWidth="3" strokeMiterlimit="10" />
        <path d="M775.689 169.93L39.3395 -566.42" stroke="#000CED" strokeWidth="2" strokeMiterlimit="10" />
        <path d="M691.83 142.56L11.1001 -538.17" stroke="#000CED" strokeWidth="2" strokeMiterlimit="10" />
        <path d="M640.959 148.16L-17.131 -509.94" stroke="#000CED" strokeWidth="2" strokeMiterlimit="10" />
        <path d="M596.27 160.15L-45.4805 -481.6" stroke="#000CED" strokeWidth="2" strokeMiterlimit="10" />
        <path d="M568.039 188.39L-73.7109 -453.36" stroke="#000CED" strokeWidth="2" strokeMiterlimit="10" />
        <path d="M539.799 216.62L-101.951 -425.13" stroke="#000CED" strokeWidth="2" strokeMiterlimit="10" />
        <path d="M344.029 163.15L-385.541 -566.42" stroke="#000CED" strokeWidth="2" strokeMiterlimit="10" />
        <path d="M279.568 155.19L-413.792 -538.17" stroke="#000CED" strokeWidth="2" strokeMiterlimit="10" />
        <path d="M221.59 153.67L-442.02 -509.94" stroke="#000CED" strokeWidth="2" strokeMiterlimit="10" />
        <path d="M171.389 160.15L-470.361 -481.6" stroke="#000CED" strokeWidth="2" strokeMiterlimit="10" />
        <path d="M143.148 188.39L-498.601 -453.36" stroke="#000CED" strokeWidth="2" strokeMiterlimit="10" />
        <path d="M114.92 216.62L-526.83 -425.13" stroke="#000CED" strokeWidth="2" strokeMiterlimit="10" />
      </g>
    </svg>
  )
}
