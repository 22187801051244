// Components
import Content from '~/components/elements/Content'

// Third party
import { motion, useInView } from 'framer-motion'

// Hooks
import { useRef } from 'react'

// Types
import type { Page_Flexcontent_Flex_Banner } from '~/graphql/types'

export default function BannerOverview({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  const sectionRef = useRef<HTMLElement>(null)

  const inView = useInView(sectionRef, { once: true })

  return (
    <section
      data-component="BannerOverview"
      data-scrollindicatortitle={fields.scrollIndicatorTitle ?? undefined}
      id={fields.sectionId ?? undefined}
      ref={sectionRef}
      className="overflow-hidden bg-ubo-whiscash pt-32 sm:pt-40"
    >
      <div className="container">
        <div className="flex flex-col items-start justify-between pb-8 sm:pb-24 md:flex-row md:items-end">
          <motion.div
            initial={{ x: '-110%' }}
            animate={inView ? { x: 0 } : { x: '-110%' }}
            transition={{
              bounce: 0,
              duration: 1
            }}
          >
            <Content className="content-3xl content-stroke content-stroke-light text-ubo-delibird">{fields.title}</Content>
          </motion.div>
          <motion.div
            initial={{ x: '110%' }}
            animate={inView ? { x: 0 } : { x: '110%' }}
            transition={{
              bounce: 0,
              duration: 1
            }}
          >
            <Content className="ml-6 mt-8 text-ubo-delibird sm:ml-24">{fields.description}</Content>
          </motion.div>
        </div>
      </div>
    </section>
  )
}
