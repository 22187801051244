// Third party
import { LossePlaatjie, useLosseLanguage } from '@ubo/losse-sjedel'
import { useState } from 'react'
import type Swiper from 'swiper'
import { Navigation } from 'swiper/modules'
import { motion } from 'framer-motion'

// Components
import Content from '~/components/elements/Content'
import Slider, { Slide } from '~/components/elements/Slider'
import ArrowRounded from '~/components/elements/svg/ArrowRounded'
import Ship from '~/components/elements/svg/Ship'
import Stripes from '~/components/elements/svg/Stripes'

// Types
import type { Page_Flexcontent_Flex_Usps } from '~/graphql/types'

export default function UspsPhases({ fields }: { fields: Page_Flexcontent_Flex_Usps }) {
  const [, locale] = useLosseLanguage()
  const [swiper, setSwiper] = useState<Swiper>()
  const [currentUspIndex, setCurrentUspIndex] = useState(0)

  if (!fields.usps) return null

  const currentUspEdge = fields.usps[currentUspIndex]

  return (
    <section
      data-component="UspsPhases"
      data-scrollindicatortitle={fields.scrollIndicatorTitle ?? undefined}
      id={fields.sectionId ?? undefined}
      className="relative overflow-hidden bg-ubo-delibird pt-12 lg:pt-24"
    >
      <div className="absolute bottom-0 flex w-full justify-center">
        <Stripes />
      </div>
      <div className="container">
        <div className="grid grid-cols-2 pb-24 lg:pb-56">
          <div className="col-span-2 lg:col-span-1">
            <Content className="content-stroke content-2xl children-h3:font-museu children-h2:mb-0 children-h3:mb-3 children-h3:text-3xl children-h3:text-ubo-whiscash lg:children-h3:text-6xl">
              {fields.description}
            </Content>
          </div>
          <div className="col-span-2 lg:col-span-1">
            <Content>{fields.secondDescription}</Content>
          </div>
        </div>
      </div>
      <div className="bg-white">
        <div className="container">
          <div className="relative -top-24 mx-auto max-w-screen-2xl lg:-top-36">
            <Ship className="absolute top-0 w-full" />
            <div className="relative grid grid-cols-4 pt-12 md:pt-20 lg:px-32 lg:pb-12 lg:pt-12">
              {fields.usps?.map((edge, i) => {
                const active = i === currentUspIndex

                return (
                  <div
                    key={edge?.title}
                    role="button"
                    className="col-span-2 mb-5 flex flex-col items-center justify-center text-center text-lg text-ubo-whiscash transition-transform hover:scale-95 hover:animate-pulse lg:col-span-1 lg:mb-0"
                    onClick={() => swiper?.slideTo(i)}
                  >
                    <LossePlaatjie
                      key={edge?.icon?.mediaItemUrl}
                      src={active ? edge?.icon : edge?.iconInactive}
                      className="mb-4 h-14 w-14 lg:h-24 lg:w-24"
                    />
                    <span className="text-sm lg:text-lg">{`${fields.appearance?.uspPfx} ${i + 1}`}</span>
                    <h3 className="text-sm font-extrabold lg:text-lg">{edge?.title}</h3>
                  </div>
                )
              })}
            </div>
          </div>
          <div className="relative -top-12 grid grid-cols-2 items-start gap-10">
            <div className="col-span-2 lg:order-last lg:col-span-1">
              <LossePlaatjie src={currentUspEdge?.image} className="mx-auto w-64 object-cover md:w-96 xl:w-auto 2xl:h-[600px]" />
            </div>
            <div className="col-span-2 -mt-14 mb-12 lg:order-first lg:col-span-1 lg:-mt-0 lg:pt-28">
              <Slider
                onSlideChange={(slide) => setCurrentUspIndex(slide.activeIndex)}
                modules={[Navigation]}
                navigation={{
                  prevEl: '.custom-swiper-btn-prev',
                  nextEl: '.custom-swiper-btn-next'
                }}
                onSwiper={(swiper) => setSwiper(swiper)}
              >
                {fields.usps?.map((edge, i) => {
                  const active = currentUspIndex === i
                  return (
                    <Slide key={edge?.title}>
                      <motion.div
                        animate={active ? { position: 'relative', opacity: 1 } : { position: 'absolute', opacity: 0 }}
                        transition={{ bounce: 0 }}
                      >
                        <Content className="children-ul:font-museu pr-4 children-headings:font-museo children-h2:text-3xl children-h3:text-xl children-h3:text-ubo-whiscash children-ul:font-light lg:children-h2:text-4xl lg:children-p:pr-24 lg:children-ul:text-xl 2xl:children-h2:text-6xl">
                          {edge?.description}
                        </Content>
                      </motion.div>
                    </Slide>
                  )
                })}
              </Slider>
              <div className="mt-10 flex justify-between">
                <button className="custom-swiper-btn-prev inline-flex items-center font-extrabold uppercase text-ubo-whiscash">
                  <ArrowRounded className="rotate-180" />
                  <span className="ml-3">
                    {locale === 'nl_NL' ? 'Vorige ' : 'Previous '}
                    {fields.appearance?.uspPfx}
                  </span>
                </button>
                <button className="custom-swiper-btn-next inline-flex items-center font-extrabold uppercase text-ubo-whiscash">
                  <span className="mr-3">
                    {locale === 'nl_NL' ? 'Volgende ' : 'Next '}
                    {fields.appearance?.uspPfx}
                  </span>
                  <ArrowRounded className="-mt-1" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
