// @ubo
import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'

// Components
import ScrollEffectStart from './ScrollEffectStart'
import ScrollEffectEnd from './ScrollEffectEnd.tsx'

export default function ScrollEffectShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    start: ScrollEffectStart,
    end: ScrollEffectEnd
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
