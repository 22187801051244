// @ubo
import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'

// Components
import UspsNumbers from './UspsNumbers'
import UspsDefault from './UspsDefault'
import UspsServices from './UspsServices'
import UspsPhases from './UspsPhases'

export default function UspsShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    default: UspsDefault,
    numbers: UspsNumbers,
    services: UspsServices,
    phases: UspsPhases
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
