export default function Chevron({ className = '' }: { className?: string }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="36" fill="none" viewBox="0 0 20 36" className={className}>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M4.082.716L19.3 16.272a2.483 2.483 0 010 3.457L4.082 35.284a2.355 2.355 0 01-3.382 0 2.483 2.483 0 010-3.456l11.136-11.383c1.665-1.937 1.65-3.012 0-4.89L.7 4.174A2.483 2.483 0 01.7.716a2.355 2.355 0 013.382 0z"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}
