import { useEffect, useState } from 'react'

export default function useClientMousePosition() {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 })

  useEffect(() => {
    const mouseMoveHandler = (event: any) => {
      const { clientX, clientY } = event
      setMousePosition({ x: clientX - window.scrollX, y: clientY - window.scrollY })
    }
    document.addEventListener('mousemove', mouseMoveHandler)

    return () => {
      document.removeEventListener('mousemove', mouseMoveHandler)
    }
  }, [])

  return mousePosition
}
