// Components
import FlexContentHandler from './FlexContentHandler'

export interface FragmentProps {
  fields: any
}

export default function Fragment({ fields }: FragmentProps) {
  return <FlexContentHandler prefix="fragment_FlexFragment" flex={fields.element.flexContent.flex} />
}
